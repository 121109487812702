<template>
  <modal
    name="modal-auditoria"
    scrollable
    :width="700"
    height="auto"
    @opened="getAudits"
  >
    <button
      type="button"
      class="close mt-3 mr-3"
      @click="$modal.hide('modal-auditoria')"
    >
      <span aria-hidden="true">&times;</span>
    </button>
    <div
      class="modal-audits-container"
      v-if="campanha_selecionada && campanha_selecionada.modelo_campanha_id"
    >
      <b-table
        :items="auditables"
        :fields="[
          { key: 'formatted_created_at', label: 'Data de Alteração' },
          { key: 'formatted_event', label: 'Evento' },
          { key: 'usu_nome', label: 'Usuário' },
          { key: 'modelo_nome', label: 'Parte' },
          { key: 'changes', label: 'Alterações' }
        ]"
        striped
        hover
        id="tabela-auditoria"
        :current-page="current_page"
        :per-page="per_page"
      >
        <template #cell(formatted_created_at)="data">
          <div>{{ data.value }}</div>
        </template>
        <template #cell(formatted_event)="data">
          <div :style="{ color: data.value === 'Exclusão' ? 'red' : 'black' }">
            {{ data.value }}
          </div>
        </template>
        <template #cell(usu_nome)="data">
          <div v-b-tooltip.hover.right :title="data.item.extra_info">
            {{ data.value }}
          </div>
        </template>
        <template #cell(modelo_nome)="data">
          <div>
            {{ data.value }}
          </div>
        </template>
        <template #cell(changes)="data">
          <div class="d-flex align-items-center">
            <i
              class="fa-solid fa-clock-rotate-left mr-3"
              v-b-tooltip.hover.left
              :title="JSON.stringify(data.item.old_values)"
            ></i>
            <i
              class="fa-solid fa-forward"
              v-b-tooltip.hover.right
              :title="JSON.stringify(data.item.new_values)"
            ></i>
          </div>
        </template>
      </b-table>
      <b-pagination
        v-model="current_page"
        ref="pagination"
        :total-rows="auditables.length"
        :per-page="per_page"
        aria-controls="tabela-auditoria"
        align="fill"
      ></b-pagination>
    </div>
  </modal>
</template>

<script>
import API_LINKS from "../../api.links";
import ApiService from "@/core/services/api.service";

export default {
  components: {},
  props: {
    campanha_selecionada: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      auditables: [],
      current_page: 1,
      per_page: 10
    };
  },
  methods: {
    async getAudits() {
      try {
        const response = await ApiService.get(
          API_LINKS.auditable +
            "/campanha/" +
            this.campanha_selecionada.modelo_campanha_id
        );

        this.auditables = response.data.audits.map((audit) => {
          const event_mapper = {
            created: "Criação",
            updated: "Atualização",
            deleted: "Exclusão"
          };
          const extra_info = audit.user_agent + " - " + audit.ip_address;
          return {
            ...audit,
            formatted_event: event_mapper[audit.event],
            formatted_created_at: this.formatDateToBrazilian(audit.created_at),
            extra_info
          };
        });
      } catch (error) {
        console.error(error);
      }
    },
    formatDateToBrazilian(date) {
      if (!date) return "-";

      const parsedDate = new Date(date);
      if (isNaN(parsedDate)) return "-";

      const day = parsedDate.getDate().toString().padStart(2, "0");
      const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
      const year = parsedDate.getFullYear();
      const hours = parsedDate.getHours().toString().padStart(2, "0");
      const minutes = parsedDate.getMinutes().toString().padStart(2, "0");
      const seconds = parsedDate.getSeconds().toString().padStart(2, "0");

      return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-audits-container {
  padding: 24px;
}
</style>
