<template>
  <!-- begin:: Header -->
  <div
    id="kt_header"
    ref="kt_header"
    class="header"
    v-bind:class="headerClasses"
  >
    <div
      class="container-fluid d-flex align-items-right justify-content-between"
    >
      <!-- begin:: Header Menu -->
      <div
        class="header-menu-wrapper header-menu-wrapper-left col p-0 align-self-center"
        ref="kt_header_menu_wrapper"
      >
        <div class="row align-items-center">
          <div class="col-auto">
            <h6
              class="mb-0 font-weight-bolder align-items-center"
              v-html="currentUserPersonalInfo.cliente"
            ></h6>
            <p
              class="mb-0"
              v-if="currentUserPersonalInfo.conta"
              v-b-tooltip.html="currentUserPersonalInfo.conta_id"
            >
              <span class="font-weight-bolder">Conta:</span>
              {{ currentUserPersonalInfo.conta }}
            </p>
            <p class="mb-0" v-if="currentUserPersonalInfo.emailCliente">
              <span class="font-weight-bolder">Email:</span>
              {{ currentUserPersonalInfo.emailCliente }}
            </p>
          </div>

          <div
            class="col-auto"
            v-if="
              (checkSuperAdmin || isAccountAdmin) && contasParaTroca.length > 0
            "
          >
            <button
              @click="trocarUsuarioOuConta"
              class="btn btn-wellon-branco btn-sm"
            >
              <i class="fas fa-arrow-right-arrow-left"></i>
              Trocar Conta
            </button>
          </div>
        </div>
      </div>
      <!-- end:: Header Menu -->

      <KTTopbar></KTTopbar>
    </div>

    <b-modal
      ref="mdlTrocarUsuarioOuConta"
      title="Trocar Conta"
      @hidden="limpar"
      @ok="trocarConta"
      okVariant="success"
      okTitle="Trocar"
      cancelTitle="Cancelar"
      ignore-enforce-focus-selector=".swal2-container"
      v-if="(checkSuperAdmin || isAccountAdmin) && contasParaTroca.length > 0"
      hide-footer
    >
      <div>
        <b-button
          variant="outline-dark"
          block
          @click="trocarParaSuperAdmin"
          v-if="checkSuperAdmin && hasAccount"
          class="mb-4"
          >Trocar para Administrador do Sistema
          <i class="fas fa-star text-info ml-2"></i
        ></b-button>

        <p>Selecione uma conta da lista a seguir:</p>

        <b-form-input
          v-model="filtroContas"
          placeholder="Digite para filtrar..."
          @input="debounce"
          @keyup.enter="changeSelected"
          @keyup.up="handleKeyUpDown"
          @keyup.down="handleKeyUpDown"
          :autofocus="true"
          class="mb-2"
        ></b-form-input>

        <ul id="lista" class="navi navi-hover navi-active">
          <li
            class="list navi-item"
            v-for="(conta, index) in contas"
            :key="conta.id"
            :contaid="conta.id"
            :id="`item-${index}`"
            @keydown="handleKeyUpDown"
          >
            <a
              class="navi-link text-hover-warning"
              href="javascript:;"
              @click.prevent="trocarConta(conta.id)"
            >
              <div class="navi-text">
                <div>
                  <span class="font-weight-bold"
                    >{{ conta.login }} ({{ conta.id }})</span
                  >
                  <i
                    v-if="conta.principal == 'true'"
                    class="fas fa-certificate icon-nm ml-1"
                    v-b-tooltip.noninteractive
                    v-b-tooltip.hover
                    title="Principal"
                    style="color: #8950fc !important"
                  ></i>

                  <i
                    class="fas fa-circle icon-nm ml-1"
                    v-b-tooltip.noninteractive
                    v-b-tooltip.hover
                    :title="`${conta.status == 'I' ? 'Inativa' : 'Bloqueada'}`"
                    v-if="conta.status != 'A'"
                    :style="`color: ${
                      conta.status == 'I' ? 'red' : 'orange'
                    } !important`"
                  ></i>
                </div>
                <span class="text-muted">{{ conta.nome }}</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </b-modal>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import ApiService from "../../../core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "KTHeader",
  components: {
    KTTopbar
  },
  data() {
    return {
      contas: [],
      idContaSelecionada: null,
      filtroContas: "",
      debounceTimeout: null
    };
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header", "kt_header_mobile");
  },
  computed: {
    ...mapGetters([
      "getClasses",
      "currentUserPersonalInfo",
      "hasAccount",
      "isAccountAdmin",
      "contasParaTroca",
      "checkSuperAdmin",
      "isAdmin"
    ]),

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    }
  },

  methods: {
    trocarUsuarioOuConta() {
      this.contas = [...this.contasParaTroca];
      this.$refs["mdlTrocarUsuarioOuConta"].show();
    },

    debounce(filtro) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.filtrarContas(filtro);
      }, 300);
    },

    filtrarContas(filtro) {
      if (filtro.length > 0) {
        const lowercase_filtro = filtro.toLowerCase();

        this.contas = this.contasParaTroca.filter((conta) => {
          const conta_id = conta.id + "";
          return (
            conta.login.toLowerCase().includes(lowercase_filtro) ||
            conta.nome.toLowerCase().includes(lowercase_filtro) ||
            conta_id.includes(lowercase_filtro)
          );
        });
      } else {
        this.contas = [...this.contasParaTroca];
      }
      this.removeSelected();
    },

    limpar() {
      this.idContaSelecionada = null;
      this.contas = [];
      this.filtroContas = "";
    },

    trocarConta(conta) {
      Swal.fire({
        title: "Deseja realmente trocar para a conta selecionada?",
        showCancelButton: true,
        confirmButtonText: "Trocar",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-success"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.post("/usuario/trocar-conta", {
            contaId: conta
          }).then(() => {
            localStorage.removeItem(
              "filtrosCampanha-" + this.currentUserPersonalInfo.login
            );
            window.location.href = "/";
          });
        }
      });
    },

    trocarParaSuperAdmin() {
      Swal.fire({
        title:
          "Deseja realmente trocar o usuário para Administrador do Sistema?",
        showCancelButton: true,
        confirmButtonText: "Trocar",
        cancelButtonText: "Cancelar",
        customClass: {
          cancelButton: "btn btn-secondary",
          confirmButton: "btn btn-info"
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          ApiService.get("/usuario/trocar-para-super-admin").then(() => {
            window.location.href = "/";
          });
        }
      });
    },

    obterIdContaSelecionada(obj) {
      this.idContaSelecionada = obj ? obj.id : null;
    },

    focusFirstItem() {
      const firstItem = document.querySelector("#item-0");
      if (firstItem) {
        firstItem.classList.add("selecionado");
      }
    },
    handleKeyUpDown(event) {
      let { activeItem, items } = this.getSelecionado();
      if (!activeItem) {
        this.focusFirstItem();
        return;
      }
      const currentId = parseInt(activeItem.id.split("-")[1]);

      if (event.key === "ArrowDown" && currentId < items.children.length - 1) {
        document
          .querySelector(`#item-${currentId}`)
          .classList.remove("selecionado");

        document
          .querySelector(`#item-${currentId + 1}`)
          .classList.add("selecionado");
      } else if (event.key === "ArrowUp" && currentId > 0) {
        document
          .querySelector(`#item-${currentId}`)
          .classList.remove("selecionado");
        document
          .querySelector(`#item-${currentId - 1}`)
          .classList.add("selecionado");
      }
    },
    changeSelected() {
      const { activeItem } = this.getSelecionado();
      this.trocarConta(activeItem.getAttribute("contaid"));
    },
    getSelecionado() {
      const items = document.querySelector("#lista");
      let activeItem = null;
      items.children.forEach((element) => {
        if (element.classList.contains("selecionado")) {
          activeItem = element;
        }
      });
      return { activeItem, items };
    },
    removeSelected() {
      const { activeItem } = this.getSelecionado();
      activeItem?.classList.remove("selecionado");
    },
    contains(number, digit) {
      if (number == digit) {
        // this is to deal with the number=0, digit=0 edge case
        return true;
      }
      while (number != 0) {
        // stop once all digits are cut off
        if (number % 10 == digit) {
          // check if the last digit matches
          return true;
        }
        number = Math.floor(number / 10); // cut off the last digit
      }
      return false;
    }
  }
};
</script>

<style scoped>
.selecionado a {
  background-color: #e7e8e9;
  color: #3699ff;
}
</style>
