<template>
  <div class="d-inline-flex align-middle mr-2">
    <b-button
      variant="light-primary"
      size="sm"
      class="btn-icon"
      v-b-tooltip.noninteractive
      title="Editar"
      @click="abrir"
    >
      <i class="fas fa-edit"></i>
    </b-button>

    <b-modal
      ref="mdlEditarUsuario"
      title="Editar Usuário"
      @hidden="limpar"
      @shown="validacao"
      @ok="salvar"
      ok-title="Salvar"
      ok-variant="success"
      cancel-title="Cancelar"
      size="lg"
      scrollable
      ignore-enforce-focus-selector=".swal2-container"
    >
      <b-form ref="frmEditarUsuario">
        <div class="row">
          <div class="col-6">
            <b-form-group>
              <label>Email:</label>
              <b-form-input
                name="email"
                v-model="frmEditarUsuario.email"
                disabled
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group>
              <label>Nome <span class="text-danger">*</span>:</label>
              <b-form-input
                name="nome"
                v-model="frmEditarUsuario.nome"
              ></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <b-form-group>
              <label>Celular:</label>
              <b-form-input
                name="celular"
                v-model="frmEditarUsuario.celular"
                data-inputmask="'mask': ['(99) 9999-9999', '(99) 99999-9999'], 'placeholder': ''"
              ></b-form-input>
            </b-form-group>
          </div>

          <div class="col-6">
            <b-form-group>
              <label>Grupo <span class="text-danger">*</span>:</label>
              <b-form-select
                name="grupo"
                :options="sltGrupo"
                v-model="frmEditarUsuario.grupo"
              ></b-form-select>
              <span class="form-text"
                ><modal-grupos-permissoes
                  :grupos="grupos"
                  :gruposPermissoes="gruposPermissoes"
                ></modal-grupos-permissoes
              ></span>
            </b-form-group>
          </div>
        </div>

        <b-form-group v-if="checarAcessoNps">
          <label>Unidades</label>
          <pv-multiselect
            class="form-control"
            optionLabel="text"
            emptyFilterMessage="Nenhum resultado"
            :filter="true"
            :options="sltUnidades"
            placeholder="Todos"
            v-model="defaultsSelect.unidades"
            @input="obterUnidades"
          >
          </pv-multiselect>
        </b-form-group>

        <b-form-group v-if="checarAcesso(['Painel Executivo'])">
          <label>Paineis Executivo</label>
          <pv-multiselect
            class="form-control"
            optionLabel="bda_titulo"
            optionValue="bi_dash_id"
            emptyFilterMessage="Nenhum resultado"
            :filter="true"
            :options="frmEditarUsuario.paineis"
            placeholder="Nenhum"
            v-model="defaultsSelect.paineis"
          >
          </pv-multiselect>
        </b-form-group>

        <b-form-group>
          <label>Receber Notificação <span class="text-danger">*</span>:</label>
          <b-form-select
            name="notificacao"
            :options="sltRecebeNotificacao"
            v-model="frmEditarUsuario.notificacao"
          ></b-form-select>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";

import Inputmask from "inputmask";
import ModalGruposPermissoes from "./ModalGruposPermissoes";

export default {
  props: {
    usuario: {
      type: Object,
      default: () => {}
    },
    unidades: {
      type: Array,
      default: () => []
    },
    sltGrupo: {
      type: Array,
      default: () => []
    },
    grupos: {
      type: Array,
      default: () => []
    },
    gruposPermissoes: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      defaultsSelect: {
        unidades: [],
        paineis: []
      },
      frmEditarUsuario: {
        conta: null,
        id: null,
        email: "",
        nome: "",
        celular: "",
        grupo: null,
        unidades: null,
        paineis: null,
        notificacao: 0
      },
      sltRecebeNotificacao: [
        {
          text: "Nenhuma",
          value: 0
        },
        {
          html: "Notificação de Saldo",
          value: 1
        },
        {
          html: "Notificação de Saldo e Status do Aparelho",
          value: 2
        },
        {
          html: "Notificação de Detratores NPS",
          value: 3
        }
      ]
    };
  },

  computed: {
    ...mapGetters(["validatorErrors"]),

    sltUnidades() {
      const unidades = [];

      for (const obj of this.unidades) {
        if (obj.und_codigo) {
          unidades.push({
            text: obj.und_nome,
            value: obj.unidade_id,
            und: obj.und_codigo
          });
        }
      }

      return unidades;
    },

    sltPaineis() {
      let paineis = this.frmEditarUsuario.paineis.map((p) => {
        return {
          text: p.bda_titulo,
          value: p.bi_dash_id
        };
      });
      return paineis;
    },

    checarAcessoNps() {
      const grupo = this.frmEditarUsuario.grupo;
      if (!grupo) {
        return false;
      }
      return (
        this.gruposPermissoes["Opine: Visualizar NPS"][grupo]
          ?.permissao_ativa === 1
      );
    }
  },

  components: {
    "modal-grupos-permissoes": ModalGruposPermissoes
  },

  methods: {
    limpar() {
      this.frmEditarUsuario.conta = null;
      this.frmEditarUsuario.id = null;
      this.frmEditarUsuario.email = "";
      this.frmEditarUsuario.nome = "";
      this.frmEditarUsuario.celular = "";
      this.frmEditarUsuario.grupo = null;
      this.frmEditarUsuario.unidades = null;
      this.frmEditarUsuario.paineis = null;
      this.frmEditarUsuario.notificacao == 0;
    },

    abrir() {
      this.limpar();

      ApiService.post("usuario/unidades", {
        idConta: this.usuario.conta_id,
        idUsuario: this.usuario.id
      }).then((response) => {
        this.frmEditarUsuario.unidades = response.data.data.unidadesForm;
        this.defaultsSelect.unidades = response.data.data.unidadesDefault;

        this.frmEditarUsuario.conta = this.usuario.conta_id;
        this.frmEditarUsuario.id = this.usuario.id;
        this.frmEditarUsuario.email = this.usuario.email;
        this.frmEditarUsuario.nome = this.usuario.nome;
        this.frmEditarUsuario.celular = this.usuario.celular;
        this.frmEditarUsuario.grupo = this.usuario.grupo;
        this.frmEditarUsuario.notificacao = this.usuario.notificacao;

        this.$refs["mdlEditarUsuario"].show();
      });

      ApiService.get("bi/dashboard").then((response) => {
        let { data } = response;
        this.frmEditarUsuario.paineis = data;
      });

      ApiService.get(`bi/usuario/${this.usuario.id}/dashboard`).then(
        (response) => {
          let { data } = response;
          this.defaultsSelect.paineis = data.map((i) => i.bi_dash_id);
        }
      );
    },

    validacao() {
      const frmEditarUsuario = this.$refs["frmEditarUsuario"];

      Inputmask().mask(frmEditarUsuario.querySelectorAll("input"));

      this.fv = formValidation(frmEditarUsuario, {
        fields: {
          email: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              emailAddress: {
                message: "O email informado é inválido."
              },
              blank: {}
            }
          },
          nome: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          celular: {
            validators: {
              blank: {}
            }
          },
          grupo: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          },
          notificacao: {
            validators: {
              notEmpty: {
                message: "Campo obrigatório."
              },
              blank: {}
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          bootstrap: new Bootstrap()
        }
      });
    },

    salvar(e) {
      e.preventDefault();

      this.fv.validate().then((status) => {
        if (status == "Valid") {
          ApiService.post("usuario/editar", this.frmEditarUsuario)
            .then(async (response) => {
              const usuario = response.data.data;
              this.$emit("usuario:atualizado", usuario);
              this.$refs["mdlEditarUsuario"].hide();

              await this.salvarPaineis();

              Swal.fire({
                title: "MENSAGEM",
                text: "Salvo com sucesso!",
                icon: "success",
                heightAuto: false
              });
            })
            .catch(() => {
              for (const error in this.validatorErrors) {
                this.fv
                  .updateValidatorOption(
                    error,
                    "blank",
                    "message",
                    this.validatorErrors[error]
                  )
                  .updateFieldStatus(error, "Invalid", "blank");
              }
            });
        }
      });
    },

    async salvarPaineis() {
      await ApiService.put(`bi/usuario/${this.usuario.id}/dashboard`, {
        dashboard: this.defaultsSelect.paineis
      });
    },

    obterUnidades(arr) {
      this.frmEditarUsuario.unidades = arr.map((o) => {
        return { unidade_id: o.value, und_codigo: o.und };
      });
    },

    obterPaineis(arr) {
      this.frmEditarUsuario.paineis = arr.map((o) => {
        return { unidade_id: o.value, und_codigo: o.und };
      });
    },
    checarAcesso(permissoes) {
      const grupo = this.frmEditarUsuario.grupo;
      if (!grupo) {
        return false;
      }
      let valor = permissoes.some((item) => {
        return this.gruposPermissoes[item][grupo]?.permissao_ativa === 1;
      });
      return valor;
    }
  }
};
</script>
