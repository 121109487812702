<template>
  <div :name="'modal-cadastro-campamnha-' + modal_name">
    <ModalConfirmacao
      v-on:submitCampanhaFromModal="submitCampanhaFromModal"
      :campanhaWarningMessage="campanhaWarningMessage"
      :editar="modelo_campanha_id !== null"
      :modalRegister="true"
    />
    <ModalPreviewCampanhaTable
      :tableHeaders="tableHeaders"
      :tableContent="tableContent"
      v-if="tableContent"
    />
    <div class="modal-overlay" v-show="show"></div>
    <scale-transition class="box" :duration="500">
      <div
        class="modal-container"
        v-show="show"
        :name="'modal-cadastro-campamnha-' + modal_name"
        :class="is_fullscreen ? 'fullscreen' : ''"
      >
        <div class="modal-header">
          <div class="d-flex justify-content-between align-items-center">
            <div
              class="back-arrow-squared"
              v-if="!is_fullscreen"
              @click="maximizeModal"
            >
              <i class="fa-solid fa-maximize" style="font-size: 12px"></i>
            </div>
            <div class="back-arrow-squared" v-else @click="minimizeModal">
              <i class="fa-solid fa-minimize" style="font-size: 12px"></i>
            </div>
            &nbsp; &nbsp;
            <div class="back-arrow-squared" @click="closeModalRegisterCampaign">
              <i class="fa-solid fa-xmark" style="font-size: 12px"></i>
            </div>
          </div>
        </div>
        <div class="toggle-sistema-modelo-container">
          <div class="toggle-header">
            <h3 class="d-flex flex-column" v-if="modelo_campanha_id">
              <span
                :style="`font-size: ${
                  full_object_payload.mdc_nome ? '0.8rem' : '1.5rem'
                }; transition: font-size .2s ease-out;`"
                >Edição de Campanha</span
              >
              <span class="campaign-title">
                {{ full_object_payload.mdc_nome }}
              </span>
            </h3>
            <h3 v-else class="campaign-title">
              <span>Cadastro de Campanha</span>
            </h3>

            <b-button
              v-b-toggle="'modeloSistemaCollapse'"
              style="padding: 0; border: none; bacgrkound: none !important"
              class="text-uppercase"
              id="toggleModeloSistemaCollapse"
            >
              <a
                class="btn btn-icon d-flex align-items-center justify-center"
                style="width: 40px"
                @click="
                  is_sistema_modelo_toggle_active =
                    !is_sistema_modelo_toggle_active
                "
              >
                <i
                  v-if="is_sistema_modelo_toggle_active"
                  class="ki ki-arrow-down icon-nm"
                  style="color: inherit"
                ></i>
                <i
                  v-if="!is_sistema_modelo_toggle_active"
                  class="ki ki-arrow-up icon-nm"
                  style="color: inherit"
                ></i>
              </a>
            </b-button>
          </div>
          <b-collapse id="modeloSistemaCollapse" class="mt-2">
            <div class="row">
              <div class="col-6">
                <h4 class="mb-2 text-title">Nome da campanha</h4>
                <b-form-group class="w-100">
                  <b-form-input
                    name="nomeCadastro"
                    type="text"
                    class="camp-input-texto camp-cor-borda"
                    v-model="full_object_payload.mdc_nome"
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-3 align-items-center">
                <div class="d-flex">
                  <h4 class="mb-2 text-title">Tipo de Campanha</h4>
                </div>
                <div class="d-flex align-items-center">
                  <b-form-group class="w-100">
                    <b-form-select
                      class="w-100"
                      name="tipoCampanha"
                      :options="tipoCampanha_opcoes"
                      @change="setTipoCampanha"
                      v-model="tipoCampanha"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
              <div class="col-3 align-items-center">
                <div class="d-flex">
                  <h4 class="mb-2 text-title">Licenciada</h4>
                </div>
                <div class="d-flex align-items-center">
                  <b-form-group class="w-100">
                    <b-form-select
                      class="w-100"
                      name="licenciada"
                      :options="liscenced_options"
                      @change="pegarLicenciadasDoBanco"
                      v-model="selected_license"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col-4">
                <div class="d-flex">
                  <h4 class="mb-2 text-title">Tipo</h4>
                </div>

                <b-form-group class="w-100">
                  <b-form-select
                    class="w-100"
                    name="tipo"
                    :options="model_type_options"
                    @change="modeloPossiveis"
                    v-model="selected_model_type"
                  ></b-form-select>
                </b-form-group>
              </div>
              <div class="col-8">
                <div class="d-flex">
                  <h4 class="mb-2 text-title">Modelo</h4>
                </div>
                <b-form-group class="w-100">
                  <pv-dropdown
                    class="form-control w-100"
                    optionLabel="text"
                    optionValue="value"
                    emptyFilterMessage="Nenhum resultado"
                    :filter="true"
                    :options="modelosPossiveisVariable"
                    placeholder="Nenhum selecionado"
                    @change="selecionarModelo(selected_model)"
                    v-model="selected_model"
                  ></pv-dropdown>
                </b-form-group>
              </div>
            </div>
          </b-collapse>
        </div>
        <div class="tabs">
          <b-nav
            class="h-100 d-flex justify-content-center align-items-center"
            tabs
            fill
            style="width: fit-content; border: none !important"
          >
            <b-nav-item :active="active_tab == 0" @click="changeActiveTab(0)"
              >Canais
            </b-nav-item>
            <b-nav-item
              :active="active_tab == 1"
              @click="changeActiveTab(1)"
              :disabled="!can_show_filters"
              >Filtros
            </b-nav-item>
            <b-nav-item :active="active_tab == 2" @click="changeActiveTab(2)"
              >Frequência
            </b-nav-item>
            <b-nav-item :active="active_tab == 3" @click="changeActiveTab(3)"
              >Avançados
            </b-nav-item>
            <b-nav-item
              :active="active_tab === 4"
              @click="changeActiveTab(4)"
              v-if="this.modelo_campanha_id"
              >Webhook
            </b-nav-item>
          </b-nav>
          <div
            v-if="active_tab == 0"
            class="modal-canais-container border-container-body"
          >
            <div class="modal-canais-body">
              <ModalHtmlPreview :html="texto.canaisTexto['E-Mail']" />
              <div class="row">
                <div class="col">
                  <div class="d-flex justify-content-between">
                    <div class="canais-draggables-container">
                      <div
                        v-for="(item, index) in canais.opcoes.filter(
                          (canalFiltrado) =>
                            canais_nao_ordenaveis.includes(canalFiltrado.id)
                        )"
                        :key="index"
                        class="mr-2 tag-canais"
                        @click="gerenciarCanais(item)"
                        :class="[
                          canais.selecionados.find((v) => v.id === item.id)
                            ? 'active'
                            : '',
                          (!channelsVisibleBI && item.id !== 4) ||
                          (!channelsVisibleAmostra && item.id !== 5) ||
                          (!channelsVisibleAmostra && item.id !== 9)
                            ? 'inactive-channel'
                            : ''
                        ]"
                      >
                        {{ item.nome }}
                      </div>

                      <draggable
                        :list="canais_draggable_exibir"
                        tag="ul"
                        class="drag-canais"
                        :disabled="false"
                        draggable=".tag-canais"
                        :animation="100"
                        @end="onEnd"
                      >
                        <div
                          v-for="(
                            item, index
                          ) in canais_draggable_exibir.filter(
                            (canalFiltrado) =>
                              !canais_nao_ordenaveis.includes(canalFiltrado.id)
                          )"
                          :key="index"
                          class="mr-2 tag-canais"
                          @click="gerenciarCanais(item)"
                          :class="[
                            canais.selecionados.find((v) => v.id === item.id)
                              ? 'active'
                              : '',
                            (!channelsVisibleBI && item.id !== 4) ||
                            (!channelsVisibleAmostra && item.id !== 5)
                              ? 'inactive-channel'
                              : ''
                          ]"
                        >
                          <div class="drag-icon">
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                            <i class="fa-solid fa-ellipsis-vertical"></i>
                          </div>
                          <i
                            class="fa-solid fa-pencil pr-2"
                            v-if="checarCanalInativoComTexto(item)"
                            style="color: #000"
                          ></i>
                          {{ item.nome }}
                        </div>
                      </draggable>
                    </div>
                  </div>
                </div>

                <div class="col-12 mt-5">
                  <div class="d-flex flex-column" style="gap: 0.5rem">
                    <div class="d-flex flex-column" style="gap: 0.5rem">
                      <h5>Ordem de Envio</h5>
                      <SelectButton
                        v-model="followSequenceObject"
                        :options="arrFollowSequenceObject"
                        optionLabel="text"
                        optionValue="value"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div class="row mt-5" v-if="cacSenha">
                <div class="col">
                  <router-link
                    :to="'/painel/amostra/' + modelo_campanha_id"
                    class="btn btn-primary text-uppercase w-100"
                    target="_blank"
                    >ver dash amostra
                  </router-link>
                </div>
              </div>
              <div class="row mt-5" v-if="tarefasBIVisible">
                <div class="col">
                  <div class="d-flex">
                    <p class="text-title">Tarefas do BI</p>
                  </div>
                  <b-form-group class="w-100">
                    <b-form-select
                      :state="stateValidationSelect"
                      class="w-100"
                      name="tarefas"
                      :options="tarefasDoBIOptions"
                      v-model="tarefasDoBISelected"
                      @change="verificarTitCargaDireta"
                    ></b-form-select>
                  </b-form-group>
                </div>
              </div>

              <!-- INICIANDO EMAIL -->
              <div class="row" v-if="email">
                <div class="col">
                  <div class="d-flex">
                    <p class="text-title">Respostas para Email</p>
                  </div>
                  <b-form-group class="w-100">
                    <b-form-input
                      name="remetente"
                      type="text"
                      class="camp-input-texto camp-cor-borda"
                      v-model="email_reply_to"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col">
                  <div class="d-flex">
                    <p class="text-title">Remetente Email</p>
                  </div>
                  <b-form-group class="w-100">
                    <b-form-input
                      name="remetente"
                      type="text"
                      class="camp-input-texto camp-cor-borda"
                      v-model="email_remetente_email"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col">
                  <div class="d-flex">
                    <p class="text-title">Remetente Nome</p>
                  </div>
                  <b-form-group class="w-100">
                    <b-form-input
                      name="remetente"
                      type="text"
                      class="camp-input-texto camp-cor-borda"
                      v-model="email_remetente_nome"
                    ></b-form-input>
                  </b-form-group>
                </div>
                <div class="col">
                  <div class="d-flex">
                    <p class="text-title">Título do Email</p>
                  </div>
                  <b-form-group class="w-100">
                    <b-form-input
                      name="remetente"
                      type="text"
                      class="camp-input-texto camp-cor-borda"
                      v-model="email_titulo"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <!-- FECHANDO EMAIL-->

              <!-- INICIANDO ARQUIVO GERADO -->
              <div class="row" v-if="arquivoGerado">
                <h6 class="col-12 mt-5 text-title">Configurações</h6>
                <div class="col-3 mt-5">
                  <div class="d-flex">
                    <h6 class="mb-2 text-title">Tipo de Arquivo</h6>
                  </div>
                  <div class="d-flex align-items-center">
                    <b-form-group class="w-100">
                      <b-form-select
                        class="w-100"
                        name="tipoArquivoGerado"
                        v-model="canalArquivo.tipoArquivoGerado"
                      >
                        <b-form-select-option value="PDF">
                          PDF
                        </b-form-select-option>
                        <b-form-select-option value="IMAGEM">
                          Imagem
                        </b-form-select-option>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </div>

                <div class="col-12 mt-5">
                  <!-- iniciando pdf-->
                  <div
                    class="row mt-5"
                    v-if="canalArquivo.tipoArquivoGerado == 'PDF'"
                  >
                    <div class="col-2 align-items-center">
                      <div class="d-flex">
                        <h4 class="mb-2 text-title">Escala</h4>
                      </div>
                      <div class="d-flex align-items-center">
                        <b-form-group class="w-100">
                          <b-form-select
                            class="w-100"
                            name="type"
                            v-model="canalArquivo.pdf.scale"
                          >
                            <b-form-select-option value="0.5"
                              >50%</b-form-select-option
                            >
                            <b-form-select-option value="0.75"
                              >75%</b-form-select-option
                            >
                            <b-form-select-option value="1"
                              >100%</b-form-select-option
                            >
                            <b-form-select-option value="1.5"
                              >150%</b-form-select-option
                            >
                            <b-form-select-option value="2"
                              >200%</b-form-select-option
                            >
                          </b-form-select>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="col-2 align-items-center">
                      <div class="d-flex">
                        <h4 class="mb-2 text-title">Orientação da Página</h4>
                      </div>
                      <div class="d-flex align-items-center">
                        <b-form-group class="w-100">
                          <b-form-select
                            class="w-100"
                            name="landscape"
                            v-model="canalArquivo.pdf.landscape"
                          >
                            <b-form-select-option value="true">
                              Paisagem
                            </b-form-select-option>
                            <b-form-select-option value="false">
                              Retrato
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="col-2 align-items-center">
                      <div class="d-flex">
                        <h4 class="mb-2 text-title">Tamanho da Página</h4>
                      </div>
                      <div class="d-flex align-items-center">
                        <b-form-group class="w-100">
                          <b-form-select
                            class="w-100"
                            name="format"
                            v-model="canalArquivo.pdf.format"
                          >
                            <b-form-select-option value="letter">
                              Carta
                            </b-form-select-option>
                            <b-form-select-option value="legal">
                              Ofício
                            </b-form-select-option>
                            <b-form-select-option value="tabloid">
                              Tabloid
                            </b-form-select-option>
                            <b-form-select-option value="ledger">
                              Ledger
                            </b-form-select-option>
                            <b-form-select-option value="a0">
                              A0
                            </b-form-select-option>
                            <b-form-select-option value="a1">
                              A1
                            </b-form-select-option>
                            <b-form-select-option value="a2">
                              A2
                            </b-form-select-option>
                            <b-form-select-option value="a3">
                              A3
                            </b-form-select-option>
                            <b-form-select-option value="a4">
                              A4
                            </b-form-select-option>
                            <b-form-select-option value="a5">
                              A5
                            </b-form-select-option>
                            <b-form-select-option value="a6">
                              A6
                            </b-form-select-option>
                          </b-form-select>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="col-2 align-items-center">
                      <div class="d-flex">
                        <h4 class="mb-2 text-title">Exibir Cabeçalho/Rodapé</h4>
                      </div>
                      <div class="d-flex align-items-center">
                        <span class="switch">
                          <label>
                            <input
                              type="checkbox"
                              name="displayHeaderFooter"
                              v-model="canalArquivo.pdf.displayHeaderFooter"
                              true-value="true"
                              false-value="false"
                            />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div class="col-2 align-items-center">
                      <div class="d-flex">
                        <h4 class="mb-2 text-title">Exibir BackGroung</h4>
                      </div>
                      <div class="d-flex align-items-center">
                        <span class="switch">
                          <label>
                            <input
                              type="checkbox"
                              name="printBackground"
                              v-model="canalArquivo.pdf.printBackground"
                              true-value="true"
                              false-value="false"
                            />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>

                    <div class="col-12 mt-5 align-items-center">
                      <h4 class="mb-2 text-title">Margem</h4>
                    </div>
                    <div class="col-2 align-items-center">
                      <div class="d-flex">
                        <h4 class="mb-2 text-title">Topo</h4>
                      </div>
                      <div class="d-flex align-items-center">
                        <b-form-group class="w-100">
                          <b-form-input
                            name="margin.top"
                            type="number"
                            class="camp-input-number camp-cor-borda"
                            v-model="canalArquivo.pdf.margin.top"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="col-2 align-items-center">
                      <div class="d-flex">
                        <h4 class="mb-2 text-title">Fim</h4>
                      </div>
                      <div class="d-flex align-items-center">
                        <b-form-group class="w-100">
                          <b-form-input
                            name="margin.bottom"
                            type="number"
                            class="camp-input-number camp-cor-borda"
                            v-model="canalArquivo.pdf.margin.bottom"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="col-2 align-items-center">
                      <div class="d-flex">
                        <h4 class="mb-2 text-title">Esquerda</h4>
                      </div>
                      <div class="d-flex align-items-center">
                        <b-form-group class="w-100">
                          <b-form-input
                            name="margin.left"
                            type="number"
                            class="camp-input-number camp-cor-borda"
                            v-model="canalArquivo.pdf.margin.left"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>
                    <div class="col-2 align-items-center">
                      <div class="d-flex">
                        <h4 class="mb-2 text-title">Direira</h4>
                      </div>
                      <div class="d-flex align-items-center">
                        <b-form-group class="w-100">
                          <b-form-input
                            name="margin.right"
                            type="number"
                            class="camp-input-number camp-cor-borda"
                            v-model="canalArquivo.pdf.margin.right"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>

                    <!-- TODO: CONTINUAR DAQUI -->
                  </div>
                  <!-- fechando pdf-->
                  <!-- iniciando img-->
                  <div
                    class="row mt-5"
                    v-if="canalArquivo.tipoArquivoGerado == 'IMAGEM'"
                  >
                    <div class="col-2 align-items-center">
                      <div class="d-flex">
                        <h4 class="mb-2 text-title">Tipo de Arquivo</h4>
                      </div>
                      <div class="d-flex align-items-center">
                        <b-form-group class="w-100">
                          <b-form-select
                            class="w-100"
                            name="type"
                            v-model="canalArquivo.imagem.type"
                          >
                            <b-form-select-option value="png"
                              >PNG</b-form-select-option
                            >
                            <b-form-select-option value="jpeg"
                              >Jpeg</b-form-select-option
                            >
                            <b-form-select-option value="webp"
                              >Webp</b-form-select-option
                            >
                          </b-form-select>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="col-2 align-items-center">
                      <div class="d-flex">
                        <h4 class="mb-2 text-title">Qualidade da Imagem</h4>
                      </div>
                      <div class="d-flex align-items-center">
                        <b-form-group class="w-100">
                          <b-form-select
                            class="w-100"
                            name="quality"
                            v-model="canalArquivo.imagem.quality"
                          >
                            <b-form-select-option value="100"
                              >Alta</b-form-select-option
                            >
                            <b-form-select-option value="75"
                              >Média</b-form-select-option
                            >
                            <b-form-select-option value="50"
                              >Baixa</b-form-select-option
                            >
                          </b-form-select>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="col-2 align-items-center">
                      <div class="d-flex">
                        <h4 class="mb-2 text-title">Codificação</h4>
                      </div>
                      <div class="d-flex align-items-center">
                        <b-form-group class="w-100">
                          <b-form-select
                            class="w-100"
                            name="quality"
                            v-model="canalArquivo.imagem.encoding"
                          >
                            <b-form-select-option value="binary"
                              >Binário</b-form-select-option
                            >
                            <b-form-select-option value="base64"
                              >Base 64</b-form-select-option
                            >
                          </b-form-select>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="col-2 align-items-center">
                      <div class="d-flex">
                        <h4 class="mb-2 text-title">Exibir Background</h4>
                      </div>
                      <div class="d-flex align-items-center">
                        <span class="switch">
                          <label>
                            <input
                              type="checkbox"
                              name="omitBackground"
                              v-model="canalArquivo.imagem.omitBackground"
                              true-value="true"
                              false-value="false"
                            />
                            <span></span>
                          </label>
                        </span>
                      </div>
                    </div>
                    <!-- fechando -->
                  </div>
                  <!-- fechando img-->
                </div>
              </div>
              <!-- FECHANDO ARQUIVO GERADO-->

              <div class="row mt-5">
                <div class="col">
                  <div class="d-flex">
                    <p class="text-title">Texto da campanha</p>
                  </div>
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                      align-items: last baseline;
                      width: 100%;
                    "
                  >
                    <b-nav tabs align="left" style="width: 100%">
                      <transition-group
                        enter-active-class="animate__animated animate__zoomIn"
                        leave-active-class="animate__animated animate__zoomOut"
                        style="display: flex"
                      >
                        <b-nav-item
                          v-for="(
                            canal, index
                          ) in this.canais.selecionados.filter(
                            (v) => v.id !== 4
                          )"
                          :key="index"
                          :active="canal.nome == texto.controle.selecionado"
                          @click="trocaAbasTexto(canal)"
                          >{{ canal.nome.toUpperCase() }}
                        </b-nav-item>
                        <b-nav-item
                          key="sc"
                          v-if="canais.selecionados.length == 0"
                          >{{ "S/C" }}
                        </b-nav-item>
                      </transition-group>
                    </b-nav>
                  </div>

                  <section class="d-flex" style="max-height: 400px">
                    <div
                      class="camp-body-right-texto camp-cor-borda d-flex flex-column w-75"
                      style="position: relative"
                    >
                      <ckeditor
                        :editor="editor"
                        v-model="texto.canaisTexto[texto.controle.selecionado]"
                        :config="editor_config"
                        v-if="richTextAtivo && !rich_text_ver_html"
                        class="ckeditor"
                        ref="ckeditor-component"
                      ></ckeditor>
                      <textarea
                        class="camp-body-right-textarea h-75"
                        v-model="texto.canaisTexto[texto.controle.selecionado]"
                        type="text"
                        placeholder="Digite o texto da campanha aqui"
                        :disabled="
                          texto.controle.selecionado === 'Whatsapp' &&
                          templateOptions.length > 0 &&
                          lockTextoCampanha
                        "
                        :readonly="canais.selecionados.length == 0"
                        id="textoCanaisTextArea"
                        v-if="
                          !richTextAtivo &&
                          texto.controle.selecionado !== 'Webhook'
                        "
                      />
                      <div>
                        <WebhookOptions
                          v-if="texto.controle.selecionado === 'Webhook'"
                          :json="texto.canaisTexto"
                          @focused="handleFocusEvent"
                        ></WebhookOptions>
                      </div>
                      <b-button
                        v-if="richTextAtivo"
                        variant="outline-primary"
                        @click="toggleRichTextVerHtml"
                        class="btn btn-secondary rich-text-html-btn text-uppercase"
                        >HTML
                      </b-button>
                      <textarea
                        class="camp-body-right-textarea h-75"
                        v-model="texto.canaisTexto[texto.controle.selecionado]"
                        type="text"
                        placeholder="Insira o html"
                        v-if="richTextAtivo && rich_text_ver_html"
                      />
                      <i
                        v-if="
                          textoWpp && texto.controle.selecionado === 'Whatsapp'
                        "
                        @click="copyToClickBoard(textoWpp)"
                        ref="btnCopyToClickBoard"
                        class="far fa-copy icon-xl"
                        data-toggle="tooltip"
                        title="Copiar texto"
                        style="
                          cursor: pointer;
                          position: absolute;
                          right: 50px;
                          top: 20px;
                          transition: all 0.3s ease-in-out;
                        "
                      />

                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          align-items: center;
                        "
                      >
                        <div class="d-flex">
                          <b-button
                            class="text-uppercase btn-adicionar-imagem"
                            :class="
                              this.texto.controle.selecionado === 'Whatsapp'
                                ? 'active'
                                : ''
                            "
                            @click="ativarImagemInput"
                            >{{
                              file_preview ? "Alterar" : "Selecione a Imagem"
                            }}
                          </b-button>
                          <b-button
                            class="text-uppercase btn-adicionar-imagem btn-danger ml-3"
                            v-if="
                              this.file_preview && this.caminhoImageWhatsapp
                            "
                            @click="removeImage"
                            :class="
                              this.texto.controle.selecionado === 'Whatsapp'
                                ? 'active'
                                : ''
                            "
                          >
                            Remover
                          </b-button>
                          <b-button
                            class="text-uppercase btn-selecionar-template ml-3"
                            v-if="
                              verificarRecursoAtivo('WHATSAPP_OFICIAL') &&
                              templateOptions.length
                            "
                            :class="
                              this.texto.controle.selecionado === 'Whatsapp'
                                ? 'active'
                                : ''
                            "
                            @click="showModalTemplates"
                            >Selecionar Template
                          </b-button>
                          <ToggleButton
                            v-if="
                              verificarRecursoAtivo('WHATSAPP_OFICIAL') &&
                              templateOptions.length &&
                              this.texto.controle.selecionado === 'Whatsapp'
                            "
                            style="
                              border-bottom-width: 0px;
                              border-top-width: 0px;
                              margin-left: 10px;
                              margin-bottom: 10px;
                              background: #1bc5bd !important;
                              border-color: #1bc5bd !important;
                              margin-top: 5px;
                            "
                            v-model="lockTextoCampanha"
                            onIcon="pi pi-lock"
                            offIcon="pi pi-lock-open"
                          />
                        </div>
                        <b-button
                          :style="
                            this.texto.controle.selecionado !== 'Whatsapp'
                              ? 'display: none'
                              : ''
                          "
                          @click="showModalImagem"
                          style="background: transparent !important; padding: 0"
                          class="text-uppercase"
                        >
                          <img
                            v-if="file_preview && !verificarSeArquivoEVideo"
                            :src="file_preview"
                            alt="Imagem do Whatsapp"
                            width="32"
                            id="imgFilePreview"
                          />
                          <b-button v-else-if="file_preview"
                            >VER MÍDIA
                          </b-button>
                        </b-button>
                      </div>
                      <input
                        class="d-none"
                        ref="fileInput"
                        type="file"
                        id="formFileLg"
                        @input="selectImgFile"
                        @change="uploadImgFile"
                        chooseLabel="SELECIONAR IMAGEM"
                        :showUploadButton="false"
                        :showCancelButton="false"
                      />
                      <input
                        class="d-none"
                        ref="emailFileInput"
                        type="file"
                        id="formFileLg"
                        chooseLabel="SELECIONAR ARQUIVO"
                        :showUploadButton="false"
                        :showCancelButton="false"
                      />
                      <modal
                        name="modal-imagem"
                        height="auto"
                        scrollable
                        class="modal-imagem"
                        @click="hideModalImagem"
                      >
                        <video
                          v-if="verificarSeArquivoEVideo"
                          width="320"
                          height="240"
                          controls
                        >
                          <source :src="file_preview" type="video/mp4" />
                        </video>
                        <img
                          v-else
                          @click="hideModalImagem"
                          style="max-width: 100%; max-height: 100vh"
                          :src="file_preview"
                          alt="Visualizar"
                        />
                      </modal>
                      <modal
                        name="modal-templates"
                        height="auto"
                        width="50%"
                        scrollable
                        class="modal-templates"
                        @click="hideModalImagem"
                      >
                        <div class="container text-center">
                          <div class="d-flex justify-content-end">
                            <!-- TODO: Verificar o porquê de remover quando for edição -->
                            <div
                              class="back-arrow-squared mt-3"
                              @click="hideModalTemplates"
                              style="cursor: pointer"
                            >
                              <i
                                class="fa-solid fa-xmark"
                                style="font-size: 12px"
                              ></i>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-12">
                              <span
                                class="p-input-icon-left pb-2 pt-4"
                                style="width: 100%"
                              >
                                <i class="pi pi-search" />
                                <InputText
                                  style="width: 100%"
                                  type="text"
                                  v-model="searchTemplateValue"
                                  placeholder="Pesquisar template"
                                />
                              </span>
                              <ScrollPanel
                                style="
                                  width: 100%;
                                  height: 500px;
                                  overflow-y: hidden !important;
                                "
                              >
                                <Card
                                  class="btn-light m-5 p-0"
                                  :class="{
                                    'is-template-wp-selected':
                                      isTemplateWppSelected(template)
                                  }"
                                  v-for="(template, key) in filtrarTemplate"
                                  :key="template.name"
                                >
                                  <template #content>
                                    <div class="row pl-5 pr-5">
                                      <div class="col-6">
                                        <div class="row">
                                          <div class="col-12">
                                            <div
                                              style="
                                                background-color: #6c757d36;
                                                height: 100%;
                                                color: black;
                                                border-radius: 5px;
                                              "
                                              class="pt-6 pl-6 pr-6"
                                            >
                                              <h4
                                                class="mb-6"
                                                style="
                                                  text-transform: uppercase;
                                                  color: black;
                                                  overflow-wrap: break-word;
                                                "
                                              >
                                                {{ template.name }}
                                              </h4>
                                              <TemplateWppVariaveis
                                                v-model="filtrarTemplate[key]"
                                                :options="variaveisDeTextoNome"
                                              ></TemplateWppVariaveis>
                                            </div>
                                          </div>
                                          <div class="col-12" v-if="template">
                                            <div
                                              v-for="component in template.components"
                                              :key="component.name"
                                            >
                                              <div
                                                v-for="(
                                                  data, key
                                                ) in variaveisFor(component)"
                                                :key="key"
                                                class="pt-3"
                                              >
                                                <label
                                                  :for="component.type"
                                                  v-if="
                                                    variaveisComponent(
                                                      component,
                                                      true
                                                    ).length > 0
                                                  "
                                                >
                                                  {{ component.type }}
                                                </label>
                                                <div
                                                  v-for="(
                                                    variable, variableKey
                                                  ) in data"
                                                  :key="variableKey"
                                                >
                                                  <div
                                                    class="d-flex w-100 mt-2"
                                                    v-for="(
                                                      v, k
                                                    ) in variaveisComponent(
                                                      component,
                                                      true
                                                    )"
                                                    :key="k"
                                                  >
                                                    <span
                                                      class="p-inputgroup-addon w-25"
                                                      v-html="`{{${k + 1}}}`"
                                                    />
                                                    <Dropdown
                                                      filter
                                                      :inputId="`i-dp-${k}`"
                                                      editable
                                                      filterPlaceholder="Pesquisar"
                                                      appendTo="body"
                                                      emptyFilterMessage="Variável não encontrada"
                                                      :key="
                                                        updateDropdownTemplate
                                                      "
                                                      @input="
                                                        forceUpdateDropdownTemplates
                                                      "
                                                      v-model="
                                                        variaveisSelecionadas[
                                                          `${template.name}|${
                                                            component.type
                                                          }${
                                                            component.type ==
                                                            'BUTTONS'
                                                              ? variableKey
                                                              : ''
                                                          }|${v}`
                                                        ]
                                                      "
                                                      :options="
                                                        variaveisDeTextoNome
                                                      "
                                                      placeholder="Digite ou selecione a variável"
                                                      style="text-align: left"
                                                      class="md:w-14rem w-100"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <PreviewWhatsapp
                                          style="
                                            color: black;
                                            text-align: left;
                                            border-radius: 5px;
                                          "
                                          v-model="template.components"
                                        >
                                        </PreviewWhatsapp>
                                      </div>
                                      <div class="col-12 pt-4">
                                        <b-button
                                          :disabled="!validaTemplate(template)"
                                          class="btn btn-primary btn-block btn-success"
                                          v-tooltip.bottom="{
                                            value:
                                              'Informe todas as variáveis para selecionar o template',
                                            disabled: validaTemplate(template)
                                          }"
                                          @click="selecionarTemplate(template)"
                                        >
                                          Selecionar Template
                                        </b-button>
                                      </div>
                                    </div>
                                  </template>
                                </Card>
                              </ScrollPanel>
                            </div>
                          </div>
                        </div>
                      </modal>
                    </div>

                    <div class="camp-body-right-variaveis camp-cor-borda">
                      <div v-if="richTextAtivo" class="d-flex flex-column">
                        <b-button
                          class="w-100 mt-3 text-uppercase"
                          @click="ativarEmailInput"
                          v-if="texto.controle.selecionado === 'E-Mail'"
                          >Anexar Arquivo
                        </b-button>
                        <b-button
                          class="w-100 mt-3 text-uppercase"
                          @click="mostrarModalHtmlPreview"
                          >Preview
                        </b-button>
                      </div>
                      <div style="min-height: 400px">
                        <span>Variáveis</span>

                        <div
                          class="mt-2 overflow-auto"
                          :style="
                            texto.controle.selecionado === 'E-Mail'
                              ? 'height: 150px !important;'
                              : texto.controle.selecionado === 'PDF'
                              ? 'height: 150px !important;'
                              : ''
                          "
                          style="
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            overflow-y: auto;
                          "
                        >
                          <button
                            class="botao-variaveis"
                            v-for="item in variaveisDeTextoNome"
                            :key="item"
                            @click="insertIntoCursor($event, item)"
                          >
                            {{ item }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div v-if="active_tab == 1" class="border-container-body">
            <b-tabs>
              <b-tab title="Filtro Campanha" class="p-4">
                <div class="modal-header col">
                  <div class="presets-container">
                    <b-button
                      v-for="(preset, idx) in presetsArr"
                      :key="idx"
                      class="mr-3 text-uppercase"
                      size="sm"
                      @click="insertPresetFilter(preset.presetFilter)"
                      >{{ preset.name }}
                    </b-button>
                  </div>
                </div>
                <div
                  class="col d-flex flex-column justify-content-center align-items-center"
                >
                  <div class="nested-header w-100">
                    <div class="d-flex flex-align-end">
                      <transition name="fade">
                        <div
                          class="total-de-mensagens-container"
                          v-if="campanhaExecutada && totalDeMensagens !== null"
                        >
                          <span>Total de mensagens da campanha:</span>
                          <strong>{{ totalDeMensagens }}</strong>
                        </div>
                      </transition>
                      <transition name="fade-slower">
                        <div
                          class="btn botao-filtro-ver-preview mr-5 text-uppercase"
                          @click="solicitarPrevia()"
                          v-if="btnMostrarModalVisible || isBankAPI"
                          ref="btnExecutarPreviewCompleto"
                        >
                          Solicitar Prévia Completa
                        </div>
                      </transition>
                      <div
                        class="btn botao-filtro-preview text-uppercase mr-3"
                        variant="primary"
                        @click="solicitarPreviaApenasCount()"
                        v-if="listaDeFiltros.length > 0 && !isBankAPI"
                        ref="btnExecutarPreviewModalCadastroCampanha"
                      >
                        Solicitar Prévia
                      </div>
                      <button
                        class="btn botao-filtro-add text-uppercase"
                        @click="adicionarNovoFiltro()"
                      >
                        + Adicionar novo filtro
                      </button>
                    </div>
                  </div>

                  <nested-draggable
                    :group="0"
                    :listaDeFiltros="listaDeFiltros"
                    :camposPossiveisDoFiltro="camposPossiveisDoFiltro"
                    :opreadoresFiltro="opreadoresFiltro"
                    class="col mt-5"
                    :isDraggable="true"
                    :sistemaLicenciadaId="
                      full_object_payload.sistema_licenciada_id
                    "
                    :contextFunctions="context_functions"
                    @nestedErrorToast="nestedErrorToast"
                  >
                  </nested-draggable>
                </div>
              </b-tab>
              <b-tab
                v-if="variaveisFiltrosNativosPadrao"
                title="Filtro Nativo"
                class="p-4"
              >
                <div class="row p-5">
                  <div class="col">
                    <div class="row" v-if="variaveisFiltrosNativosPadrao">
                      <div
                        v-for="(filterLine, i) in filtroNativoPeruca"
                        :key="i + filterLine"
                        class="d-flex mt-3 w-100"
                      >
                        <div
                          v-for="(substr, idx) in filterLine"
                          :key="idx + substr"
                          class=""
                          style="white-space: pre"
                        >
                          <div
                            style="d-flex; font-size: 14px; rgb(58 58 58);"
                            v-html="substr['campo']"
                            v-if="substr['type'] === 's'"
                            class="d-flex"
                          ></div>
                          <input
                            v-else
                            name="filtroNativoInput"
                            type="text"
                            class="camp-cor-borda"
                            v-model="
                              variaveisFiltrosNativosPadrao['VARIAVEIS'][
                                substr['campo']
                              ]
                            "
                            style="max-width: 75px"
                          />
                        </div>
                        <div class="ml-5 d-flex">
                          <span style="font-size: 14px" class="mr-2"
                            >Operador:
                          </span>
                          <input
                            name="filtroNativoInput"
                            type="text"
                            class="camp-cor-borda"
                            style="max-width: 75px"
                            v-model="
                              variaveisFiltrosNativosPadrao['OPERADORES'][i]
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
          <div v-if="active_tab == 2" class="border-container-body">
            <scale-transition group class="frequencies-group">
              <div
                class="d-flex flex-column"
                v-for="(freq, idx) in this.frequencia"
                :key="idx"
                v-show="idx"
                style="
                  background: #e8f3d6;
                  padding: 1rem;
                  border-radius: 8px;

                  width: 49%;
                "
              >
                <div class="d-flex">
                  <p class="text-title" style="font-weight: 600">
                    Frequência -
                    <strong style="color: rgb(255, 168, 0)">
                      {{ idx + 1 }}
                    </strong>
                  </p>
                </div>
                <div class="d-flex">
                  <b-form-select
                    v-model="freq.dropdown.selecionado"
                    :options="freq.dropdown.opcoes"
                    :id="'frequencia-' + idx"
                    class="col"
                    :disabled="
                      freq.dropdown.selecionado.value !== -1 ? true : false
                    "
                  ></b-form-select>
                  <div
                    class="d-flex"
                    :class="
                      freq.dropdown.selecionado.value !== -1 ? 'col-6' : ''
                    "
                    style="padding-right: 0 !important"
                  >
                    <b-button
                      variant="outline-primary"
                      class="button-plus text-uppercase"
                      @click="createNewFrequency()"
                      >+
                    </b-button>
                    <b-button
                      v-if="frequencia.length > 1"
                      variant="outline-danger"
                      class="button-plus d-block text-uppercase"
                      @click="removerFrequencia(idx)"
                      >-
                    </b-button>
                    <ToggleButton
                      v-if="freq.dropdown.selecionado.value !== -1"
                      v-model="freq.mdc_ativo"
                      onLabel="Ativo"
                      offLabel="Inativo"
                      style="height: 38px"
                      size="lg"
                      class="ml-3 w-100 text-uppercase"
                    />
                  </div>
                </div>

                <b-row class="mt-3" v-if="freq.dropdown.selecionado != 0">
                  <b-col>
                    <p class="text-title" style="font-weight: 600">
                      Data e hora de início:
                    </p>
                    <b-row>
                      <b-col cols="7" class="pr-0">
                        <b-input
                          v-model="freq.dataInicio"
                          type="date"
                          @change="definirDataHoraInicio(idx)"
                        ></b-input>
                      </b-col>
                      <b-col cols="5">
                        <b-input
                          v-model="freq.horaInicio"
                          type="time"
                          @change="definirDataHoraInicio(idx)"
                        ></b-input>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col>
                    <p class="text-title" style="font-weight: 600">
                      Data e hora de término:
                    </p>
                    <b-row>
                      <b-col cols="7" class="pr-0">
                        <b-input
                          v-model="freq.dataTermino"
                          type="date"
                          @change="definirDataHoraTermino(idx)"
                        ></b-input>
                      </b-col>
                      <b-col cols="5">
                        <b-input
                          v-model="freq.horaTermino"
                          type="time"
                          @change="definirDataHoraTermino(idx)"
                        ></b-input>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>

                <div class="mt-3">
                  <transition
                    mode="out-in"
                    enter-active-class="animate__animated animate__zoomIn"
                  >
                    <section v-if="freq.dropdown.selecionado == 0" key="0">
                      <div class="">
                        <p class="text-title" style="font-weight: 600">
                          Dia e hora
                        </p>
                        <vc-date-picker
                          v-model="freq.umaVez.dataSelecionada"
                          mode="dateTime"
                          :timezone="freq.umaVez.timezone"
                          is24hr
                          aria-orientation="horizontal"
                        >
                          <template v-slot="{ inputValue, inputEvents }">
                            <input
                              class="camp-input-date camp-cor-borda w-100"
                              :value="inputValue"
                              v-on="inputEvents"
                              readonly
                            />
                          </template>
                        </vc-date-picker>
                      </div>
                    </section>

                    <section
                      class="d-flex"
                      v-if="freq.dropdown.selecionado == 1"
                      key="1"
                    >
                      <div>
                        <p class="text-title" style="font-weight: 600">
                          Hora inicial
                        </p>
                        <b-form-select
                          :options="freq.aCadaMinuto.horasOpcoes"
                          v-model="freq.aCadaMinuto.intervaloInicio"
                          @change="() => (freq.aCadaMinuto.intervaloFim = null)"
                        ></b-form-select>
                      </div>
                      <div class="ml-5">
                        <p class="text-title" style="font-weight: 600">
                          Hora final
                        </p>

                        <b-form-select
                          :options="
                            getFinalHourOptionsBasedOnStartHour(
                              freq.aCadaMinuto.intervaloInicio
                            )
                          "
                          v-model="freq.aCadaMinuto.intervaloFim"
                          :disabled="!freq.aCadaMinuto.intervaloInicio"
                        ></b-form-select>
                      </div>
                      <div class="ml-5">
                        <p class="text-title" style="font-weight: 600">
                          Minuto
                        </p>

                        <b-form-select
                          :options="freq.aCadaMinuto.minutoOpcoes"
                          v-model="freq.aCadaMinuto.minuto"
                        ></b-form-select>
                      </div>
                    </section>
                    <section
                      class="d-flex"
                      v-if="freq.dropdown.selecionado == 2"
                      key="2"
                    >
                      <div>
                        <p class="text-title" style="font-weight: 600">
                          Hora inicial
                        </p>
                        <b-form-select
                          :options="freq.umaVezHora.horasOpcoes"
                          v-model="freq.umaVezHora.intervaloInicio"
                          @change="() => (freq.umaVezHora.intervaloFim = null)"
                        ></b-form-select>
                      </div>
                      <div class="ml-5">
                        <p class="text-title" style="font-weight: 600">
                          Hora final
                        </p>

                        <b-form-select
                          :options="
                            getFinalHourOptionsBasedOnStartHour(
                              freq.umaVezHora.intervaloInicio
                            )
                          "
                          v-model="freq.umaVezHora.intervaloFim"
                          :disabled="!freq.umaVezHora.intervaloInicio"
                        ></b-form-select>
                      </div>
                      <div class="ml-5">
                        <p class="text-title" style="font-weight: 600">
                          Minuto da hora
                        </p>

                        <b-form-select
                          :options="freq.umaVezHora.dropdown.opcoes"
                          v-model="freq.umaVezHora.dropdown.selecionado"
                        ></b-form-select>
                      </div>
                    </section>

                    <section v-if="freq.dropdown.selecionado == 3" key="3">
                      <div>
                        <p class="text-title" style="font-weight: 600">
                          Horário
                        </p>
                        <b-form-input
                          class="camp-input-date camp-cor-borda"
                          type="time"
                          v-model="freq.umaVezDia.hora"
                          max="31"
                          min="1"
                        />
                      </div>
                    </section>

                    <section v-if="freq.dropdown.selecionado == 4" key="4">
                      <div style="display: flex">
                        <div>
                          <p class="text-title" style="font-weight: 600">Dia</p>

                          <b-form-select
                            :options="freq.umaVezSemana.dropdown.opcoes"
                            v-model="freq.umaVezSemana.dropdown.selecionado"
                          ></b-form-select>
                        </div>
                        <div>
                          <p class="text-title" style="font-weight: 600">
                            Horário
                          </p>
                          <b-form-input
                            type="time"
                            v-model="freq.umaVezSemana.hora"
                            class="camp-input-hora camp-cor-borda"
                          />
                        </div>
                      </div>
                    </section>

                    <section v-if="freq.dropdown.selecionado == 5" key="5">
                      <div style="display: flex; align-items: center">
                        <div class="mr-4">
                          <p class="text-title" style="font-weight: 600">
                            Dia do mês
                          </p>
                          <b-form-input
                            v-model="freq.umaVezMes.dia"
                            class="camp-cor-borda camp-input-dia"
                            type="number"
                            min="1"
                          />
                        </div>
                        <div>
                          <p class="text-title" style="font-weight: 600">
                            Horário
                          </p>
                          <b-form-input
                            v-model="freq.umaVezMes.hora"
                            class="camp-input-hora camp-cor-borda"
                            type="time"
                            max="31"
                            min="1"
                            required
                          />
                        </div>
                      </div>
                    </section>

                    <section v-if="freq.dropdown.selecionado == 6" key="6">
                      <div class="d-50"></div>
                      <div style="display: flex" class="mt-lg-3">
                        <div class="mr-1">
                          <p class="text-title" style="font-weight: 600">Mês</p>

                          <b-form-select
                            :options="freq.umaVezAno.dropdown.opcoes"
                            v-model="freq.umaVezAno.dropdown.selecionado"
                          ></b-form-select>
                        </div>
                        <div class="mr-1">
                          <p class="text-title" style="font-weight: 600">Dia</p>
                          <b-form-input
                            type="number"
                            v-model="freq.umaVezAno.dia"
                            class="camp-input-dia"
                            min="1"
                          />
                        </div>
                        <div>
                          <p class="text-title" style="font-weight: 600">
                            Horário
                          </p>
                          <b-form-input
                            type="time"
                            v-model="freq.umaVezAno.hora"
                            class="camp-input-hora"
                          />
                        </div>
                      </div>
                    </section>
                  </transition>
                </div>
              </div>
            </scale-transition>
          </div>
          <div v-if="active_tab == 3" class="border-container-body">
            <div class="row mb-3 mt-5">
              <div class="col">
                <div
                  class="row mb-3"
                  v-if="callback.dropdown.opcoes.length > 0"
                >
                  <div class="col-6">
                    <section
                      class="section-data d-flex flex-column justify-content-between h-100"
                    >
                      <div class="d-flex">
                        <p class="text-title">Ação ao Obter Resposta</p>
                      </div>
                      <div>
                        <b-form-select
                          :options="callback.dropdown.opcoes"
                          v-model="full_object_payload.callback_id"
                          @change="changeNumeroMaximoHoras"
                        ></b-form-select>
                      </div>
                    </section>
                  </div>
                  <div class="col">
                    <section
                      class="section-data d-flex flex-column justify-content-between h-100"
                    >
                      <div class="d-flex">
                        <p class="text-title">Número Máximo de Horas</p>
                      </div>
                      <b-form-group class="w-100">
                        <b-form-input
                          type="number"
                          v-model="full_object_payload.cam_limite_maximo_horas"
                          name="numeroMaximoDeHoras"
                          class="camp-input-texto camp-cor-borda"
                        ></b-form-input>
                      </b-form-group>
                    </section>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6" v-if="subcontas.length > 1">
                    <section class="section-data">
                      <div class="d-flex">
                        <p class="text-title">Subcontas</p>
                      </div>
                      <b-form-select
                        :options="subcontas"
                        @change.native="getWhatsappTemplates"
                        min="1"
                        max="24"
                        v-model="full_object_payload.mdc_preferencial_conta_id"
                        class="col"
                      ></b-form-select>
                    </section>
                  </div>
                  <div :class="subcontas.length > 1 ? 'col-6' : 'col'">
                    <section class="section-data">
                      <div class="d-flex">
                        <p class="text-title">Funções Pós Envio</p>
                      </div>
                      <MultiSelect
                        class="col"
                        v-model="funcoesPosEnvioSelecionadas"
                        :options="funcoesPosEnvio"
                        optionLabel="name"
                        placeholder="Funções pós envio"
                        size="lg"
                      />
                    </section>
                  </div>
                </div>
                <div class="row mt-5">
                  <div
                    class="col-6"
                    :class="subcontas.length === 0 ? 'w-100' : ''"
                  >
                    <p class="text-title">Duplicidade</p>
                    <div class="d-flex mt-3">
                      <RadioButton
                        inputId="radio1"
                        name="radioMsgDuplicada"
                        :value="true"
                        v-model="toggleMsgDuplicada"
                        class="mr-2"
                      />
                      <label for="radio1"
                        >Permitindo Duplicidade de Mensagens</label
                      >
                    </div>
                    <div class="d-flex mt-3">
                      <RadioButton
                        inputId="radio2"
                        name="radioMsgDuplicada"
                        :value="false"
                        v-model="toggleMsgDuplicada"
                        class="mr-2"
                      />
                      <label for="radio2"
                        >Não Permitindo Duplicidade de Mensagens</label
                      >
                    </div>
                  </div>
                  <div
                    class="col-6"
                    :class="subcontas.length === 0 ? 'w-100' : ''"
                  >
                    <p class="text-title">Antecipação de Feriados</p>
                    <div class="d-flex mt-3">
                      <RadioButton
                        inputId="radio1"
                        name="radioFeriados"
                        :value="true"
                        v-model="full_object_payload.mdc_antecipar_feriado"
                        class="mr-2"
                      />
                      <label for="radio1">Antecipando Feriados</label>
                    </div>
                    <div class="d-flex mt-3">
                      <RadioButton
                        inputId="radio2"
                        name="radioFeriados"
                        :value="false"
                        v-model="full_object_payload.mdc_antecipar_feriado"
                        class="mr-2"
                      />
                      <label for="radio2">Não Antecipando Feriados</label>
                    </div>
                  </div>
                </div>
                <div class="row mt-5">
                  <div class="col">
                    <section
                      class="section-data d-flex flex-column justify-content-between h-100"
                    >
                      <div class="d-flex">
                        <p class="text-title">Número Máximo de Consultas</p>
                      </div>
                      <b-form-input
                        type="number"
                        min="0"
                        v-model="limiteConsulta"
                        name="numeroMaximoConsulta"
                        class="camp-input-texto camp-cor-borda"
                      ></b-form-input>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="active_tab == 4" class="border-container-body">
            <div class="row mb-3 mt-5 ml-2">
              <div class="col-12">
                <div class="form-group mt-3">
                  <!-- Contêiner flexível para colocar os itens lado a lado -->
                  <div class="d-flex align-items-center">
                    <!-- Coluna para o switch e seu label -->
                    <div class="d-flex align-items-center mr-5">
                      <p class="text-title mb-0 mr-3">Ativar Webhook</p>
                      <span
                        class="switch switch-sm"
                        :class="
                          toggleWebhookStatus
                            ? 'switch-success'
                            : 'switch-secondary'
                        "
                      >
                        <label>
                          <input
                            :disabled="!this.full_object_payload.webhook"
                            type="checkbox"
                            name="respostaChamada"
                            v-model="toggleWebhookStatus"
                          />
                          <span></span>
                        </label>
                      </span>
                    </div>

                    <!-- Coluna para o botão e seu label -->
                    <div class="d-flex align-items-center">
                      <p class="text-title mb-0 mr-2">Gerar novo link</p>
                      <button
                        class="btn btn-ok-whatsapp ml-3"
                        v-b-tooltip.hover="
                          'Gera um novo link e invalida o link anterior.'
                        "
                        @click="gerarNovoLinkWebhook"
                      >
                        <i
                          class="fa fa-refresh text-white"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mt-3">
                  <div class="d-flex align-items-center">
                    <p class="text-title">Campo de telefone</p>
                    <i
                      class="fas fa-info-circle ml-2 text-muted pb-3"
                      v-b-tooltip.hover="
                        'Os valores informados não são armazenados! Insira o caminho completo do número de telefone, pode ser informado mais de um separado por vírgulas, o primeiro não nulo será utilizado, por exemplo: user.phone,user.telefone'
                      "
                    ></i>
                  </div>
                  <b-form-group class="w-100">
                    <b-form-input
                      type="text"
                      v-model="phonePath"
                      name="phonePath"
                      class="camp-input-texto camp-cor-borda"
                      placeholder="Caminho do número de telefone (Ex: user.phone)"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group mt-3">
                  <div class="d-flex align-items-center">
                    <p class="text-title">Campo de dados</p>
                    <i
                      class="fas fa-info-circle ml-2 text-muted pb-3"
                      v-b-tooltip.hover="
                        'Os valores informados não são armazenados! Insira o caminho completo do campo de dados, por exemplo: user.data'
                      "
                    ></i>
                  </div>
                  <b-form-group class="w-100">
                    <b-form-input
                      type="text"
                      v-model="dataPath"
                      name="dataPath"
                      class="camp-input-texto camp-cor-borda"
                      placeholder="Caminho dos dados (Ex: user.data)"
                    ></b-form-input>
                  </b-form-group>
                </div>
              </div>
              <div class="col-12 pt-2">
                <div class="form-group mb-3">
                  <div class="d-flex">
                    <p class="text-title">Link</p>
                  </div>
                  <div class="input-group">
                    <input
                      type="text"
                      id="linkInput"
                      class="form-control"
                      :value="formattedLink"
                      readonly
                    />
                    <div class="input-group-append">
                      <button class="btn btn-ok-whatsapp" @click="copyLink">
                        <i class="fa-regular fa-copy text-white"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row justify-content-end mt-5">
          <div class="col-3">
            <b-button
              class="btn btn-primary btn-block"
              @click="submitCampanha"
              variant="success"
            >
              {{
                this.modelo_campanha_id
                  ? "Salvar Alterações"
                  : "Cadastrar Campanha"
              }}
            </b-button>
          </div>
          <div class="col-2">
            <b-button
              class="btn btn-secondary btn-block"
              @click="cancelCampaignChanges"
              variant="danger"
            >
              Cancelar
            </b-button>
          </div>
        </div>
      </div>
    </scale-transition>
  </div>
</template>

<script>
import utils from "@teteu/utils";
import API_LINKS from "../api.links";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ModalHtmlPreview from "./components/ModalHtmlPreview";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import draggable from "vuedraggable";
import presetsArr from "./cadastro-componentes/presets";
import nestedDraggable from "./cadastro-componentes/NestedDraggable.vue";
import MultiSelect from "primevue/multiselect";
import Dropdown from "primevue/dropdown";
import UploadAdapter from "./upload_adapter";
import ToggleButton from "primevue/togglebutton";
import ModalConfirmacao from "./cadastro-componentes/ModalConfirmacao.vue";
import RadioButton from "primevue/radiobutton";
import { ScaleTransition } from "vue2-transitions";
import ModalPreviewCampanhaTable from "./components/ModalPreviewCampanhaTable";
import Card from "primevue/card/Card";
import ScrollPanel from "primevue/scrollpanel/ScrollPanel";
import InputText from "primevue/inputtext/InputText";
import PreviewWhatsapp from "@/view/pages/painel/automacao/template-whatsapp/PreviewWhatsapp.vue";
import TemplateWppVariaveis from "@/view/pages/painel/automacao/campanha/components/TemplateWppVariaveis.vue";
import Tooltip from "primevue/tooltip";
import moment from "moment";
import WebhookOptions from "@/view/components/WebhookOptions.vue";
import SelectButton from "primevue/selectbutton";

import Utils from "@/core/utils/Utils";
// import AutoComplete from "primevue/autocomplete";

export default {
  name: "ModalCadastroCampanha",
  components: {
    WebhookOptions,
    Dropdown,
    TemplateWppVariaveis,
    PreviewWhatsapp,
    ModalHtmlPreview,
    draggable,
    // AutoComplete,
    nestedDraggable,
    MultiSelect,
    ToggleButton,
    ModalConfirmacao,
    RadioButton,
    ScaleTransition,
    ModalPreviewCampanhaTable,
    Card,
    ScrollPanel,
    InputText,
    SelectButton
  },
  props: {
    modal_name: {
      type: String,
      required: true
    },
    modelo_campanha_id: {
      type: Number,
      required: false,
      default: null
    },
    context_functions: {
      type: Array,
      required: false,
      default: undefined
    }
  },
  computed: {
    formattedLink() {
      let baseUrl = this.computedLink?.split("?")[0];
      let params = this.computedLink?.split("?")[1];
      if (params) {
        let paramsArray = params.split("&");
        let abbreviatedParams = paramsArray.map((param) => {
          let [key, value] = param.split("=");
          let abbreviatedValue =
            value.length > 10 && key === "token"
              ? value.substring(0, 10) + "..."
              : value;
          return `${key}=${abbreviatedValue}`;
        });
        let shortenedParams = abbreviatedParams.join("&");
        return `${baseUrl}?${shortenedParams}`;
      }
      return baseUrl;
    },
    computedLink() {
      const webhook = this.full_object_payload.webhook;
      const token = webhook?.mdw_token || "";
      let updatedLink =
        process.env.VUE_APP_COMUNICANTE_API +
        API_LINKS.campanhaWebhook +
        "?token=" +
        token;
      if (this.phonePath) {
        updatedLink += `&destinatario=${encodeURIComponent(this.phonePath)}`;
      }
      if (this.dataPath) {
        updatedLink += `&data_path=${encodeURIComponent(this.dataPath)}`;
      }
      return updatedLink;
    },
    isBankAPI() {
      const bank = this.liscenced_databases.find((banco) => {
        return (
          banco.sistema_licenciada_id ==
          this.full_object_payload.sistema_licenciada_id
        );
      });
      if (bank) {
        return ["API_E", "API_G"].includes(bank.banco_id);
      }
      return false;
    },
    richTextAtivo() {
      return (
        this.texto.controle.selecionado === "E-Mail" ||
        this.texto.controle.selecionado === "PDF"
      );
    },
    email() {
      return this.texto.controle.selecionado === "E-Mail";
    },
    arquivoGerado() {
      return this.texto.controle.selecionado === "PDF";
    },
    verificarSeArquivoEVideo() {
      if (!this.file_preview) {
        return false;
      }
      const regex = /(?:\.([^.]+))?$/;
      const ext = regex.exec(this.file_preview)[1];
      const videoExtensions = ["mp4", "webm", "ogg"];
      return videoExtensions.includes(ext);
    },
    filtrarTemplate() {
      return this.templateOptions.filter((v) => {
        return (
          v.status === "APPROVED" && v.name.includes(this.searchTemplateValue)
        );
      });
    }
  },
  created() {
    this.presetsArr = presetsArr;
  },
  data() {
    return {
      phonePath: "",
      dataPath: "",
      lastfocusedWebhookTextAreaActive: "",
      initialChannels: [],
      variaveisSelecionadas: {},
      searchTemplateValue: "",
      can_show_filters: false,
      executouPreviewCompleto: false,
      current_uid_preview_count: false,
      current_uid_preview: null,
      tableHeaders: null,
      tableContent: null,
      campaing_to_edit_on_retrocompatibility: null,
      show: false,
      funcoesPosEnvio: [],
      campanhaWarningMessage: "",
      isAvancadoCollapsed: true,
      btnMostrarModalVisible: false,
      presetsArr: null,
      campanhaExecutada: false,
      totalDeMensagens: null,
      rich_text_ver_html: false,
      arrayPerucaFiltrosNativos: [],
      filtroNativoKeys: [],
      filtroNativoPeruca: [],
      filtrosNativos: [],
      variaveisFiltrosNativosPadrao: [],
      todosModelosComOpcoesDeFiltros: [],
      is_open: false,
      is_fullscreen: false,
      edit_mode: false,
      is_sistema_modelo_toggle_active: true,
      active_tab: 0,
      campaign_name_exibition: "",
      full_object_payload: {
        mdc_nome: "",
        sistema_licenciada_id: null,
        modelo_id: null,
        mdc_status: "A",
        mdc_permitir_msg_duplicada: false,
        canais: [],
        crons: [],
        callback_id: null,
        filtros: [],
        funcoes_pos_envio: [],
        mdc_limite_consulta: null,
        mdc_limite_maximo_horas: null,
        mdc_antecipar_feriado: false,
        cam_limite_maximo_horas: null
      },
      callback: {
        dropdown: {
          selecionado: {
            cal_nome: "Selecione"
          },
          opcoes: []
        },
        selecionados: []
      },
      canais_nao_ordenaveis: [4, 5, 9],
      modelosPossiveisVariable: [],
      licenciadaOpcoes: [],
      sistemaLicenciadaIdVmodel: null,
      tipoCampanha_opcoes: [],
      tipoCampanha: null,
      canalArquivo: {
        tipoArquivoGerado: "PDF",
        pdf: {
          format: "a4",
          displayHeaderFooter: false,
          printBackground: false,
          scale: 1,
          landscape: false,
          margin: {
            top: 5,
            bottom: 5,
            left: 5,
            right: 5
          }
        },
        imagem: {
          type: "png",
          quality: "100",
          encoding: "binary",
          omitBackground: true
        }
      },
      liscenced_options: [],
      liscenced_databases: [],
      all_models: [],
      model_type_options: [],
      possible_models_of_model_type: [],
      selected_model: null,
      selected_model_type: null,
      selected_license: null,
      camposPossiveisDoFiltro: [],
      canais: {
        selecionados: [],
        opcoes: []
      },
      channelsVisibleBI: true,
      channelsVisibleAmostra: true,
      editor: ClassicEditor,
      editor_config: {
        extraPlugins: [this.uploader]
      },
      email_remetente_nome: "",
      email_remetente_email: "",
      email_reply_to: "",
      email_titulo: "",
      canais_draggable_exibir: [],
      emailFileToAttachPreview: null,
      caminhoEmailAnexo: null,
      htmlParaInserirNoEditor: "",
      followSequenceObject: true,
      arrFollowSequenceObject: [
        { value: true, text: "Envia na sequência escolhida" },
        { value: false, text: "Envia sem distinção de sequência" }
      ],
      emailRichText: "",
      modeloCanalIdDoDash: null,
      dashUID: null,
      cacSenha: null,
      variaveisDeTextoNome: [],
      tarefasDoBI: [],
      tarefasDoBIOptions: [],
      toggleMsgDuplicada: false,
      toggleWebhookStatus: false,
      funcoesPosEnvioSelecionadas: [],
      limiteConsulta: 0,
      file_preview: null,
      imageToUpload: "",
      caminhoImageWhatsapp: "",
      tarefasBIVisible: false,
      textoWpp: null,
      texto: {
        controle: {
          selecionado: "",
          variaveis: ["nome", "idade", "nascimento"]
        },
        canaisTexto: {},
        imagem: null
      },
      listaDeFiltros: [],
      frequencia: [],
      templateOptions: [],
      canShowTemplate: true,
      templateSelecionadoWpp: {},
      updateDropdownTemplate: 1,
      debounceDropdown: null,
      lockTextoCampanha: true,
      stateValidationSelect: null,
      recursosConta: []
    };
  },
  watch: {
    selected_model: {
      handler() {
        this.totalDeMensagens = null;
        this.executouPreviewCompleto = false;
        this.current_uid_preview = null;
      },
      immediate: true
    },
    limiteConsulta: {
      handler(limiteConsulta) {
        if (limiteConsulta > 0) {
          this.full_object_payload.mdc_limite_consulta = limiteConsulta;
        } else {
          this.limiteConsulta = "";
          this.full_object_payload.mdc_limite_consulta = null;
        }
      },
      deep: true,
      imediate: true
    },
    canais: {
      handler(canais) {
        const dashAmostra = canais.selecionados.find((v) => v.id === 5);
        if (dashAmostra) {
          for (let i = 0; i < canais.selecionados.length; i++) {
            if (canais.selecionados[i].id !== 5) {
              this.gerenciarCanais(canais.selecionados[i]);
              this.channelsVisibleAmostra = false;
            }
          }
        } else {
          this.channelsVisibleAmostra = true;
        }
        this.$forceUpdate();
      },
      deep: true,
      imediate: true
    },
    tarefasBIVisible: {
      handler(isVisible) {
        if (!isVisible) {
          this.channelsVisibleBI = true;
          this.tarefasDoBISelected = "";
        }
      },
      deep: true,
      immediate: true
    },
    listaDeFiltros: {
      handler(val, oldVal) {
        if (
          this.$refs.btnExecutarPreviewModalCadastroCampanha ||
          this.$refs.btnExecutarPreviewCompleto
        ) {
          this.current_uid_preview = null;
          if (this.$refs.btnExecutarPreviewModalCadastroCampanha) {
            this.$refs.btnExecutarPreviewModalCadastroCampanha.innerText =
              "Solicitar Prévia";
          }
          this.tableHeaders = null;
          this.tableContent = null;
          this.btnMostrarModalVisible = false;
          this.executouPreviewCompleto = false;
          this.totalDeMensagens = null;
        }

        function recursiveRemoveLastMfiEOu(lista) {
          if (lista[lista.length - 1].listaDeFiltros.length === 0) {
            delete lista[lista.length - 1].mfi_e_ou;
            return;
          }
          recursiveRemoveLastMfiEOu(lista[lista.length - 1].listaDeFiltros);
        }

        function removeNull(lista) {
          const listaInitialLength = lista.length;
          for (let i = 0; i < listaInitialLength; i++) {
            if (lista[i] === null) {
              const removedElement = lista.splice(i, 1);
            }
          }
        }

        // Esse método tá bugado pakas.
        function removeLastMfiEOu(lista) {
          if (lista.length > 0) {
            if (lista[lista.length - 1].listaDeFiltros.length > 0) {
              if (
                lista[lista.length - 1].listaDeFiltros[
                  lista[lista.length - 1].listaDeFiltros.length - 1
                ].listaDeFiltros.length > 0
              ) {
                const listThree =
                  lista[lista.length - 1].listaDeFiltros[
                    lista[lista.length - 1].listaDeFiltros.length - 1
                  ].listaDeFiltros;
                if (listThree[listThree.length - 1].listaDeFiltros.length > 0) {
                  delete listThree[listThree.length - 1].listaDeFiltros[
                    listThree[listThree.length - 1].listaDeFiltros.length - 1
                  ].mfi_e_ou;
                } else {
                  delete lista[lista.length - 1].listaDeFiltros[
                    lista[lista.length - 1].listaDeFiltros.length - 1
                  ].listaDeFiltros[
                    lista[lista.length - 1].listaDeFiltros[
                      lista[lista.length - 1].listaDeFiltros.length - 1
                    ].listaDeFiltros.length - 1
                  ].mfi_e_ou;
                }
              } else {
                delete lista[lista.length - 1].listaDeFiltros[
                  lista[lista.length - 1].listaDeFiltros.length - 1
                ].mfi_e_ou;
              }
            } else {
              delete lista[lista.length - 1].mfi_e_ou;
            }
          }
        }

        function recursiveAddMfiToAllFilters(lista) {
          if (lista.length === 0) {
            return;
          }
          for (let i = 0; i < lista.length; i++) {
            if (lista[i].listaDeFiltros.length > 0) {
              recursiveAddMfiToAllFilters(lista[i].listaDeFiltros);
            } else if (!lista[i].hasOwnProperty("mfi_e_ou")) {
              lista[i].mfi_e_ou = "AND";
            }
          }
        }

        function addMfiToAllFilters(lista) {
          if (lista.length > 0) {
            lista.forEach((filtro0) => {
              if (
                !filtro0.hasOwnProperty("mfi_e_ou") &&
                filtro0.listaDeFiltros.length === 0
              ) {
                filtro0.mfi_e_ou = "AND";
              }
              if (filtro0.listaDeFiltros.length > 0) {
                filtro0.listaDeFiltros.forEach((filtro1) => {
                  if (
                    !filtro1.hasOwnProperty("mfi_e_ou") &&
                    filtro1.listaDeFiltros.length === 0
                  ) {
                    filtro1.mfi_e_ou = "AND";
                  }
                  if (filtro1.listaDeFiltros.length > 0) {
                    filtro1.listaDeFiltros.forEach((filtro2) => {
                      if (
                        !filtro2.hasOwnProperty("mfi_e_ou") &&
                        filtro2.listaDeFiltros.length === 0
                      ) {
                        filtro2.mfi_e_ou = "AND";
                      }
                      if (filtro2.listaDeFiltros.length > 0) {
                        filtro2.listaDeFiltros.forEach((filtro3) => {
                          if (!filtro3.hasOwnProperty("mfi_e_ou")) {
                            filtro3.mfi_e_ou = "AND";
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        }

        function changeHierarchyOfLastElement(lista, initialHierarchy = 0) {
          if (lista[lista.length - 1].listaDeFiltros.length !== 0) {
            changeHierarchyOfLastElement(
              lista[lista.length - 1].listaDeFiltros,
              lista[lista.length - 1].hierarquia
            );
          }
          lista[lista.length - 1].hierarquia = initialHierarchy;
        }

        addMfiToAllFilters(val);
        removeLastMfiEOu(val);

        this.$forceUpdate();
      },
      deep: true,
      immediate: true
    }
  },
  async mounted() {
    if (this.modelo_campanha_id) {
      this.edit_mode = true;
    }
  },
  methods: {
    async buscarRecursosConta(contaId) {
      const res = await Utils.getRecursosByConta(contaId);
      this.recursosConta = res.data.data;
    },
    verificarRecursoAtivo(recurso) {
      return this.recursosConta.some((r) => r.rec_codigo === recurso);
    },
    gerarNovoLinkWebhook() {
      const dataPath = this.dataPath;
      const phonePath = this.phonePath;
      this.dataPath = "*";
      this.phonePath = "";
      ApiService.post(
        API_LINKS.gerarNovoLinkWebhook + "/" + this.modelo_campanha_id,
        ""
      ).then((res) => {
        this.full_object_payload.webhook = res.data.webhook;
        this.toggleWebhookStatus = res.data.webhook.mdw_status === "A";
        this.dataPath = dataPath;
        this.phonePath = phonePath;
      });
    },
    copyLink() {
      navigator.clipboard
        .writeText(this.computedLink)
        .then(() => {
          Swal.fire({
            icon: "success",
            title: "Copiado!",
            text: "O link foi copiado com sucesso.",
            timer: 2000,
            showConfirmButton: false
          });
        })
        .catch(() => {
          Swal.fire({
            icon: "error",
            title: "Erro!",
            text: "Não foi possível copiar o link."
          });
        });
    },
    selecionarTemplate(template) {
      this.templateSelecionadoWpp = template;
      let canal = "Whatsapp";
      if (
        this.texto.controle.selecionado === canal &&
        this.validaTemplate(template)
      ) {
        let text = "";
        const variaveis = this.getVariaveisSelecionadas(template);

        template.components.forEach((component) => {
          const varComponent = Object.keys(variaveis).filter((k) => {
            return k.split("|")[1] === component.type;
          });
          if (component.text) {
            let texto = component.text;
            for (const varComponentKey in varComponent) {
              const varComponentValue = varComponent[varComponentKey];
              texto = texto.replace(
                varComponentValue.split("|")[2],
                this.variaveisDeTextoNome.includes(variaveis[varComponentValue])
                  ? "{" + variaveis[varComponentValue] + "}"
                  : variaveis[varComponentValue]
              );
            }
            text += texto + "\n";
          }
        });
        this.texto.canaisTexto["Whatsapp"] = text;
        this.textoWpp = text;
        this.$forceUpdate();
        let aux = "";
        let valores = Object.values(variaveis);
        valores = valores.map((v) => {
          return this.variaveisDeTextoNome.includes(v) ? "{" + v + "}" : v;
        });
        if (valores.length) {
          aux = "&&" + valores.join("&&");
        }
        this.textoWpp += `@template(${template.name}${aux})`;
        let canal = this.campaing_to_edit_on_retrocompatibility?.CANAIS.find(
          (i) => i.can_nome == "Whatsapp"
        );
        if (canal) {
          canal.cac_mensagem = this.textoWpp;
        }
        this.hideModalTemplates();
      }
    },
    variaveisTemplate(template, unique = false) {
      let variaveis = [];
      for (const component of template.components) {
        variaveis = variaveis.concat(
          this.variaveisComponent(component, unique)
        );
      }
      return variaveis;
    },
    variaveisFor(component) {
      if (component.type === "BUTTONS") {
        return {
          button_examples: component.buttons.filter((b) => b.example)
        };
      } else {
        return component.example;
      }
    },
    variaveisComponent(component, unique = false) {
      const variaveis = [];
      if (component && component.text) {
        const matches = component.text.match(/{{\d+}}/g) || [];
        for (const match of matches) {
          if (unique && !variaveis.includes(match)) {
            variaveis.push(match);
          }
        }
      } else if (component && component.type === "BUTTONS") {
        for (const b of component.buttons) {
          if (b.example) {
            const matches = b.url.match(/{{\d+}}/g) || [];
            for (const match of matches) {
              if (unique && !variaveis.includes(match)) {
                variaveis.push(match);
              }
            }
          }
        }
      }
      return variaveis;
    },
    getVariaveisSelecionadas(template) {
      if (template) {
        let quant = this.getVariaveisTemplate(template);
        let variaveis = [];
        for (const variaveisSelecionadasKey in this.variaveisSelecionadas) {
          if (variaveisSelecionadasKey.split("|")[0].includes(template.name)) {
            variaveis[variaveisSelecionadasKey] =
              this.variaveisSelecionadas[variaveisSelecionadasKey];
          }
        }

        let arrayBodyKey = Object.keys(variaveis).filter((k) =>
          k.split("|")[1].replace(/[0-9]/g, "").includes("BODY")
        );
        let arrayButtonsKey = Object.keys(variaveis).filter((k) =>
          k.split("|")[1].replace(/[0-9]/g, "").includes("BUTTONS")
        );

        arrayBodyKey = arrayBodyKey.slice(0, quant.BODY);
        arrayButtonsKey = arrayButtonsKey.slice(0, quant.BUTTONS);

        let aux = {};
        for (const key in variaveis) {
          if (arrayBodyKey.includes(key) || arrayButtonsKey.includes(key)) {
            aux[key] = variaveis[key];
          }
        }
        variaveis = aux;

        if (quant.BODY === 0) {
          for (const v in variaveis) {
            if (v.includes("BODY")) {
              delete variaveis[v];
            }
          }
        }

        if (quant.BUTTONS === 0) {
          for (const v in variaveis) {
            if (v.includes("BUTTONS")) {
              delete variaveis[v];
            }
          }
        }
        return variaveis;
      }
      return this.variaveisSelecionadas;
    },
    getVariaveisTemplate(template) {
      let componentes = template?.components;
      let body = componentes.find((c) => c.type === "BODY");
      let quant_body = body?.example ? body?.example.body_text[0].length : 0;

      let buttons = componentes.find((c) => c.type === "BUTTONS");
      let quant_buttons = buttons?.buttons
        ? buttons?.buttons.reduce((soma, item) => {
            return soma + (item.example ? item.example.length : 0);
          }, 0)
        : 0;
      return { BODY: quant_body, BUTTONS: quant_buttons };
    },
    validaTemplate(template) {
      if (
        Object.values(this.variaveisSelecionadas).some((v) => v.trim() === "")
      ) {
        return false;
      }
      const variaveis = Object.keys(this.variaveisSelecionadas).filter((v) => {
        return v.split("|")[0].includes(template.name);
      });
      return variaveis.length >= this.variaveisTemplate(template, true).length;
    },
    getFinalHourOptionsBasedOnStartHour(start_hour) {
      start_hour = parseInt(start_hour.substring(0, 2));
      const filtered_hour_options = [];

      for (let i = start_hour; i < 24; i++) {
        filtered_hour_options.push(`${i < 10 ? "0" + i : i} horas`);
      }
      return filtered_hour_options;
    },
    mostrarModalPreviewTable() {
      this.$modal.show("modal-preview-table");
    },
    nestedErrorToast(toast_object_body) {
      this.$toast.add(toast_object_body);
    },
    onEnd() {
      const whatsapp_position = this.canais_draggable_exibir.findIndex(
        (v) => v.id === 3
      );
      const sms_position = this.canais_draggable_exibir.findIndex(
        (v) => v.id === 1
      );
      const whatsapp_and_sms_active =
        this.canais_draggable_exibir[whatsapp_position].ativo &&
        this.canais_draggable_exibir[sms_position].ativo;
      const sms_before_whatsapp = whatsapp_position > sms_position;

      if (sms_before_whatsapp && whatsapp_and_sms_active) {
        this.canais_draggable_exibir.splice(
          whatsapp_position + 1,
          0,
          this.canais_draggable_exibir.splice(sms_position, 1)[0]
        );
        this.$toast.add({
          severity: "warn",
          summary: "Atenção",
          detail:
            "O SMS não pode ser priorizado com relação ao WhatsApp. O SMS foi movido para a posição seguinte ao WhatsApp.",
          life: 7000
        });
      }
    },
    closeModalRegisterCampaign() {
      this.show = false;
      this.is_open = false;
      this.$emit("toggleModalCadastrarCampanha");
    },
    registerNewCampaign() {
      this.initModalCampaignRegister();
      this.is_open = true;
      this.show = true;
    },
    openTransition() {
      if (this.show) {
        this.resetModalCampaignRegister();
        this.show = false;
        this.is_open = false;
      } else {
        this.initModalCampaignRegister();
        this.is_open = true;
        this.show = true;
      }
    },
    resetModalCampaignRegister() {
      this.is_open = false;
      this.is_fullscreen = false;
      this.canais.selecionados = [];
      this.canais.opcoes = [];
      this.canais_draggable_exibir = [];
      this.channelsVisibleBI = true;
      this.channelsVisibleAmostra = true;
      this.tarefasBIVisible = false;
      this.tarefasDoBISelected = "";
      this.tarefasDoBI = [];
      this.tarefasDoBIOptions = [];
    },
    async postEdicaoCampanha() {
      const resPostCampanha = ApiService.post(
        API_LINKS.campanha + "/" + this.modelo_campanha_id,
        {
          campanha: this.full_object_payload
        }
      );

      return resPostCampanha;
    },
    async postCadastroCampanha() {
      const resPostCampanha = ApiService.post(API_LINKS.campanha, {
        campanha: this.full_object_payload
      });

      return resPostCampanha;
    },
    enviarEdicao() {
      this.full_object_payload.crons = [];
      this.transformarFrequenciasEmCrons();

      this.frequencia.forEach((v) => {
        if (v.mdc_cron_txt !== "") {
          this.full_object_payload.crons.push({
            mdc_cron_txt: v.mdc_cron_txt,
            mdc_cron: v.mdc_cron,
            mdc_ativo: v.mdc_ativo,
            mdc_data_inicio: v.mdc_data_inicio,
            mdc_data_fim: v.mdc_data_fim
          });
        }
      });

      if (this.full_object_payload.mdc_limite_consulta === "") {
        this.full_object_payload.mdc_limite_consulta = null;
      }

      if (this.variaveisFiltrosNativosPadrao) {
        this.full_object_payload.mdc_variaveis_filtro_nativo = JSON.stringify(
          this.variaveisFiltrosNativosPadrao
        );
      } else {
        this.full_object_payload.mdc_variaveis_filtro_nativo = null;
      }

      this.pegarTextosDosCanaisEPassarParaObjetoDeEnviar();
      this.verificarSeOsCanaisTemOrdem();

      if (this.imageToUpload.length > 0) {
        const temWhatsapp = this.full_object_payload.canais.some(
          (v) => v.canal_id === 3
        );

        if (temWhatsapp) {
          this.full_object_payload.canais.find(
            (v) => v.canal_id === 3
          ).cac_anexo = this.imageToUpload;

          this.full_object_payload.canais.find(
            (v) => v.canal_id === 3
          ).cac_anexo_caminho = this.caminhoImageWhatsapp;
        }
      } else {
        if (this.full_object_payload.canais.find((v) => v.canal_id === 3)) {
          this.full_object_payload.canais.find(
            (v) => v.canal_id === 3
          ).cac_anexo = null;
        }
      }

      this.verificarCanaisExcluidos();

      // Atualiza o status do webhook
      if (this.full_object_payload.webhook) {
        this.full_object_payload.webhook.mdw_status = this.toggleWebhookStatus
          ? "A"
          : "I";
      }

      this.full_object_payload.mdc_permitir_msg_duplicada = this
        .toggleMsgDuplicada
        ? "S"
        : "N";

      this.full_object_payload.filtros = this.listaDeFiltros;

      this.full_object_payload.funcoes_pos_envio =
        this.funcoesPosEnvioSelecionadas.map((v) => {
          return {
            funcao_pos_envio_id: v.code
          };
        });

      this.postEdicaoCampanha().then((res) => {
        Swal.fire({
          icon: "success",
          title: "Campanha salva com sucesso!",
          showConfirmButton: false,
          timer: 1000
        });
        this.openTransition();
        this.fecharModalConfirmacao();
        this.$emit("updateCampanhas");
      });
    },
    enviarCadastro() {
      this.full_object_payload.crons = [];
      this.transformarFrequenciasEmCrons();

      this.frequencia.forEach((v) => {
        if (v.mdc_cron_txt !== "") {
          this.full_object_payload.crons.push({
            mdc_cron_txt: v.mdc_cron_txt,
            mdc_cron: v.mdc_cron,
            mdc_ativo: v.mdc_ativo,
            mdc_data_inicio: v.mdc_data_inicio,
            mdc_data_fim: v.mdc_data_fim
          });
        }
      });

      if (this.full_object_payload.mdc_limite_consulta === "") {
        this.full_object_payload.mdc_limite_consulta = null;
      }

      if (this.variaveisFiltrosNativosPadrao) {
        this.full_object_payload.mdc_variaveis_filtro_nativo = JSON.stringify(
          this.variaveisFiltrosNativosPadrao
        );
      } else {
        this.full_object_payload.mdc_variaveis_filtro_nativo = null;
      }

      this.pegarTextosDosCanaisEPassarParaObjetoDeEnviar();
      this.verificarSeOsCanaisTemOrdem();

      if (this.imageToUpload.length > 0) {
        const temWhatsapp = this.full_object_payload.canais.some(
          (v) => v.canal_id === 3
        );

        if (temWhatsapp) {
          this.full_object_payload.canais.find(
            (v) => v.canal_id === 3
          ).cac_anexo = this.imageToUpload;

          this.full_object_payload.canais.find(
            (v) => v.canal_id === 3
          ).cac_anexo_caminho = this.caminhoImageWhatsapp;
        }
      } else {
        if (this.full_object_payload.canais.find((v) => v.canal_id === 3)) {
          this.full_object_payload.canais.find(
            (v) => v.canal_id === 3
          ).cac_anexo = null;
        }
      }

      this.full_object_payload.canais_excluir = [];

      this.full_object_payload.mdc_permitir_msg_duplicada = this
        .toggleMsgDuplicada
        ? "S"
        : "N";

      this.full_object_payload.filtros = this.listaDeFiltros;

      this.full_object_payload.funcoes_pos_envio =
        this.funcoesPosEnvioSelecionadas.map((v) => {
          return {
            funcao_pos_envio_id: v.code
          };
        });

      this.postCadastroCampanha().then((res) => {
        this.$toast.add({
          severity: "success",
          summary: "Sucesso",
          detail: `Campanha criada com sucesso!`,
          life: 3000
        });
        window.confetti({
          particleCount: utils.random(200, 400),
          spread: 70,
          origin: { y: 1 },
          duration: 7
        });
        this.openTransition();
        this.fecharModalConfirmacao();
        this.$emit("updateCampanhas");
      });
    },
    pegarTextosDosCanaisEPassarParaObjetoDeEnviar() {
      this.full_object_payload.canais = this.canais.selecionados.map((v, i) => {
        if (v.id === 2) {
          return {
            cac_reply_to: this.email_reply_to,
            canal_id: v.id,
            cac_mensagem: this.texto.canaisTexto[v.nome],
            modelo_canal_id:
              this.canais.selecionados.find((c) => c.id === v.id)
                .modelo_canal_id || null,
            cac_anexo: v.cac_anexo || null,
            cac_anexo_caminho: null,
            cac_anexo_tamanho: null,
            cac_remetente: this.email_remetente_email,
            cac_remetente_nome: this.email_remetente_nome,
            cac_titulo: this.email_titulo,
            cac_ativo: true
          };
        } else if (v.id === 4) {
          return {
            canal_id: v.id,
            modelo_tarefa_id: this.tarefasDoBISelected,
            cac_anexo: v.cac_anexo || null,
            modelo_canal_id:
              this.canais.selecionados.find((c) => c.id === v.id)
                .modelo_canal_id || null,
            cac_anexo_caminho: null,
            cac_remetente: null,
            cac_remetente_nome: null,
            cac_titulo: null,
            cac_ativo: true
          };
        } else if (v.id === 9) {
          return {
            cac_reply_to: null,
            canal_id: v.id,
            cac_mensagem: this.texto.canaisTexto[v.nome],
            modelo_canal_id:
              this.canais.selecionados.find((c) => c.id === v.id)
                .modelo_canal_id || null,
            cac_anexo_caminho: null,
            cac_anexo: JSON.stringify({
              tipo_arquivo: this.canalArquivo.tipoArquivoGerado,
              configuracoes: { ...this.canalArquivo }
            }),
            cac_remetente: null,
            cac_remetente_nome: null,
            cac_titulo: null,
            cac_ativo: true
          };
        } else {
          return {
            canal_id: v.id,
            cac_mensagem:
              v.nome === "Whatsapp" &&
              this.textoWpp &&
              this.templateOptions.length // Verifica se o canal é Whatsapp.
                ? this.textoWpp
                : this.texto.canaisTexto[v.nome],
            modelo_canal_id:
              this.canais.selecionados.find((c) => c.id === v.id)
                .modelo_canal_id || null,
            cac_anexo: v.cac_anexo || null,
            cac_anexo_caminho: null,
            cac_remetente: null,
            cac_remetente_nome: null,
            cac_titulo: null,
            cac_ativo: true
          };
        }
      });
    },
    submitCampanha(event) {
      this.pegarTextosDosCanaisEPassarParaObjetoDeEnviar();
      // Verifica se a campanha tem o canal de Whatsapp selecionado.
      const validarTemplate =
        Object.values(this.canais.selecionados).find(
          (v) => v.nome.toUpperCase() === "WHATSAPP"
        ) || null;

      // Caso o canal BI esteja selecionado, não permite tarefa vazia.
      const biSelecionado = this.canais.selecionados.some((i) => i.id == 4);
      if (biSelecionado && !this.stateValidationSelect) {
        this.stateValidationSelect = false;
        return;
      }
      const templateEncontrado = this.encontrarTemplateByTexto(this.textoWpp);
      // Verifica se o canal é Whatsapp, se tem templates cadastrados e se o template foi selecionado.
      if (validarTemplate && this.templateOptions.length) {
        // Verifica se o texto do Whatsapp está vazio ou se não contém o template.
        if (
          this.textoWpp == null ||
          this.textoWpp.trim() === "" ||
          !this.textoWpp.includes("@template(")
        ) {
          Swal.fire({
            icon: "error",
            title: "Não é possível salvar campanha sem template.",
            text:
              "A conta selecionada para a execução da campanha possui Whatsapp Oficial, portanto é necessário selecionar um template." +
              "\nVocê pode trocar a conta que executa a campanha na aba Avançados > Subcontas.",
            showConfirmButton: true
          });
          return;
        }
        if (!templateEncontrado) {
          // Verifica se o template selecionado existe.
          Swal.fire({
            icon: "error",
            title: "Template não encontrado.",
            text: "O template selecionado não foi encontrado na lista de templates disponíveis, talvez tenha sido excluído. Por favor, selecione um template válido.",
            showConfirmButton: true
          });
          return;
        }
      }
      // Verificar se a campanha tem template selecionado que precisa de arquivo.
      if (
        validarTemplate &&
        this.templateOptions.length &&
        this.templateSelecionadoPrecisaArquivo() &&
        this.verificaSeAquivoNaoEnviado()
      ) {
        Swal.fire({
          icon: "error",
          title: "Não é possível salvar campanha sem arquivo.",
          text: "O template selecionado precisa de um arquivo anexado para ser enviado. Por favor, anexe um arquivo.",
          showConfirmButton: true
        });
        return;
      }

      if (this.full_object_payload.canais.length === 0) {
        this.$toast.add({
          severity: "error",
          summary: "Erro",
          detail: `Não é possível salvar campanha sem canais.`,
          life: 5000
        });
        return;
      }
      this.campanhaWarningMessage = "";
      if (this.listaDeFiltros.length === 0) {
        this.campanhaWarningMessage = `⚠️ Campanha sem filtros!\n`;
      }
      if (this.full_object_payload.tipo_campanha_id === null) {
        this.campanhaWarningMessage = `⚠️ Campanha sem tipo selecionado!\n`;
      }
      if (
        this.callback.dropdown.opcoes.length > 0 &&
        this.full_object_payload.callback_id === null
      ) {
        this.campanhaWarningMessage += ` ⚠️ Campanha sem callback selecionada\n`;
      }
      if (this.full_object_payload.callback_id == 5 && !biSelecionado) {
        this.campanhaWarningMessage += ` ⚠️ Campanha com callback Resposta NPS configurada mas sem tarefa do BI.`;
      }

      if (this.campanhaWarningMessage.length > 0) {
        this.showModalConfirmacao();
      } else {
        if (this.modelo_campanha_id !== null) {
          this.enviarEdicao();
        } else {
          this.enviarCadastro();
        }
      }
    },
    insertPresetFilter(presetFilter) {
      const copyOfPreset = JSON.parse(JSON.stringify(presetFilter));
      for (let i = 0; i < presetFilter.length; i++) {
        this.listaDeFiltros.push(copyOfPreset[i]);
      }
    },
    submitCampanhaFromModal() {
      if (this.modelo_campanha_id) {
        this.enviarEdicao();
      } else {
        this.enviarCadastro();
      }
    },
    fecharModalConfirmacao() {
      this.$modal.hide("modal-confirmacao");
    },
    showModalConfirmacao() {
      this.$modal.show("modal-confirmacao");
    },
    adicionarNovoFiltro() {
      this.listaDeFiltros.push({
        hierarquia: 0,
        filtro_id: 1,
        mfi_valor: "",
        ope_titulo: "",
        mfi_e_ou: "AND",
        mfi_valor_2: null,
        operador_id: 12,
        listaDeFiltros: [],
        contexto_id: null
      });
      this.listaDeFiltros.forEach((v, i) => {
        if (!(Array.isArray(v.listaDeFiltros) && v.listaDeFiltros.length)) {
          if (!v.mfi_e_ou) v.mfi_e_ou = "AND";
        }
        delete this.listaDeFiltros[this.listaDeFiltros.length - 1].mfi_e_ou;
      });
    },
    trocaAbasTexto(canal) {
      this.texto.controle.selecionado = canal.nome;
    },
    async defaultInitializer() {
      await this.obterOpcoesLicenciada();
      await this.obterTipoCampanha();
      ApiService.get(API_LINKS.campanha + "/operador", "", false).then(
        (res) => {
          this.opreadoresFiltro = res.data.operadores.map((v) => {
            return {
              ope_titulo: v.ope_titulo,
              ope_sinal: v.ope_sinal,
              operador_id: v.operador_id
            };
          });
        }
      );

      ApiService.get(API_LINKS.campanha + "/canal-envio", "", false).then(
        (res) => {
          this.canais.opcoes = res.data.canais
            .filter((v) => {
              return ![6, 7, 8].includes(v.canal_id);
            })
            .map((v) => {
              return {
                nome: v.can_nome,
                id: v.canal_id,
                ativo: false
              };
            });

          this.canais_draggable_exibir = this.canais.opcoes.filter(
            (canalFiltrado) =>
              !this.canais_nao_ordenaveis.includes(canalFiltrado.id)
          );
        }
      );

      const subcontas = await ApiService.get(API_LINKS.contasAtivas, "", false);
      this.subcontas = subcontas.data.subContas.map((v) => {
        return {
          text:
            v.con_nome_identificacao == null
              ? v.con_login
              : v.con_nome_identificacao,
          value: v.conta_id
        };
      });
      const contaPrincipalId = subcontas.data?.contaPrincipal?.conta_id;
      this.subcontas.unshift({
        value: contaPrincipalId,
        text: "Conta Principal"
      });
    },
    handleFocusEvent(id) {
      this.lastfocusedWebhookTextAreaActive = id;
    },
    showModalImagem() {
      this.$modal.show("modal-imagem");
    },
    hideModalImagem() {
      this.$modal.hide("modal-imagem");
    },
    showModalTemplates() {
      this.variaveisSelecionadas = {};
      this.sortTemplateOptions();
      this.updateVariablesFromTemplate();
      this.$modal.show("modal-templates");
    },
    hideModalTemplates() {
      this.$modal.hide("modal-templates");
    },
    getWhatsappTemplates() {
      const contaId = this.full_object_payload.mdc_preferencial_conta_id;
      this.buscarRecursosConta(contaId).then(() => {
        if (!this.verificarRecursoAtivo("WHATSAPP_OFICIAL")) {
          this.templateOptions = [];
          this.templateSelecionadoWpp = {};
          return;
        }
        let url = "";
        if (contaId) {
          url = `${API_LINKS.whatsappTemplates}?conta_id=${contaId}`;
        } else {
          url = API_LINKS.whatsappTemplates;
        }
        ApiService.get(url, "", false).then((res) => {
          this.templateOptions = res.data.data[0].templates || [];
          this.preencherTemplateSelecionadoPorTexto(this.textoWpp);
        });
      });
      this.textoWpp = null;
    },
    sortTemplateOptions() {
      // Ordena para o template selecionado seja o primeiro
      this.templateOptions = this.templateOptions.sort((a, b) => {
        return this.isTemplateWppSelected(b) - this.isTemplateWppSelected(a);
      });
    },
    updateVariablesFromTemplate() {
      let canal = this.campaing_to_edit_on_retrocompatibility?.CANAIS.find(
        (i) => i.can_nome == "Whatsapp"
      );
      if (canal) {
        const regex = /@template\((.*?)\)$/;
        const match = canal.cac_mensagem?.match(regex);

        if (match) {
          const valuesString = match[1];
          const valuesArray = valuesString
            .split("&&")
            .map((value) => value.trim());

          let variables = valuesArray;
          let nameTemplate = variables[0]; // Pega o nome do template
          variables.shift(); // Remove o nome do template

          const templateAtual = this.templateOptions.find(
            (v) => v.name === nameTemplate
          );

          let variaveisQuant = {};
          templateAtual?.components.forEach((comp) => {
            if (comp.type === "BODY") {
              variaveisQuant["BODY"] = comp.example
                ? comp.example.body_text[0].length
                : 0;
            }
            if (comp.type === "BUTTONS") {
              variaveisQuant["BUTTONS"] = {};
              comp.buttons.forEach((button, key) => {
                variaveisQuant["BUTTONS"][key] = button.example
                  ? button.example.length
                  : 0;
              });
              variaveisQuant["BUTTONS"] = Object.values(
                variaveisQuant["BUTTONS"]
              ).reduce((acc, cur) => acc + cur, 0);
            }
          });

          let chave = 0;
          for (let i = 0; i < variables.length; ++i) {
            if (i > variaveisQuant["BODY"] - 1) {
              chave = i;
              break;
            }
            this.variaveisSelecionadas[
              `${nameTemplate}|BODY|{{${parseInt(i) + 1}}}`
            ] = variables[i]?.replace("{", "").replace("}", "");
          }

          let indexBtn = 0;
          if (variaveisQuant.BUTTONS) {
            for (let i = chave; i < variables.length; i++) {
              this.variaveisSelecionadas[
                `${nameTemplate}|BUTTONS${indexBtn}|{{1}}`
              ] = variables[i]?.replace("{", "").replace("}", "");
              indexBtn++;
            }
          }
        }
      }
    },
    forceUpdateDropdownTemplates(value) {
      const time = this.variaveisDeTextoNome.includes(value) ? 0 : 1500;
      clearTimeout(this.debounceDropdown);
      this.debounceDropdown = setTimeout(() => {
        this.updateDropdownTemplate++;
      }, time);
    },
    ativarImagemInput() {
      let fileInput = this.$refs.fileInput;
      fileInput.click();
    },
    selectImgFile() {
      let fileInput = this.$refs.fileInput;
      let imgFile = fileInput.files;

      if (imgFile && imgFile[0]) {
        let reader = new FileReader();
        reader.onload = (e) => {
          this.file_preview = e.target.result;
        };
        reader.readAsDataURL(imgFile[0]);

        this.$emit("fileInput", imgFile[0]);
      }
    },
    uploadImgFile() {
      setTimeout(() => {
        ApiService.post(API_LINKS.contasAtivas + "/upload-arquivo", {
          arquivo: this.file_preview,
          caminho: "modelo-campanha/"
        }).then((res) => {
          this.imageToUpload = res.data.url;
          this.caminhoImageWhatsapp = res.data.caminho;
        });
      }, 100);
    },
    async recuperarDadosParaEdicaoDeCampanha(modelo_campanha_id) {
      const campanha_res = await this.getCampaign(modelo_campanha_id);
      this.campaing_to_edit_on_retrocompatibility = campanha_res.data.campanha;
      const campanha = campanha_res.data.campanha;
      this.dataPath = "*";
      this.full_object_payload.webhook = campanha.webhook;
      this.full_object_payload.modelo_campanha_id = modelo_campanha_id;
      this.full_object_payload.mdc_nome = campanha.mdc_nome;
      this.full_object_payload.mdc_limite_maximo_horas =
        campanha.mdc_limite_maximo_horas;
      this.full_object_payload.sistema_licenciada_id =
        campanha.sistema_licenciada_id;
      this.selected_license = campanha.sistema_licenciada_id;
      this.full_object_payload.tipo_campanha_id = campanha.tipo_campanha_id;
      this.tipoCampanha = campanha.tipo_campanha_id;
      this.full_object_payload.modelo_id = campanha.modelo_id;
      this.full_object_payload.mdc_antecipar_feriado =
        campanha.mdc_antecipar_feriado;
      this.full_object_payload.cam_limite_maximo_horas =
        campanha.cam_limite_maximo_horas;

      this.toggleWebhookStatus = campanha.webhook?.mdw_status === "A";
      if (campanha.mdc_permitir_msg_duplicada === "S") {
        this.toggleMsgDuplicada = true;
      } else {
        this.toggleMsgDuplicada = false;
      }
      if (campanha.mdc_preferencial_conta_id !== null) {
        this.full_object_payload.mdc_preferencial_conta_id =
          campanha.mdc_preferencial_conta_id;
      }
      this.getWhatsappTemplates();

      this.funcoesPosEnvioSelecionadas = campanha.FUNCOES_POS_ENVIO.map((v) => {
        return {
          code: v.funcao_pos_envio_id,
          name: v.fpe_descricao
        };
      });

      // ToDo: código redundante, refatorar
      // Número máximo de consultas
      if (campanha.mdc_limite_consulta === null) {
        this.limiteConsulta = null;
      } else {
        this.limiteConsulta = campanha.mdc_limite_consulta;
      }

      campanha.CANAIS.filter((v) => {
        return ![6, 7, 8].includes(v.canal_id);
      }).forEach((v) => {
        const canal_das_options_draggable = this.canais.opcoes.find(
          (c) => c.id === v.canal_id && v.cac_ativo
        );

        if (canal_das_options_draggable) {
          canal_das_options_draggable.ativo = true;
        } else {
          return;
        }
        this.gerenciarCanais({
          nome: v.can_nome,
          id: v.canal_id,
          ativo: false,
          modelo_canal_id: v.modelo_canal_id,
          cac_anexo: v.cac_anexo
        });

        if (v.canal_id === 7) {
          this.emailRichText;
        } else if (v.canal_id === 4) {
          this.tarefasDoBISelected = v.modelo_tarefa_id;
          this.verificarTitCargaDireta();
        } else if (v.canal_id === 5) {
          // this.cacLogin = v.cac_url;
          this.cacSenha = v.cac_senha;
          this.modeloCanalIdDoDash = v.modelo_canal_id;
        } else if (v.canal_id === 9) {
          if (v.cac_anexo) {
            const aux = JSON.parse(v.cac_anexo);
            if (aux?.configuracoes) {
              this.canalArquivo = aux.configuracoes;
            }
          }
        } else if (v.canal_id === 2) {
          // channel email
          this.email_remetente_nome = v.cac_remetente_nome;
          this.email_remetente_email = v.cac_remetente;
          this.email_titulo = v.cac_titulo;
          this.email_reply_to = v.cac_reply_to;
        }
      });

      this.canais.opcoes.forEach((v) => {
        const canal = campanha.CANAIS.find((c) => c.canal_id === v.id);
        if (canal) {
          v.cac_ordem = canal.cac_ordem;
        } else {
          v.cac_ordem = 0;
        }
        if (v.cac_ordem != 0) {
          this.followSequenceObject = true;
        } else {
          this.followSequenceObject = false;
        }
      });

      this.canais_draggable_exibir = this.canais.opcoes
        .filter(
          (canalFiltrado) =>
            !this.canais_nao_ordenaveis.includes(canalFiltrado.id)
        )
        .sort((a, b) => Number(a.cac_ordem) - Number(b.cac_ordem));

      const canalWhatsapp = campanha.CANAIS.find((v) => v.canal_id === 3);

      if (canalWhatsapp) {
        if (canalWhatsapp.cac_anexo) {
          this.file_preview = canalWhatsapp.cac_anexo;
          this.imageToUpload = canalWhatsapp.cac_anexo;
          this.caminhoImageWhatsapp = canalWhatsapp.cac_anexo_caminho;
        }
      }

      const textosObject = {};
      campanha.CANAIS.forEach((v) => {
        if (v.can_nome === "Whatsapp") {
          this.textoWpp = v.cac_mensagem;
          textosObject[v.can_nome] = v.cac_mensagem?.replace(
            /@template\(\S+\)/g,
            ""
          );
        } else {
          textosObject[v.can_nome] = v.cac_mensagem;
        }
      });
      this.texto.canaisTexto = textosObject;

      this.initialChannels = campanha.CANAIS.map((v) => v.modelo_canal_id);

      this.listaDeFiltros = campanha.FILTROS;
      this.can_show_filters = true;
      const bancoId = this.liscenced_databases.find(
        (v) => campanha.sistema_licenciada_id == v.sistema_licenciada_id
      ).sistema_banco_id;
      this.dataPath = "";

      ApiService.get(API_LINKS.campanhaModelo + "/" + bancoId, "", false).then(
        (res) => {
          this.possible_models_of_model_type = res.data.modelos.map((v) => {
            return {
              value: v.modelo_id,
              text: v.md_descricao,
              mt_tipo: v.mt_tipo
            };
          });

          this.all_models = res.data.modelos;
          const arrDeOpcoesDeModelosTotais = res.data.modelos.map((v) => {
            return {
              text: v.mt_titulo,
              value: v.mt_tipo
            };
          });

          this.model_type_options = [
            ...new Map(
              arrDeOpcoesDeModelosTotais.map((item) => [item.value, item])
            ).values()
          ];

          const dataModeloCorreto = res.data.modelos.find(
            (v) => v.modelo_id === campanha.modelo_id
          );
          this.selected_model_type = dataModeloCorreto.mt_tipo;
          this.selected_model = dataModeloCorreto.modelo_id;

          this.modeloPossiveis();
          this.selecionarModelo();

          if (campanha.CALLBACK !== null) {
            this.full_object_payload.callback_id =
              campanha.CALLBACK.callback_id;
          }
          campanha.CRONS.forEach((v) => {
            this.createNewFrequencyWithParameters(
              v.mdc_cron,
              v.mdc_ativo,
              v.mdc_cron_txt,
              v.mdc_data_inicio,
              v.mdc_data_fim
            );
          });
          if (campanha.CRONS.length === 0) {
            this.createNewFrequency();
          }

          setTimeout(() => {
            if (campanha.mdc_variaveis_filtro_nativo) {
              this.variaveisFiltrosNativosPadrao = JSON.parse(
                campanha.mdc_variaveis_filtro_nativo
              );
            }
          }, 500);
        }
      );
    },
    saveCampaign() {
      Swal.fire({
        icon: "success",
        title: "Campanha salva com sucesso!",
        showConfirmButton: false,
        timer: 1000
      });
    },
    cancelCampaignChanges() {
      Swal.fire({
        title: `Deseja fechar ${
          this.modelo_campanha_id ? "edição" : "criação"
        }?`,
        text: "Você perderá as mudanças não salvas e não poderá reverter isso!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim!"
      }).then((result) => {
        if (result.value) {
          this.closeModalRegisterCampaign();
        }
      });
    },
    async initModalCampaignRegister() {
      // this is the door
      this.can_show_filters = false;
      await new Promise((resolve) => setTimeout(resolve, 1000));
      const toggleModeloSistemaCollapse = document.getElementById(
        "toggleModeloSistemaCollapse"
      );
      const class_list_has_collapsed =
        toggleModeloSistemaCollapse.classList.contains("collapsed");

      if (!this.modelo_campanha_id) {
        toggleModeloSistemaCollapse.click();
      } else if (!class_list_has_collapsed && this.modelo_campanha_id) {
        toggleModeloSistemaCollapse.click();
      }

      await this.defaultInitializer();
      if (this.modelo_campanha_id) {
        setTimeout(() => {
          this.can_show_filters = true;
        }, 5000);
        this.recuperarDadosParaEdicaoDeCampanha(this.modelo_campanha_id);
      } else {
        this.can_show_filters = true;
        this.createNewFrequency();
        this.getWhatsappTemplates(); // Obtém a lista de templates do whatsapp.
      }
    },
    async getCampaign(modelo_campanha_id) {
      const response = await ApiService.get(
        API_LINKS.campanha + "/" + modelo_campanha_id,
        "",
        false
      );
      return response;
    },
    async getCampanhaFiltros() {
      const filtros = ApiService.get(
        API_LINKS.campanhaFiltro + "/" + this.full_object_payload.modelo_id,
        "",
        false
      );
      return filtros;
    },
    async selecionarModelo(modeloSelecionado) {
      if (modeloSelecionado) {
        this.selected_model = modeloSelecionado;
        this.possible_models_of_model_type.map((v) => {
          if (v.value === modeloSelecionado) {
            this.selected_model_type = v.mt_tipo;
          }
        });
      }

      this.callback.dropdown.selecionado = {
        cal_nome: "Selecione"
      };

      this.full_object_payload.modelo_id = this.selected_model;
      const modeloComFiltrosDeModelo = this.all_models.find(
        (v) => v.modelo_id === this.selected_model && v.md_filtro_nativo
      );
      if (modeloComFiltrosDeModelo) {
        this.variaveisFiltrosNativosPadrao = JSON.parse(
          modeloComFiltrosDeModelo.md_variaveis_filtro_nativo_padrao
        );
        this.filtroNativoKeys = Object.keys(
          this.variaveisFiltrosNativosPadrao["VARIAVEIS"]
        );

        this.filtrosNativos = JSON.parse(
          modeloComFiltrosDeModelo.md_filtro_nativo
        ).toString();
        this.filtroNativoPeruca = JSON.parse(
          modeloComFiltrosDeModelo.md_filtro_nativo
        );
        this.filtroNativoPeruca = this.filtroNativoPeruca.map((query) => {
          const provisoryArrayOfSubstring = [];
          let provisoryStringOriginal = query;
          let lastI = 0;
          query.replace(/[A-Z_]+/g, (v, i) => {
            if (this.filtroNativoKeys.indexOf(v) !== -1) {
              provisoryArrayOfSubstring.push({
                type: "s",
                campo: provisoryStringOriginal.substring(lastI, i)
              });
              provisoryArrayOfSubstring.push({ type: "i", campo: v });
              lastI = i + v.length;
            } else if (v === v.toUpperCase() && v !== "_") {
              provisoryArrayOfSubstring.push({
                type: "s",
                campo: provisoryStringOriginal.substring(lastI, i)
              });
              provisoryArrayOfSubstring.push({
                type: "s",
                campo:
                  "<strong style='margin: 0 6px; font-size: 12px; color: red;'>" +
                  v +
                  "</strong>"
              });
              lastI = i + v.length;
            }
            return v;
          });
          provisoryArrayOfSubstring.push({
            type: "s",
            campo:
              "<span>" + provisoryStringOriginal.substring(lastI) + "</span>"
          });
          return provisoryArrayOfSubstring;
        });
        this.arrayPerucaFiltrosNativos = [];
        const stringOriginal = this.filtrosNativos;
        let lastI = 0;

        this.filtrosNativos = this.filtrosNativos.replace(
          /[A-Z_]+/g,
          (v, i) => {
            if (v === "_" || v.toLowerCase() === v) {
              return v;
            }

            let stringToSendInTheArray = "";
            if (this.filtroNativoKeys.indexOf(v) !== -1) {
              this.arrayPerucaFiltrosNativos.push({
                type: "s",
                campo: this.filtrosNativos.substring(lastI, i)
              });
              this.arrayPerucaFiltrosNativos.push({ type: "i", campo: v });
            } else {
              if (v === "AND" || v === "OR") {
                stringToSendInTheArray += this.filtrosNativos.substring(
                  lastI,
                  i
                );
                stringToSendInTheArray +=
                  ' <strong style="color: red; margin: 0 4px">';
                stringToSendInTheArray += this.filtrosNativos.substring(
                  i,
                  i + v.length
                );
                stringToSendInTheArray += "</strong> ";
              } else {
                stringToSendInTheArray += this.filtrosNativos.substring(
                  lastI,
                  i
                );
                stringToSendInTheArray +=
                  ' <strong style="color: #000; margin: 0 4px">';
                stringToSendInTheArray += this.filtrosNativos.substring(
                  i,
                  i + v.length
                );
                stringToSendInTheArray += "</strong> ";
              }
              this.arrayPerucaFiltrosNativos.push({
                type: "s",
                campo: stringToSendInTheArray
              });
            }
            lastI = i + v.length;
            return v;
          }
        );
        this.arrayPerucaFiltrosNativos.push({
          type: "s",
          campo: stringOriginal.substring(lastI)
        });
      } else {
        this.variaveisFiltrosNativosPadrao = null;
        this.filtrosNativos = null;
      }

      this.getCampanhaCallbacks().then((res) => {
        this.callback.dropdown.opcoes = res.data.callbacks.map((callback) => {
          return {
            callback_id: callback.callback_id,
            callback_modelo_id: callback.callback_modelo_id,
            modelo_id: callback.modelo_id,
            cal_limite_maximo_horas: callback.cal_limite_maximo_horas,
            text: callback.cal_nome,
            value: callback.callback_id
          };
        });
        this.callback.dropdown.opcoes.unshift({
          text: "",
          value: null
        });
      });

      await this.getCampanhaFiltros().then((res) => {
        this.camposPossiveisDoFiltro = res.data.filtros.map((filtro) => {
          return {
            fil_campo: filtro.fil_campo,
            fil_titulo: filtro.fil_titulo,
            filtro_id: filtro.filtro_id,
            ope_titulo: filtro.ope_titulo,
            fil_tipo_dado: filtro.fil_tipo_dado,
            fil_tipo: filtro.fil_tipo,
            fil_mascara: filtro.fil_mascara
          };
        });
        this.variaveisDeTextoObjeto = res.data.variaveis;
        this.variaveisDeTextoNome = Object.keys(this.variaveisDeTextoObjeto);
      });

      this.getTarefasCarga().then((res) => {
        this.tarefasDoBI = res.data.tarefas;

        this.tarefasDoBIOptions = this.tarefasDoBI.map((v) => {
          return {
            text: v.tit_titulo,
            value: v.modelo_tarefa_id
          };
        });

        this.verificarTitCargaDireta();
      });

      this.getFuncoesPosEnvio().then((res) => {
        this.funcoesPosEnvio = res.data.funcoes.map((v) => {
          return {
            name: v.fpe_descricao,
            code: v.funcao_pos_envio_id
          };
        });
      });
    },
    async getCampanhaCallbacks() {
      const callbackList = await ApiService.get(
        API_LINKS.campanhaCallback + "/" + this.full_object_payload.modelo_id,
        "",
        false
      );
      return callbackList;
    },
    async getFuncoesPosEnvio() {
      const bancoId = this.encontrarBancoIdDaLicenciadaSelecionada();

      const funcoesPosEnvio = ApiService.get(
        API_LINKS.campanha +
          `/funcao-pos-envio/${this.full_object_payload.modelo_id}/${bancoId}`,
        "",
        false
      );
      return funcoesPosEnvio;
    },
    async getTarefasCarga() {
      const tarefas = ApiService.get(
        API_LINKS.campanha +
          "/tarefa-carga/" +
          this.full_object_payload.modelo_id,
        "",
        false
      );
      return tarefas;
    },
    verificarTitCargaDireta() {
      this.stateValidationSelect =
        this.tarefasDoBISelected && this.tarefasDoBISelected != "";

      const tarefaSelecionadaObj = this.tarefasDoBI.find(
        (v) => v.modelo_tarefa_id === this.tarefasDoBISelected
      );
      if (tarefaSelecionadaObj) {
        if (tarefaSelecionadaObj.tit_carga_direta) {
          this.channelsVisibleBI = false;
          this.canais.opcoes.map((v) => {
            if (v.id !== 4 && v.ativo) {
              this.gerenciarCanais(v);
            }
            return v;
          });
        }
      }
    },
    gerenciarCanais(item) {
      this.texto.controle.selecionado = "S/C";
      const nomeDoCanal = item.nome;

      item.ativo = !item.ativo;
      if (item.id === 4) {
        this.tarefasBIVisible = !this.tarefasBIVisible;
      }

      if (item.id === 3 && item.ativo) {
        this.file_preview = "";
      }

      if (
        item.id === 4 &&
        this.canais.selecionados.findIndex((x) => x.id === item.id) === -1
      ) {
        this.canais.selecionados.push(item);
      } else if (
        item.ativo &&
        item.id !== 4 &&
        this.canais.selecionados.findIndex((x) => x.id === item.id) === -1
      ) {
        this.texto.controle.selecionado = nomeDoCanal;
        this.canais.selecionados.push(item);
      } else {
        this.canais.selecionados.splice(
          this.canais.selecionados.findIndex((x) => x.nome === item.nome),
          1
        );
      }
      if (!item.ativo && this.canais.selecionados.length > 0) {
        this.texto.controle.selecionado = this.canais.selecionados[0].nome;
      }
      if (this.canais.selecionados.find((v) => v.id === item.id)) {
        this.canais.selecionados.find((v) => v.id === item.id).ativo =
          item.ativo;
      }
    },
    modeloPossiveis(modeloTiposVmodel) {
      if (modeloTiposVmodel) {
        this.selected_model_type = modeloTiposVmodel;
      }
      this.modelosPossiveisVariable = this.possible_models_of_model_type.filter(
        (v) => {
          return this.selected_model_type == v.mt_tipo;
        }
      );
      this.callback.dropdown.opcoes = [];
      this.full_object_payload.callback_id = null;
    },
    encontrarBancoIdDaLicenciadaSelecionada() {
      const banco = this.liscenced_databases.find((v) => {
        return (
          v.sistema_licenciada_id ===
          this.full_object_payload.sistema_licenciada_id
        );
      });

      if (banco) {
        return banco.sistema_banco_id;
      }
      return -1;
    },
    async pegarLicenciadasDoBanco(licenciadaId) {
      if (licenciadaId) {
        this.full_object_payload.sistema_licenciada_id = licenciadaId;
      }
      this.selected_model = {
        value: -1,
        text: "Selecione o Modelo"
      };
      this.selected_model_type = {
        value: -1,
        text: "Selecione o Tipo"
      };

      const bancoId = this.encontrarBancoIdDaLicenciadaSelecionada();
      await ApiService.get(
        API_LINKS.campanhaModelo + "/" + bancoId,
        "",
        false
      ).then((res) => {
        this.possible_models_of_model_type = res.data.modelos.map((v) => {
          return {
            value: v.modelo_id,
            text: v.md_descricao,
            mt_tipo: v.mt_tipo
          };
        });
        this.modelosPossiveisVariable = this.possible_models_of_model_type;
        this.todosModelosComOpcoesDeFiltros = res.data.modelos.map((v) => {
          return {
            value: v.modelo_id,
            md_filtro_nativo: v.md_filtro_nativo,
            md_variaveis_filtro_nativo_padrao:
              v.md_variaveis_filtro_nativo_padrao
          };
        });

        const arrDeOpcoesDeModelosTotais = res.data.modelos.map((v) => {
          return {
            ...v,
            text: v.mt_titulo,
            value: v.mt_tipo
          };
        });

        this.model_type_options = [
          ...new Map(
            arrDeOpcoesDeModelosTotais.map((item) => [item.value, item])
          ).values()
        ];
      });
    },
    async setTipoCampanha(tipoCampanhaId) {
      if (tipoCampanhaId) {
        this.full_object_payload.tipo_campanha_id = tipoCampanhaId;
      }
    },
    async obterTipoCampanha() {
      const response = await ApiService.get(API_LINKS.tipoCampanha, "", false);
      this.tipoCampanha_opcoes = response.data.tiposCampanha.map((v) => {
        return {
          value: v.tipo_campanha_id,
          text: v.tcm_nome
        };
      });
    },
    async obterOpcoesLicenciada() {
      const response = await ApiService.get(
        API_LINKS.sistemaLicenciada,
        "",
        false
      );
      this.liscenced_options = response.data.sistemas.map((v) => {
        return {
          value: v.sistema_licenciada_id,
          text: v.sli_titulo
        };
      });
      if (this.liscenced_options.length == 1) {
        this.full_object_payload.sistema_licenciada_id =
          this.liscenced_options[0].value;
        await this.pegarLicenciadasDoBanco(this.liscenced_options[0].value);
      }
      this.liscenced_databases = response.data.sistemas.map((v) => {
        return {
          sistema_licenciada_id: v.sistema_licenciada_id,
          sistema_banco_id: v.sistema_banco_id,
          ...v
        };
      });
      return response;
    },
    changeActiveTab(tab) {
      this.active_tab = tab;
    },
    maximizeModal() {
      this.is_fullscreen = true;
    },
    minimizeModal() {
      this.is_fullscreen = false;
    },
    toggleModal() {
      if (this.is_open) {
        this.closeModal();
      } else {
        this.openModal();
      }
    },
    openModal() {
      this.is_open = true;
      this.$modal.show("modal-cadastro-campamnha-" + this.modal_name);
    },
    closeModal() {
      this.is_open = false;
      this.show = false;
    },
    checarCanalInativoComTexto(item) {
      // ToDo: preciso refatorar isso depois, desde que não há mais campanha das rotas e sim campanha vinda da edição
      if (!this.$route.params.campanhaObj) {
        return false;
      }
      const foi_selecionado = this.canais.selecionados.some(
        (v) => v.id === item.id
      );
      if (foi_selecionado) {
        return false;
      }
      const campanha = this.$route.params.campanhaObj;
      const texto_do_canal = campanha.CANAIS.find((v) => {
        return v.canal_id === item.id;
      });
      if (texto_do_canal) {
        return true;
      }
      return false;
    },
    toggleRichTextVerHtml() {
      this.rich_text_ver_html = !this.rich_text_ver_html;
    },
    uploader(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new UploadAdapter(loader);
      };
    },
    logarCanaisDebug() {
      this.verificarSeOsCanaisTemOrdem();
    },
    mensagemFollowSequence() {
      if (this.followSequenceObject) {
        return "Seguir sequência";
      }
      return "Todos os Canais";
    },
    verificarSeOsCanaisTemOrdem() {
      if (this.followSequenceObject) {
        const arrayOfChannelsIndexedByOrder = this.canais_draggable_exibir.map(
          (c) => c.id
        );

        this.full_object_payload.canais.map((canal, index) => {
          canal.cac_ordem =
            arrayOfChannelsIndexedByOrder.indexOf(canal.canal_id) + 1;
        });
      } else {
        this.full_object_payload.canais.map((canal, index) => {
          canal.cac_ordem = 0 + "";
        });
      }
    },
    ativarEmailInput() {
      let emailFileInput = this.$refs.emailFileInput;
      emailFileInput.click();
    },
    inserirHtmlNoRichText(htmlParaInserir) {
      this.texto.canaisTexto["E-Mail"] = htmlParaInserir;
    },
    mostrarModalHtmlPreview() {
      this.$modal.show("modal-html-preview");
    },
    mostrarModalEmail() {
      this.$modal.show("modal-editar-email");
    },
    redirectToDashAmostra() {
      ApiService.post(
        API_LINKS.solicitacaoPainelAmostra + "/" + this.modelo_campanha_id,
        { cac_senha: this.cacSenha },
        { baseURL: process.env.VUE_APP_COMUNICANTE_API }
      ).then((res) => {
        ApiService.post(
          API_LINKS.respostaPainelAmostra + "/" + this.modelo_campanha_id,
          {
            cac_senha: this.cacSenha,
            uid: res.data.requisicao.uid
          },
          { baseURL: process.env.VUE_APP_COMUNICANTE_API }
        );
      });
    },
    alterarCacSenha(novaCacSenha) {
      this.cacSenha = novaCacSenha;
    },
    insertIntoCursor(event, variavel) {
      event.preventDefault();
      if (this.texto.controle.selecionado === "E-Mail") {
        const textArea = document.createElement("input");
        textArea.value = `{${variavel}}`;
        textArea.style = { position: "absolute", left: "-9999px" };
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand("copy");
        document.body.removeChild(textArea);
        this.$toast.add({
          severity: "success",
          summary: "Variável copiada",
          detail: `Variável {${variavel}} copiada com sucesso.`,
          life: 7000
        });
        setTimeout(() => {
          this.$refs.richTextEmail.quill.focus();
        }, 50);

        return;
      }
      let textArea;
      if (this.texto.controle.selecionado === "Webhook") {
        const inputId = this.lastfocusedWebhookTextAreaActive;
        if (inputId === "headers-input" || inputId === "format-input") {
          textArea = document.getElementById(inputId);
        } else {
          return; // Caso não seja nenhum desses selecionados, finaliza a execução da função.
        }
      } else {
        textArea = document.getElementById("textoCanaisTextArea");
      }

      const start = textArea.selectionStart;
      const end = textArea.selectionEnd;
      const text = textArea.value;
      const before = text.substring(0, start);
      const after = text.substring(end, text.length);
      // Caso seja o canal de webhook, tem que colocar entre '{{' e '}}'
      if (this.texto.controle.selecionado === "Webhook") {
        textArea.value = before + `{{${variavel}}}` + after;
      } else {
        textArea.value = before + `{${variavel}}` + after;
      }

      if (
        typeof this.texto.canaisTexto[this.texto.controle.selecionado] !==
          "undefined" &&
        this.texto.controle.selecionado !== "Webhook"
      ) {
        this.texto.canaisTexto[this.texto.controle.selecionado] =
          textArea.value.trim();
      }
      textArea.selectionEnd = end + variavel.length + 2;
      if (this.texto.controle.selecionado === "Webhook") {
        const event = new Event("change", {
          bubbles: true,
          cancelable: true
        });
        textArea.dispatchEvent(event);
      }
    },
    changeNumeroMaximoHoras() {
      if (this.full_object_payload.callback_id == null) {
        this.full_object_payload.cam_limite_maximo_horas = null;
        return;
      }

      const callback = this.callback.dropdown.opcoes.find(
        (c) => this.full_object_payload.callback_id == c.value
      );
      if (!callback) return;
      this.full_object_payload.cam_limite_maximo_horas =
        callback.cal_limite_maximo_horas;
    },
    removeImage() {
      this.file_preview = "";
      this.imageToUpload = "";
      ApiService.post(
        API_LINKS.contasAtivas + "/delete-arquivo",
        {
          caminho: this.caminhoImageWhatsapp
        },
        null,
        false
      ).catch(() => {
        this.$toast.add({
          severity: "error",
          summary: "Erro no Upload de Imagem",
          detail: `Não foi possível salvar imagem.`,
          life: 7000
        });
      });
    },
    verificarCanaisExcluidos() {
      const canais_ids = this.full_object_payload.canais.map((v) => {
        return v.modelo_canal_id;
      });

      this.full_object_payload.canais_excluir = this.initialChannels.filter(
        (v) => canais_ids.indexOf(v) === -1
      );
    },

    createNewFrequencyWithParameters(
      mdc_cron,
      mdc_ativo,
      mdc_cron_txt,
      mdc_data_inicio,
      mdc_data_fim
    ) {
      const splitedCron = mdc_cron.split(" ");

      this.createNewFrequency();

      let dataInicio = null;
      if (mdc_data_inicio) {
        dataInicio = moment(mdc_data_inicio);

        this.frequencia[this.frequencia.length - 1].dataInicio =
          dataInicio.format("YYYY-MM-DD");
        this.frequencia[this.frequencia.length - 1].horaInicio =
          dataInicio.format("HH:mm");

        this.frequencia[this.frequencia.length - 1].mdc_data_inicio =
          mdc_data_inicio;
      }

      let dataTermino = null;
      if (mdc_data_fim) {
        dataTermino = moment(mdc_data_fim);

        this.frequencia[this.frequencia.length - 1].dataTermino =
          dataTermino.format("YYYY-MM-DD");
        this.frequencia[this.frequencia.length - 1].horaTermino =
          dataTermino.format("HH:mm");

        this.frequencia[this.frequencia.length - 1].mdc_data_fim = mdc_data_fim;
      }

      if (
        dataInicio !== null &&
        dataTermino !== null &&
        dataTermino.diff(dataInicio, "days") === 2
      ) {
        this.frequencia[this.frequencia.length - 1].dropdown.selecionado = 0;
        this.frequencia[this.frequencia.length - 1].umaVez.dataSelecionada =
          dataInicio.add(1, "days").toDate();
      } else if (
        (isNaN(splitedCron[0]) &&
          isNaN(splitedCron[1]) &&
          isNaN(splitedCron[2]) &&
          isNaN(splitedCron[3]) &&
          isNaN(splitedCron[4])) ||
        splitedCron[0].indexOf("/") !== -1
      ) {
        this.frequencia[this.frequencia.length - 1].dropdown.selecionado = 1;
        this.frequencia[this.frequencia.length - 1].aCadaMinuto.minuto =
          splitedCron[0] === "*"
            ? "1 minutos"
            : splitedCron[0].split("/")[1] + " minutos";
        if (splitedCron[1].length > 1) {
          this.frequencia[
            this.frequencia.length - 1
          ].aCadaMinuto.intervaloInicio =
            this.formatToCorrectTimeWithZeroBefore(
              splitedCron[1].split("-")[0]
            ) + " horas";
          this.frequencia[this.frequencia.length - 1].aCadaMinuto.intervaloFim =
            this.formatToCorrectTimeWithZeroBefore(
              splitedCron[1].split("-")[1]
            ) + " horas";
        }
      } else if (
        isNaN(splitedCron[1]) &&
        isNaN(splitedCron[2]) &&
        isNaN(splitedCron[3]) &&
        isNaN(splitedCron[4])
      ) {
        this.frequencia[this.frequencia.length - 1].dropdown.selecionado = 2;
        this.frequencia[
          this.frequencia.length - 1
        ].umaVezHora.dropdown.selecionado =
          this.formatToCorrectTimeWithZeroBefore(splitedCron[0]) + " min";

        if (splitedCron[1].length > 1) {
          this.frequencia[
            this.frequencia.length - 1
          ].umaVezHora.intervaloInicio =
            this.formatToCorrectTimeWithZeroBefore(
              splitedCron[1].split("-")[0]
            ) + " horas";
          this.frequencia[this.frequencia.length - 1].umaVezHora.intervaloFim =
            this.formatToCorrectTimeWithZeroBefore(
              splitedCron[1].split("-")[1]
            ) + " horas";
        }
      } else if (
        isNaN(splitedCron[2]) &&
        isNaN(splitedCron[3]) &&
        isNaN(splitedCron[4])
      ) {
        this.frequencia[this.frequencia.length - 1].dropdown.selecionado = 3;

        this.frequencia[this.frequencia.length - 1].umaVezDia.hora =
          this.formatToCorrectTimeWithZeroBefore(splitedCron[1]) +
          ":" +
          this.formatToCorrectTimeWithZeroBefore(splitedCron[0]);
      } else if (isNaN(splitedCron[2]) && isNaN(splitedCron[3])) {
        this.frequencia[this.frequencia.length - 1].dropdown.selecionado = 4;
        this.frequencia[
          this.frequencia.length - 1
        ].umaVezSemana.dropdown.selecionado = splitedCron[4];
        this.frequencia[
          this.frequencia.length - 1
        ].umaVezSemana.dropdown.seleconado = splitedCron[4];
        this.frequencia[this.frequencia.length - 1].umaVezSemana.hora =
          this.formatToCorrectTimeWithZeroBefore(splitedCron[1]) +
          ":" +
          this.formatToCorrectTimeWithZeroBefore(splitedCron[0]);
      } else if (isNaN(splitedCron[3]) && isNaN(splitedCron[4])) {
        this.frequencia[this.frequencia.length - 1].dropdown.selecionado = 5;
        this.frequencia[this.frequencia.length - 1].umaVezMes.hora =
          this.formatToCorrectTimeWithZeroBefore(splitedCron[1]) +
          ":" +
          this.formatToCorrectTimeWithZeroBefore(splitedCron[0]);
        this.frequencia[this.frequencia.length - 1].umaVezMes.dia =
          splitedCron[2];
      } else if (isNaN(splitedCron[4])) {
        this.frequencia[this.frequencia.length - 1].dropdown.selecionado = 6;
        this.frequencia[
          this.frequencia.length - 1
        ].umaVezAno.dropdown.selecionado = splitedCron[3] - 1;
        this.frequencia[this.frequencia.length - 1].umaVezAno.hora =
          this.formatToCorrectTimeWithZeroBefore(splitedCron[1]) +
          ":" +
          this.formatToCorrectTimeWithZeroBefore(splitedCron[0]);
        this.frequencia[this.frequencia.length - 1].umaVezAno.dia =
          splitedCron[2];
      }

      this.frequencia[this.frequencia.length - 1].mdc_ativo = mdc_ativo;
      this.frequencia[this.frequencia.length - 1].mdc_cron_txt = mdc_cron_txt;
    },
    transformarFrequenciasEmCrons() {
      const months = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ];

      const days = [
        "domingo",
        "segunda-feira",
        "terça-feira",
        "quarta-feira",
        "quinta-feira",
        "sexta-feira",
        "sábado"
      ];
      for (let i = 0; i < this.frequencia.length; i++) {
        if (this.frequencia[i].umaVez.dataSelecionada !== null) {
          const data = this.frequencia[i].umaVez.dataSelecionada;

          this.frequencia[
            i
          ].mdc_cron = `${+data.getMinutes()} ${+data.getHours()} ${+data.getDate()} ${
            +this.frequencia[i].umaVez.dataSelecionada.getMonth() + 1
          } *`;
          this.frequencia[
            i
          ].mdc_cron_txt = `No dia ${+data.getDate()}, às ${+data.getHours()} horas e ${+data.getMinutes()} minutos.`;

          this.frequencia[i].mdc_data_inicio = moment(data)
            .subtract(1, "days")
            .format("YYYY-MM-DD HH:mm:ss");

          this.frequencia[i].mdc_data_fim = moment(data)
            .add(1, "days")
            .format("YYYY-MM-DD HH:mm:ss");
        } else if (this.frequencia[i].aCadaMinuto.minuto !== "") {
          this.frequencia[i].mdc_cron = `${
            +this.frequencia[i].aCadaMinuto.minuto.substring(0, 2) === 1
              ? "*"
              : "*/" + +this.frequencia[i].aCadaMinuto.minuto.substring(0, 2)
          } ${
            this.frequencia[i].aCadaMinuto.intervaloInicio.length > 0 &&
            this.frequencia[i].aCadaMinuto.intervaloFim.length > 0
              ? `${+this.frequencia[i].aCadaMinuto.intervaloInicio.substring(
                  0,
                  2
                )}-${+this.frequencia[i].aCadaMinuto.intervaloFim.substring(
                  0,
                  2
                )}`
              : "*"
          } * * *`;
          this.frequencia[
            i
          ].mdc_cron_txt = `Uma vez a cada ${this.frequencia[i].aCadaMinuto.minuto}`;
          this.frequencia[i].aCadaMinuto;
        } else if (
          this.frequencia[i].umaVezHora.dropdown.selecionado !== "min"
        ) {
          if (
            this.frequencia[i].umaVezHora.intervaloInicio.length > 0 &&
            this.frequencia[i].umaVezHora.intervaloFim.length > 0
          ) {
            this.frequencia[i].mdc_cron = `${+this.frequencia[
              i
            ].umaVezHora.dropdown.selecionado.substring(
              0,
              2
            )} ${+this.frequencia[i].umaVezHora.intervaloInicio.substring(
              0,
              2
            )}-${+this.frequencia[i].umaVezHora.intervaloFim.substring(
              0,
              2
            )} * * *`;
          } else {
            this.frequencia[i].mdc_cron = `${+this.frequencia[
              i
            ].umaVezHora.dropdown.selecionado.substring(0, 2)} * * * *`;
          }

          this.frequencia[i].mdc_cron_txt = `Toda hora aos ${+this.frequencia[
            i
          ].umaVezHora.dropdown.selecionado.substring(0, 2)} minutos`;
        } else if (this.frequencia[i].umaVezDia.hora !== null) {
          this.frequencia[i].mdc_cron = `${+this.frequencia[
            i
          ].umaVezDia.hora.substring(3, 6)} ${+this.frequencia[
            i
          ].umaVezDia.hora.substring(0, 2)} * * *`;
          this.frequencia[
            i
          ].mdc_cron_txt = `Uma vez ao dia às ${+this.frequencia[
            i
          ].umaVezDia.hora.substring(0, 2)} horas e ${+this.frequencia[
            i
          ].umaVezDia.hora.substring(3, 6)} minutos.`;
        } else if (this.frequencia[i].umaVezSemana.hora !== null) {
          this.frequencia[i].mdc_cron = `${+this.frequencia[
            i
          ].umaVezSemana.hora.substring(3, 6)} ${+this.frequencia[
            i
          ].umaVezSemana.hora.substring(0, 2)} * * ${
            this.frequencia[i].umaVezSemana.dropdown.selecionado
          }`;
          this.frequencia[i].mdc_cron_txt = `Uma vez por semana, ${
            days[this.frequencia[i].umaVezSemana.dropdown.selecionado]
          } às ${+this.frequencia[i].umaVezSemana.hora.substring(
            0,
            2
          )} horas e ${+this.frequencia[i].umaVezSemana.hora.substring(
            3,
            6
          )} minutos.`;
        } else if (this.frequencia[i].umaVezMes.dia !== null) {
          this.frequencia[i].mdc_cron = `${+this.frequencia[
            i
          ].umaVezMes.hora.substring(3, 6)} ${+this.frequencia[
            i
          ].umaVezMes.hora.substring(0, 2)} ${
            this.frequencia[i].umaVezMes.dia
          } * *`;
          this.frequencia[i].mdc_cron_txt = `Uma vez ao mês no dia ${
            this.frequencia[i].umaVezMes.dia
          }, às ${+this.frequencia[i].umaVezMes.hora.substring(
            0,
            2
          )} horas e ${+this.frequencia[i].umaVezMes.hora.substring(
            3,
            6
          )} minutos.`;
        } else if (this.frequencia[i].umaVezAno.dia !== null) {
          this.frequencia[i].mdc_cron = `${+this.frequencia[
            i
          ].umaVezAno.hora.substring(3, 6)} ${+this.frequencia[
            i
          ].umaVezAno.hora.substring(0, 2)} ${
            this.frequencia[i].umaVezAno.dia
          } ${+this.frequencia[i].umaVezAno.dropdown.selecionado + 1} *`;
          this.frequencia[i].mdc_cron_txt = `Uma vez ao ano, no mês de ${
            months[this.frequencia[i].umaVezAno.dropdown.selecionado]
          }, no dia ${+this.frequencia[i].umaVezAno.dia}, às ${+this.frequencia[
            i
          ].umaVezAno.hora.substring(0, 2)} horas e ${+this.frequencia[
            i
          ].umaVezAno.hora.substring(3, 6)} minutos`;
        }
      }
    },

    removerFrequencia(idx) {
      this.frequencia.splice(idx, 1);
    },
    createNewFrequency() {
      this.frequencia.push({
        dropdown: {
          selecionado: {
            value: -1,
            text: ""
          },
          opcoes: [
            {
              value: 0,
              text: "Uma única vez"
            },
            {
              value: 1,
              text: "A cada minuto"
            },
            {
              value: 2,
              text: "Uma vez a cada hora"
            },
            {
              value: 3,
              text: "Uma vez ao dia"
            },
            {
              value: 4,
              text: "Uma vez por semana"
            },
            {
              value: 5,
              text: "Uma vez por mês"
            },
            {
              value: 6,
              text: "Uma vez ao ano"
            }
          ]
        },
        umaVez: {
          dataSelecionada: null,
          timezone: ""
        },
        aCadaMinuto: {
          minutoOpcoes: Array.from(
            {
              length: 60
            },
            (_, idx) => `${++idx} minutos`
          ),
          horasOpcoes: [
            "00 horas",
            "01 horas",
            "02 horas",
            "03 horas",
            "04 horas",
            "05 horas",
            "06 horas",
            "07 horas",
            "08 horas",
            "09 horas",
            "10 horas",
            "11 horas",
            "12 horas",
            "13 horas",
            "14 horas",
            "15 horas",
            "16 horas",
            "17 horas",
            "18 horas",
            "19 horas",
            "20 horas",
            "21 horas",
            "22 horas",
            "23 horas"
          ],
          minuto: "",
          intervaloInicio: "",
          intervaloFim: ""
        },
        umaVezHora: {
          dropdown: {
            selecionado: "min",
            opcoes: ["00 min", "10 min", "20 min", "30 min", "40 min", "50 min"]
          },
          horasOpcoes: [
            "00 horas",
            "01 horas",
            "02 horas",
            "03 horas",
            "04 horas",
            "05 horas",
            "06 horas",
            "07 horas",
            "08 horas",
            "09 horas",
            "10 horas",
            "11 horas",
            "12 horas",
            "13 horas",
            "14 horas",
            "15 horas",
            "16 horas",
            "17 horas",
            "18 horas",
            "19 horas",
            "20 horas",
            "21 horas",
            "22 horas",
            "23 horas"
          ],
          intervaloInicio: "",
          intervaloFim: ""
        },
        umaVezDia: {
          hora: null
        },
        umaVezSemana: {
          dropdown: {
            selecionado: {
              value: -1,
              text: "Selecione"
            },
            opcoes: [
              {
                value: 0,
                text: "Domingo"
              },
              {
                value: 1,
                text: "Segunda"
              },
              {
                value: 2,
                text: "Terça"
              },
              {
                value: 3,
                text: "Quarta"
              },
              {
                value: 4,
                text: "Quinta"
              },
              {
                value: 5,
                text: "Sexta"
              },
              {
                value: 6,
                text: "Sábado"
              }
            ]
          },
          hora: null
        },
        umaVezMes: {
          dia: null,
          hora: null
        },
        umaVezAno: {
          dropdown: {
            selecionado: {
              value: -1,
              text: "Mês do ano"
            },
            opcoes: [
              {
                value: 0,
                text: "Janeiro"
              },
              {
                value: 1,
                text: "Fevereiro"
              },
              {
                value: 2,
                text: "Março"
              },
              {
                value: 3,
                text: "Abril"
              },
              {
                value: 4,
                text: "Maio"
              },
              {
                value: 5,
                text: "Junho"
              },
              {
                value: 6,
                text: "Julho"
              },
              {
                value: 7,
                text: "Agosto"
              },
              {
                value: 8,
                text: "Setembro"
              },
              {
                value: 9,
                text: "Outubro"
              },
              {
                value: 10,
                text: "Novembro"
              },
              {
                value: 11,
                text: "Dezembro"
              }
            ]
          },
          dia: null,
          hora: null,
          diaCompleto: null,
          dataSelecionada: null,
          timezone: null
        },
        mdc_cron: "",
        mdc_cron_txt: "",
        mdc_ativo: false,
        dataInicio: null,
        horaInicio: null,
        dataTermino: null,
        horaTermino: null,
        mdc_data_inicio: null,
        mdc_data_fim: null
      });
    },
    formatToCorrectTimeWithZeroBefore(time) {
      return time.toString().length === 1 ? "0" + time : time;
    },
    solicitarPreviaApenasCount() {
      this.campanhaExecutada = true;
      this.totalDeMensagens = null;
      this.tableHeaders = null;
      this.tableContent = null;
      this.btnMostrarModalVisible = false;

      if (this.current_uid_preview_count) {
        ApiService.get(
          API_LINKS.campanhaPreviewDados +
            `/${this.full_object_payload.sistema_licenciada_id}/${this.current_uid_preview_count}`
        ).then((res2) => {
          if (res2.data.requisicao.sucesso) {
            if (
              res2.data.requisicao.requisicao?.resultado?.code === "EREQUEST"
            ) {
              const response_error_stringified = JSON.stringify(
                res2.data.requisicao.requisicao?.resultado
              );
              this.$toast.add({
                severity: "error",
                summary: "Erro",
                detail: response_error_stringified,
                life: 7000
              });
              this.current_uid_preview_count = null;
              return;
            }

            if (res2.data.requisicao.requisicao.status == "PENDENTE") {
              this.$refs.btnExecutarPreviewModalCadastroCampanha.innerText =
                "Tentar Novamente";
              this.$toast.add({
                severity: "warn",
                summary: "Resultado Pendente",
                detail: `O resultado da prévia ainda está sendo processando. Tente novamente.`,
                life: 7000
              });
              return;
            }

            this.totalDeMensagens =
              res2.data.requisicao.requisicao.resultado[0]?.count_qtd;
            this.tableHeaders = Object.keys(
              res2.data.requisicao.requisicao.resultado[0]
            );
            this.tableContent = res2.data.requisicao.requisicao.resultado;
            this.btnMostrarModalVisible = true;
            this.previewJaFoiExecutado = true;
            this.$refs.btnExecutarPreviewModalExecucaoManual.innerText =
              "Solicitar Prévia";
            this.executouPreviewCompleto = false;
            this.current_uid_preview_count = null;
          }
        });
        return;
      }

      ApiService.post(
        API_LINKS.campanhaPreviewPost,
        {
          apenas_count: true,
          campanha: {
            sistema_licenciada_id:
              this.full_object_payload.sistema_licenciada_id,
            modelo_id: this.full_object_payload.modelo_id,
            mdc_variaveis_filtro_nativo:
              this.full_object_payload.mdc_variaveis_filtro_nativo,
            filtros: this.listaDeFiltros
          }
        },
        null,
        false
      ).then((res) => {
        if (res.data.requisicao.sucesso) {
          this.current_uid_preview_count = res.data.requisicao.uid;
          ApiService.get(
            API_LINKS.campanhaPreviewDados +
              `/${this.full_object_payload.sistema_licenciada_id}/${res.data.requisicao.uid}`
          ).then((res2) => {
            if (res2.data.requisicao.sucesso) {
              this.current_uid_preview_count = res2.data.requisicao.uid;
              if (
                res2.data.requisicao.requisicao?.resultado?.code === "EREQUEST"
              ) {
                const { message = "Campanha Invalida." } =
                  res2.data.requisicao.requisicao?.resultado;
                this.$toast.add({
                  severity: "error",
                  summary: "Erro",
                  detail: message,
                  life: 7000
                });
                this.current_uid_preview_count = null;
                return;
              }
              if (res2.data.requisicao.requisicao.status == "PENDENTE") {
                this.$refs.btnExecutarPreviewModalCadastroCampanha.innerText =
                  "Tentar Novamente";
                this.$toast.add({
                  severity: "warn",
                  summary: "Resultado Pendente",
                  detail: `O resultado da prévia ainda está sendo processando. Tente novamente.`,
                  life: 7000
                });
                return;
              }

              this.totalDeMensagens =
                res2.data.requisicao.requisicao.resultado[0]?.count_qtd;
              this.tableHeaders = Object.keys(
                res2.data.requisicao.requisicao.resultado[0]
              );
              this.tableContent = res2.data.requisicao.requisicao.resultado;
              this.btnMostrarModalVisible = true;
              this.previewJaFoiExecutado = true;
              this.$refs.btnExecutarPreviewModalCadastroCampanha.innerText =
                "Solicitar Prévia";
              this.executouPreviewCompleto = false;
              this.current_uid_preview_count = null;
            }
          });
        } else {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: `Cliente offline.`,
            life: 3000
          });
        }
      });
    },

    async solicitarPrevia() {
      try {
        if (this.executouPreviewCompleto) {
          this.mostrarModalPreviewTable();
          return;
        }

        this.campanhaExecutada = true;
        this.tableHeaders = null;
        this.tableContent = null;

        if (this.current_uid_preview) {
          await this.buscarDadosPrevia(this.current_uid_preview);
          return;
        }

        const res = await ApiService.post(
          API_LINKS.campanhaPreviewPost,
          {
            campanha: {
              sistema_licenciada_id:
                this.full_object_payload.sistema_licenciada_id,
              modelo_id: this.full_object_payload.modelo_id,
              mdc_variaveis_filtro_nativo:
                this.full_object_payload.mdc_variaveis_filtro_nativo,
              filtros: this.listaDeFiltros
            }
          },
          null,
          false
        );

        if (res.data.requisicao.sucesso) {
          this.current_uid_preview = res.data.requisicao.uid;
          await this.buscarDadosPrevia(this.current_uid_preview);
        } else {
          this.exibirToast("error", "Erro", "Cliente offline.", 3000);
        }
      } catch (error) {
        console.error("Erro ao solicitar prévia:", error);
        this.exibirToast(
          "error",
          "Erro inesperado",
          "Ocorreu um erro ao processar a solicitação.",
          5000
        );
      }
    },
    async buscarDadosPrevia(uid) {
      try {
        const res2 = await ApiService.get(
          `${API_LINKS.campanhaPreviewDados}/${this.full_object_payload.sistema_licenciada_id}/${uid}`
        );

        if (!res2.data.requisicao.sucesso) {
          this.exibirToast(
            "error",
            "Erro",
            "Falha ao obter dados da prévia.",
            5000
          );
          return;
        }

        const requisicao = res2.data.requisicao.requisicao || {};

        if (requisicao.resultado?.code === "EREQUEST") {
          this.exibirToast(
            "error",
            "Erro",
            JSON.stringify(requisicao.resultado),
            7000
          );
          this.current_uid_preview = null;
          return;
        }

        if (requisicao.status === "PENDENTE") {
          this.definirBotaoTexto("Tentar Novamente");
          this.exibirToast(
            "warn",
            "Resultado Pendente",
            "O resultado da prévia ainda está sendo processado. Tente novamente.",
            7000
          );
          return;
        }

        this.processarDadosPrevia(requisicao.resultado);
      } catch (error) {
        console.error("Erro ao buscar dados da prévia:", error);
        this.exibirToast(
          "error",
          "Erro inesperado",
          "Não foi possível buscar os dados da prévia.",
          5000
        );
      }
    },

    processarDadosPrevia(resultado) {
      this.totalDeMensagens = Array.isArray(resultado) ? resultado.length : 0;

      if (this.totalDeMensagens > 0) {
        this.tableHeaders = Object.keys(resultado[0]);
        this.tableContent = resultado;
        this.definirBotaoTexto("Solicitar Prévia");

        setTimeout(() => {
          this.mostrarModalPreviewTable();
          this.executouPreviewCompleto = true;
        }, 500);

        this.current_uid_preview = null;
      } else {
        this.exibirToast(
          "warn",
          "Resultado Vazio",
          "A prévia completa não retornou nenhum resultado.",
          7000
        );
        this.tableHeaders = null;
        this.tableContent = null;
        this.current_uid_preview = null;
      }
    },

    exibirToast(severity, summary, detail, life = 5000) {
      this.$toast.add({ severity, summary, detail, life });
    },

    definirBotaoTexto(texto) {
      if (this.$refs.btnExecutarPreviewModalCadastroCampanha) {
        this.$refs.btnExecutarPreviewModalCadastroCampanha.innerText = texto;
      }
    },
    definirDataHoraInicio(idx) {
      const frequencia = this.frequencia[idx];
      const data = frequencia.dataInicio;
      const hora = frequencia.horaInicio;

      if (data && hora) {
        this.frequencia[idx].mdc_data_inicio = `${data} ${hora}:00`;
      } else {
        this.frequencia[idx].mdc_data_inicio = null;
      }
    },
    definirDataHoraTermino(idx) {
      const frequencia = this.frequencia[idx];
      const data = frequencia.dataTermino;
      const hora = frequencia.horaTermino;

      if (data && hora) {
        this.frequencia[idx].mdc_data_fim = `${data} ${hora}:00`;
      } else {
        this.frequencia[idx].mdc_data_fim = null;
      }
    },
    isTemplateWppSelected(template) {
      let canal = this.campaing_to_edit_on_retrocompatibility?.CANAIS.find(
        (i) => i.can_nome == "Whatsapp"
      );
      if (canal) {
        let matches = canal.cac_mensagem?.match(/\((.*?)&&/g);
        let nameTemplate = matches
          ? matches[0].replace("&&", "").replace("(", "")
          : "";
        if (template.name == nameTemplate) {
          return true;
        }
      }
      return false;
    },
    copyToClickBoard(valor) {
      if (!valor) return;
      navigator.clipboard.writeText(valor).then(
        () => {
          this.$refs.btnCopyToClickBoard.classList.add("text-primary");
          this.$refs.btnCopyToClickBoard.attributes["title"].value =
            "O texto já foi copiado";
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Erro",
            detail: "Erro ao copiar o texto.",
            life: 3000
          });
        }
      );
    },
    encontrarTemplateByTexto(texto) {
      if (!texto) return null;
      const templateRegex = /@template\(([^)]+)\)/;
      const match = texto.match(templateRegex);
      if (match && match[1]) {
        const templateName = match[1].split("&&")[0];
        const templateEncontrado = this.templateOptions.find(
          (i) => i.name === templateName
        );
        if (!templateEncontrado) {
          return null;
        }
        return templateEncontrado;
      }
      return null;
    },
    preencherTemplateSelecionadoPorTexto(texto) {
      const templateEncontrado = this.encontrarTemplateByTexto(texto);
      if (templateEncontrado) {
        this.templateSelecionadoWpp = templateEncontrado;
      }
    },
    templateSelecionadoPrecisaArquivo() {
      const headerTemplate = this.templateSelecionadoWpp.components.find(
        (i) => i.type == "HEADER"
      );
      if (!headerTemplate) return false;
      return ["IMAGE", "VIDEO", "DOCUMENT"].includes(headerTemplate.format);
    },
    verificaSeAquivoNaoEnviado() {
      const contem = !this.file_preview || !this.caminhoImageWhatsapp;
      //verifica se tem canal PDF como alternativa de envio
      const canalPDF =
        Object.values(this.canais.selecionados).find((v) => v.id == 9) || null;
      return contem && !canalPDF;
    }
  },
  directives: {
    tooltip: Tooltip
  }
};
</script>

<style lang="scss" scoped>
.is-template-wp-selected {
  // background-color: #7da6e4;
  border: 2px solid #78a8f0;
}
::v-deep .vm--overlay {
  background: none !important;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter,
.slide-fade-leave-to,
.slide-fade-leave-active {
  transform: translate(1000px, 1000px);
  opacity: 0;
}

::v-deep .vm--modal {
  overflow: scroll !important;
}

::v-deep .vm--modal.expanded {
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
}

::v-deep .vm--modal.default {
  width: 80% !important;
  height: 70% !important;
  top: 10% !important;
  left: 10% !important;
}

.float-btn {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
  border: 2px solid #18c5bd;
  color: #18c5bd;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: #18c5bd;
    color: #fff;
    animation: none !important;
  }

  animation: pulse 2s infinite;
  font-weight: 500;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 #18c5bd;
  }
  70% {
    transform: scale(1.2);
    box-shadow: 0 0 0 10px rgba(255, 140, 0, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(255, 140, 0, 0);
  }
}

.modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-container {
  padding: 12px !important;
  position: fixed;
  top: 5%;
  left: 5%;
  background: #fff;
  width: 90%;
  height: 90%;
  z-index: 998;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  border-radius: 24px;

  &.fullscreen {
    width: 100% !important;
    height: 100% !important;
    top: 0 !important;
    left: 0 !important;

    border-radius: 0 !important;
  }

  .modal-header {
    border: none !important;
    display: flex;
    justify-content: flex-end;
  }

  .toggle-sistema-modelo-container {
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    border-radius: 0.42rem;
    outline: 1px solid #eee;
  }

  .toggle-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .toggle-title {
      font-size: 16px;
      font-weight: 600;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300&display=swap");

.rich-text-html-btn {
  position: absolute;
  right: 32px;
  bottom: 32px;
}

::v-deep .ck {
  &.ck-editor {
    position: absolute !important;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 300px;
  }

  &.ck-content,
  &.ck-editor__main {
    height: 100%;
  }
}

.rich-text-editor {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 250px;
  border-radius: none;

  .ql-editor {
    font-family: "Inter", sans-serif;
    font-size: 14px;
  }
}

.dragArea.dragArea,
.dragArea.dragArea * {
  ul {
    width: 100%;
  }
}

.canais-draggables-container {
  display: flex;
  gap: 1rem;

  .spacer-divider {
    width: 1px;
    height: 100%;
    background-color: #e0e0e0;
  }
}

.modal-imagem {
  min-width: 100px;
  background: transparent !important;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .modal-imagem .vm--modal {
  width: unset !important;
  background: transparent !important;
  top: unset !important;
  left: unset !important;
}

.modal-templates {
  min-width: 200px;
  height: 200px;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Conversation */

.conversation-html {
  box-sizing: border-box;
  height: 100%;
  margin: 0;
  padding: 0;
}

.conversation-body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  margin: 0;
  padding: 0;
  height: 100%;
}

.conversation {
  height: calc(100% - 12px);
  position: relative;
  background: #efe7dd
    url("https://cloud.githubusercontent.com/assets/398893/15136779/4e765036-1639-11e6-9201-67e728e86f39.jpg")
    repeat;
  z-index: 0;
}

.conversation ::-webkit-scrollbar {
  transition: all 0.5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

.conversation ::-webkit-scrollbar-track {
  background: transparent;
}

.conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

.conversation .conversation-container {
  height: calc(100% - 68px);
  box-shadow: inset 0 10px 10px -10px #000000;
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 5px;
}

.conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both;
}

/* Messages */

.message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
  z-index: -1;
}

.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata .time {
  color: rgba(0, 0, 0, 0.45);
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
}

.metadata .tick svg {
  position: absolute;
  transition: 0.5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
  transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
  transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
  transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
  transform: perspective(800px) rotateY(-179.9deg);
}

.message:first-child {
  margin: 16px 0 8px;
}

.message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
}

.message.received .metadata {
  padding: 0 0 0 16px;
}

.message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.message.sent {
  background: #e1ffc7;
  border-radius: 5px 0 5px 5px;
  float: right;
}

.message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

::v-deep .modal-templates .vm--modal {
  top: unset !important;
  left: unset !important;
}

.form-group {
  margin-bottom: 0 !important;
}

.btn-adicionar-imagem {
  display: none;
}

.btn-adicionar-imagem.active {
  display: block;
}

.btn-selecionar-template {
  display: none;
}

.btn-selecionar-template.active {
  display: block;
}

#formFileLg {
  display: none;
}

#formFileLg.active {
  display: block;
}

.collapse-avancadas {
  background: #eee;
  padding: 12px;
  border-radius: 8px;
  background: #eee !important;
}

.camp-body-right-texto ::v-deep .p-button {
  background: #ccc !important;
  border-color: #ccc !important;
  color: #000;
  transition: 0.3s ease;
}

::v-deep .ql-container.ql-snow {
  border: none !important;
}

::v-deep .ql-toolbar.ql-snow {
  border: none !important;
}

::v-deep .p-button:hover {
  color: rgb(93, 93, 93);
}

::v-deep .p-selectbutton .p-button {
  background: #c4c4c4 !important;
  border-color: #c4c4c4 !important;

  &.p-highlight {
    background: #ffc107 !important;
    border-color: #ffc107 !important;
  }
}

::v-deep .p-button {
  border-radius: 8px;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: unset !important;
}

.modal-filtros {
  position: relative;
}

.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.save-button {
  text-transform: uppercase;
  position: fixed !important;
  right: 60px;
  z-index: 998;
  top: 75px;
}

.tag-canais {
  background: #c4c4c4;
  color: #fff;
  padding: 4px 12px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.tag-canais.active {
  background: #ffc107;
  color: #fff;
}

.text-title {
  font-family: "Inter", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 16px;
  color: #27282c;
  font-weight: 300;
}

.camp-cor-borda {
  border: 1px solid #a0a0a0;
}

.border-black {
  border-radius: 10px;
}

.label-upload {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  margin: 0px;
  transition: all 0.1s ease-in-out;
}

.uploading-image {
  width: 60px;
  height: 40px;
  object-fit: cover;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  transition: all 0.1s ease-in-out;
}

.uploading-image:hover {
  transform: scale(1.05);
}

.label-upload:hover {
  transform: scale(1.1);
}

.icon-class {
  width: 24;
  height: 24;
  color: #383940;
}

.camp-container {
  width: 100%;
  background-color: #fff;
  border-radius: 10px;
  padding-bottom: 24px;
}

.camp-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e6ef;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.camp-body {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  flex-wrap: wrap;
}

.camp-body-left {
  width: 50%;
}

.camp-body-right {
  min-width: 50%;
  padding: 0px 30px;
}

.camp-body-right-texto {
  width: 100%;
  height: 300px;
  padding: 15px 15px 0px 15px;
  border-top: none;
  border-radius: 0px 0px 0px 15px;
  overflow: hidden !important;
}

.camp-body-right-textarea {
  outline: none;
  resize: none;
  font-family: "Inter", Tahoma, sans-serif;
  font-weight: 300;
}

.camp-body-right-variaveis {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: none;
  border-top: none;
  padding: 10px;
  width: 25%;
  height: 300px;
  border-radius: 0px 0px 15px 0px;
}

.camp-body-right-variaveis div .overflow-auto {
  height: 250px !important;
}

.camp-botao-voltar {
  color: #808080;
  transition: all 0.1s linear;
  cursor: pointer;
}

.camp-botao-voltar:hover {
  transform: scale(1.1);
}

.camp-botao-salvar {
  width: 80px;
  height: 35px;
  box-shadow: 0px 0px 1px #000;
  background-color: #0fb60b;
  color: #fff;
}

.botao-variaveis {
  background-color: #bdbec7;
  padding: 0px 4px;
  margin: 1px;
  border-radius: 10px;
  word-break: break-all;
  font-size: calc(9px + 0.18vw);
}

.botao-variaveis:hover {
  transition: all 0.3s ease-in;
  background-color: #808080;
  color: #fff;
}

.button-plus {
  margin-left: 10px;
  height: 40px;
  width: 40px;
  display: inline;
  font-size: 1.2em;
}

.section-data {
  width: 100%;
}

.section-data-modelo {
  display: flex;
}

.caixa-texto {
  margin-top: -7px;
}

.dropdown-secundaria {
  background-color: #fff;
  width: 170px;
}

.dropdown-principais {
  background-color: #fff;
  width: 200px;
  font-size: 8px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.tag-element-custom {
  margin: 0px -20px;
  padding: 0px;
}

.camp-container-droggable {
  display: flex;
}

.camp-input-texto {
  height: 37px;
  width: 100%;
  border: 1px solid #e4e6ef;
  border-radius: 7px;
  padding: 0px 10px;
  font-family: "Inter", Segoe UI, sans-serif;
  font-weight: 350;
  outline: none;
}

.camp-input-date {
  height: 37px;
  border: 1px solid #e4e6ef;
  border-radius: 7px;
  padding: 0px 10px;
  font-family: "Inter", Segoe UI, sans-serif;
  font-weight: 350;
  outline: none;
  background: #fff !important;
}

.camp-input-hora {
  height: 37px;
  width: 100px;
  border-radius: 7px;
  padding: 0px 10px;
  font-family: "Inter", Segoe UI, sans-serif;
  outline: none;
  border: 1px solid #e4e6ef;
  background: #fff !important;
}

.camp-input-dia {
  height: 37px;
  width: 50px;
  border-radius: 7px;
  padding: 0px 10px;
  font-family: "Inter", Segoe UI, sans-serif;
  outline: none;
  border: 1px solid #e4e6ef;
}

.camp-input-number {
  height: 37px;
  border-radius: 7px;
  padding: 0px 10px;
  font-family: "Inter", "Segoe UI", sans-serif;
  outline: none;
}

.camp-template-cabecalho-callback {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}

.camp-template-corpo-callback {
  border-radius: 0 0 10px 10px;
  border-top: none;
  height: 220px;
  width: 100%;
  overflow-y: auto;
}

.select-callback {
  border: none;
}

.camp-template-corpo-filtro {
  border-radius: 0 0 10px 10px;
  border-top: none;
  height: 400px;
  width: 100%;
}

.filtro-lista {
  overflow: auto;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.linha-horizontal {
  width: 100%;
  height: 1px;
  border-bottom: 2px solid #2081aa;
}

.tooltips-class-info {
  background-color: #fff;
}

.nav {
  border: 1px solid #a0a0a0;
  border-top: none;
  border-right: none;
  border-left: none;
}

.botao-filtro {
  width: 100%;
  padding: 2px 6px;
  border-radius: 5px;
  z-index: 10;
}

.trash-container {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-trash:before {
  color: red;
}

.nested-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.nested-header .botao {
  text-transform: uppercase;
}

.botao-filtro-add {
  border: 1px solid #1bc5bd;
  color: #1bc5bd;
  padding: 2px 6px;
  border-radius: 5px;
  z-index: 10;
  padding: 4px 12px;
  height: 38px;
}

.botao-filtro-preview {
  border: 1px solid #ffa800;
  color: #ffa800;
  border-radius: 5px;
  z-index: 10;
  height: 38px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.botao-filtro-ver-preview {
  border: 1px solid #f64e60;
  color: #f64e60;
  border-radius: 5px;
  z-index: 10;
  height: 38px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.p-togglebutton.p-button {
  background: #e4e6ef;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  text-transform: uppercase;
}

.p-togglebutton.p-button.p-highlight {
  background: #1bc5bd;
  border-color: #1bc5bd;
  color: #ffffff;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}

.inactive-channel {
  opacity: 0.5;
}

.total-de-mensagens-container {
  margin-right: 30px;
  font-size: 18px;
}

.total-de-mensagens-container span {
  margin-right: 8px;
}

.back-arrow-squared {
  border: 1px solid #eee;
  border-radius: 4px;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: none !important;
}

.modal-canais-container {
  .modal-canais-body {
    padding: 32px;

    .input-switch-container {
      display: flex !important;
      align-items: center;
      gap: 1rem;

      p {
        margin: 0;
      }
    }

    .drag-canais {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      gap: 1rem;

      .canal-li {
        padding: 4px 8px;
        border-radius: 4px;
        background: #ccc;
        color: #000;

        &.ativo {
          background: #ffc107;
          color: #fff;
        }
      }
    }
  }
}

.border-container-body {
  outline: 1px solid #eee;
  border-radius: 0 0.42rem 0.42rem 0.42rem;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  padding: 8px;
}

.drag-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;

  i {
    margin: 0;
    padding: 0;
  }

  > i:first-child {
    margin-right: 1px;
  }
}

label {
  margin-bottom: 0;
}

::v-deep .custom-select:disabled {
  background-color: #c8c8c8 !important;
}

.frequencies-group {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
}

.campaign-title {
  overflow: hidden;
  position: relative;
  display: inline-block;
}

.campaign-title::before,
.campaign-title::after {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
}

// .campaign-title::before {
//   background-color: #ffc007;
//   height: 2px;
//   bottom: 0;
//   transform-origin: 100% 50%;
//   transform: scaleX(0);
//   transition: transform 0.2s cubic-bezier(0.76, 0, 0.24, 1);
// }

// .campaign-title::after {
//   content: attr(data-replace);
//   height: 100%;
//   top: 0;
//   transform-origin: 100% 50%;
//   transform: translate3d(200%, 0, 0);
//   transition: transform 0.2s cubic-bezier(0.76, 0, 0.24, 1);
//   color: #ffc007;
// }

.campaign-title:hover::before {
  transform-origin: 0% 50%;
  transform: scaleX(1);
}

// .campaign-title:hover::after {
//   transform: translate3d(0, 0, 0);
// }

// .campaign-title span {
//   display: inline-block;
//   transition: transform 0.2s cubic-bezier(0.76, 0, 0.24, 1);
// }

.campaign-title:hover span {
  transform: translate3d(-200%, 0, 0);
}

.campaign-title {
  text-decoration: none;
  color: #18272f;
  vertical-align: top;
}

.input-group > .input-group-prepend {
  width: 100%;
}

.p-scrollpanel-content {
  box-sizing: content-box;
}

::v-deep .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  border: none !important;
}

::v-deep .ck-toolbar {
  border: none !important;
}

::v-deep .ck.ck-toolbar {
  border: none !important;
}

::v-deep .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border: none !important;
}

::v-deep .p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: #ffc107 !important;
  background: #ffc107 !important;
}
</style>
