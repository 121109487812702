const API_LINKS = {
  banco: "/administrativo/sistema-banco/banco",
  sistema: "/administrativo/sistema-banco/sistema",
  sistemaBanco: "/administrativo/sistema-banco",
  sistemaLicenciada: "/administrativo/sistema",
  sistemaLicenciadaTestarConexao: "/administrativo/sistema/testar-conexao",
  sistemaLicenciadaConfiguracoes:
    "/administrativo/sistema/sistema-configuracao",
  sistemaLicenciadaRespostaTesteConexao:
    "administrativo/sistema/resposta-testar-conexao",
  licenca: "/administrativo/licenca",
  licencasOnlines: "/administrativo/licenca/pegar-online",
  tipoCampanha: "/administrativo/tipo-campanha",
  forcarAtualizarCredenciais: "administrativo/licenca/atualizar-credenciais",
  campanha: "/administrativo/campanha",
  campanhaModelo: "/administrativo/campanha/modelo",
  modelo: "/administrativo/modelo",
  campanhaCallback: "/administrativo/campanha/callback",
  campanhaFiltro: "/administrativo/campanha/filtro",
  campanhaUltimasExecucoes: "administrativo/campanha/ultimas-execucoes",
  cancelarCampanhaEmExecucao: "administrativo/campanha/execucao/cancelar",
  lancarCampanha: "/administrativo/campanha/disparar-campanha",
  contasAtivas: "/administrativo/conta",
  listarContas: "/administrativo/contas-usuarios",
  campanhaPreviewPost: "/administrativo/campanha/preview-campanha",
  campanhaPreviewDados: "/administrativo/campanha/resposta-preview-campanha",
  novaSenhaDash: "/administrativo/campanha/painel-amostra/nova-senha",
  disparoManual: "/administrativo/campanha/disparar-campanha-atual",
  solicitacaoPainelAmostra: "/campanha/painel-amostra",
  respostaPainelAmostra: "/campanha/resposta-painel-amostra",
  chatbot: "/administrativo/chatbot/fluxo",
  chatbot_listar_fluxos: "/administrativo/chatbot/fluxo",
  chatbot_menu: "/administrativo/chatbot/menu",
  chatbot_fluxo_estagio: "/administrativo/chatbot/estagio",
  chatbot_fluxo_categoria: "/administrativo/chatbot/categoria",
  chatbot_consultas: "/administrativo/chatbot/campanha",
  chatbot_modelos: "/administrativo/chatbot/modelo",
  chatbot_formatacao_entrada: "/administrativo/chatbot/formatacao-entrada",
  chatbot_duplicar: "/administrativo/chatbot/fluxo/duplicar",
  chatwoot: "/chatwoot",
  chatbot_times: "/chatwoot/times",
  chatbot_consulta: "/administrativo/chatbot/campanha",
  chatbot_callback: "/administrativo/chatbot/callback",
  chatbot_menu_configuracao: "/administrativo/chatbot/menu-configuracao",
  chatbot_modelo_links: "/modelo-link",
  chatbot_possibilidade: "administrativo/chatbot/callback/possibilidade",
  agendas: "/agenda",
  recursosAtivos: "/conta/recurso-ativo",
  feriado: "/feriado",
  listaMensagensAgenda: "/agenda/listar-mensagem",
  salvarMensagemAgenda: "/agenda/salvar-mensagem",
  feature_flags: "/feature",
  atalho: "/atalhos",
  whatsappTemplates: "/template",
  novidades: "/novidade",
  campanhaWebhook: "/webhook/executar-campanha",
  gerarNovoLinkWebhook: "/administrativo/campanha/gerar-webhook-token",
  auditable: "/auditable",
  authRecursoIA: "/auth-recurso-ia"
};

export default API_LINKS;
