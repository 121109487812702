<template>
  <div class="card p-4">
    <div class="card-header">
      <div class="d-flex justify-content-end w-100">
        <b-button
          variant="success"
          class="b-button-custom"
          @click="updatePrompts"
        >
          Aplicar Alterações</b-button
        >
      </div>
    </div>
    <div class="card-body">
      <Toast position="top-right" />
      <div class="row mt-3">
        <div class="col-12">
          <p class="text-title m-0 mb-2">Conta</p>
          <Select2
            v-model="conta_selecionada"
            :options="contas_formatadas"
            @change="onChangeContaSelect"
            class="form-control"
            style="position: relative !important"
          />
        </div>

        <template v-if="conta_selecionada_objeto">
          <div class="col-6 mt-5">
            <p class="text-title m-0 mb-2">Prompt Inicial</p>
            <b-form-textarea
              v-model="auth_recurso_ia.prompt_inicial"
              rows="15"
            ></b-form-textarea>
          </div>
          <div class="col-6 mt-5">
            <p class="text-title m-0 mb-2">Prompt de Agendamento</p>
            <b-form-textarea
              v-model="auth_recurso_ia.prompt_agendamento"
              rows="15"
            ></b-form-textarea>
          </div>
          <div class="col-6 mt-5">
            <p class="text-title m-0 mb-2">Prompt de Fluxo</p>
            <b-form-textarea
              v-model="auth_recurso_ia.prompt_fluxo"
              rows="15"
            ></b-form-textarea>
          </div>
          <div class="col-6 mt-5">
            <p class="text-title m-0 mb-2">Prompt Finalizar</p>
            <b-form-textarea
              v-model="auth_recurso_ia.prompt_finalizar"
              rows="15"
            ></b-form-textarea>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import API_LINKS from "../../painel/automacao/api.links.js";
import "moment/locale/pt-br";
import Toast from "primevue/toast";
import Swal from "sweetalert2";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Select2 from "v-select2-component";

export default {
  components: {
    Toast,
    Select2
  },
  data() {
    return {
      contas_formatadas: [],
      contas: [],
      conta_selecionada: null,
      conta_selecionada_objeto: null,
      auth_recurso_ia: {
        prompt_inicial: null,
        prompt_fluxo: null,
        prompt_finalizar: null,
        prompt_agendamento: null
      }
    };
  },

  methods: {
    async onChangeContaSelect(selected) {
      this.conta_selecionada_objeto = this.contas.find(
        (conta) => conta.con_login === selected
      );
      await this.getPrompts(this.conta_selecionada_objeto.conta_id);
    },
    async getPrompts(conta_id) {
      const res = await ApiService.get(
        API_LINKS.authRecursoIA + "/prompts/" + conta_id
      );
      this.auth_recurso_ia = res.data;
    },
    async updatePrompts() {
      let campos_pendentes = false;
      if (!this.auth_recurso_ia.recurso_ia_id) {
        campos_pendentes = true;
      } else if (!this.auth_recurso_ia.prompt_inicial) {
        campos_pendentes = true;
      }

      if (campos_pendentes) {
        Swal.fire({
          title: "Campos pendentes",
          text: "Preencha todos os campos obrigatórios",
          icon: "warning",
          confirmButtonText: "Ok"
        });
        return;
      }

      const payload = {
        ...this.auth_recurso_ia
      };
      await ApiService.put(API_LINKS.authRecursoIA + "/prompts", payload);

      Swal.fire({
        title: "Sucesso",
        text: "Prompts alterados com sucesso",
        icon: "success",
        confirmButtonText: "Ok"
      });
    },
    async pegarContas() {
      const contas = await ApiService.get(API_LINKS.authRecursoIA + "/contas");
      this.contas = contas.data;
      this.contas_formatadas = contas.data.map((conta) => conta.con_login);
    }
  },
  mounted() {
    const init = async () => {
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: "Recurso IA",
          route: "/painel/recurso-ia"
        },
        {
          title: "Prompt"
        }
      ]);
      await this.pegarContas();
    };
    init();
  }
};
</script>

<style scoped>
::v-deep .select2 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100% !important;
  height: 35px !important;
}

::v-deep .select2-container .select2-selection--single {
  height: unset !important;
}
::v-deep .select2-container--default .select2-selection--single {
  border: none !important;
  line-height: unset !important;
}
::v-deep
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  line-height: unset !important;
  height: 35px !important;
}
</style>
