<template>
  <div>
    <div
      class="d-flex justify-content-center align-items-center h-75"
      v-if="carregando"
    >
      <v-progress-circular indeterminate size="32" color="#462181" width="2"
        ><v-progress-circular
          indeterminate
          size="24"
          color="#ff7f00"
          width="2"
          rotate="180"
        ></v-progress-circular
      ></v-progress-circular>
    </div>
    <div class="d-flex flex-column justify-content-between w-100" v-else>
      <Toast position="top-right" />
      <!-- scrollable -->
      <modal name="modal-detalhes-licenciada" width="550px" height="auto">
        <modal-detalhes-licenciada
          @botao-voltar="$modal.hide('modal-detalhes-licenciada')"
          @botao-editar="editar($event)"
          @exibir-toast="exibirToast($event)"
          :item="itemSelecionado"
        />
      </modal>
      <modal name="configuracoes-extra" width="700px" height="540px">
        <configuracoes-extras
          @botao-voltar="$modal.hide('configuracoes-extra')"
          :licenciada="itemSelecionado"
        />
      </modal>
      <b-table
        id="tabelaSistemaLicenciada"
        small
        :current-page="paginacao.paginaAtual"
        :per-page="paginacao.porPagina"
        :fields="fields"
        :items="itens"
        responsive="sm"
        hover
      >
        <template #cell(sli_titulo)="row">
          <div class="mt-3" title="Ver detalhes" @click="detalhes(row.item)">
            {{ row.item.sli_titulo }}
          </div>
        </template>

        <template #cell(label_sist_banco)="row">
          <div class="mt-3" title="Ver detalhes" @click="detalhes(row.item)">
            {{ row.item.label_sist_banco }}
          </div>
        </template>

        <template #cell(sli_token)="data">
          <div class="mt-3">
            {{ data.value }}
            <i
              class="fa-regular fa-copy"
              style="font-size: 12px; margin-left: 5px; color: #3699ff"
              :id="data.value + '-tooltip'"
              title="Copiar sli_token"
              @click="copiar(data.value)"
            ></i>
            <b-tooltip
              :ref="data.value"
              :target="data.value + '-tooltip'"
              triggers="click"
            >
              Copiado!
            </b-tooltip>
          </div>
        </template>

        <template #cell(sli_db_dsn)="data">
          <div
            class="mt-3"
            style="inline-size: 300px; overflow-wrap: break-word"
          >
            {{ data.value }}
          </div>
        </template>

        <template #cell(sli_db_database)="data">
          <div class="mt-3">
            {{ data.value }}
          </div>
        </template>

        <!-- A virtual composite column -->
        <!-- <template #cell(status)="data">
        <div class="camp-center mt-4 ml-4">
          <status-components :ativo="data.value" />
        </div>
      </template> -->

        <template #cell()="row">
          <i>{{ row.value }}</i>
          <b-dropdown
            no-caret
            variant="outline-secondary"
            id="dropdown-dropleft"
            dropleft
            class="border-0"
          >
            <template #button-content>
              <b-icon-three-dots-vertical />
            </template>
            <b-dropdown-item href="#" @click="detalhes(row.item)"
              >Detalhes</b-dropdown-item
            >
            <b-dropdown-item
              v-if="row.item.sistema_obj.sis_visibilidade"
              href="#"
              @click="editar(row.item)"
              >Editar</b-dropdown-item
            >
            <!-- <b-dropdown-item href="#">Desativar</b-dropdown-item> -->
            <ConfirmDialog></ConfirmDialog>
            <b-dropdown-item @click="apagar(row.item)"
              >Remover
            </b-dropdown-item>

            <b-dropdown-item @click="exibirConfiguracoesExtras(row.item)">
              Propriedades
            </b-dropdown-item>

            <b-dropdown-item
              v-if="!['API_E', 'API_G', 'WHK'].includes(row.item.banco_id)"
              @click="testarConexao(row.item)"
            >
              Testar conexão
            </b-dropdown-item>
          </b-dropdown>
        </template>

        <!-- Optional default data cell scoped slot -->
        <!--<template #cell()="data">
        <i>{{ data.value }}</i>
      </template> -->
      </b-table>
      <div class="d-flex justify-content-center">
        <b-pagination
          v-model="paginacao.paginaAtual"
          :total-rows="paginacao.totalItens"
          :per-page="paginacao.porPagina"
          aria-controls="tabelaSistemaLicenciada"
          align="fill"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import ModalDetalhesLicenciada from "./components/ModalDetalhesLicenciada.vue";
import ConfiguracoesExtras from "./components/ConfiguracoesExtras.vue";
import { mapGetters } from "vuex";
import ApiService from "@/core/services/api.service";
import ConfirmDialog from "primevue/confirmdialog";
import Swal from "sweetalert2";
import API_LINKS from "../api.links";
import moment from "moment";
import Toast from "primevue/toast";

export default {
  components: {
    ModalDetalhesLicenciada,
    ConfiguracoesExtras,
    ConfirmDialog,
    Toast
  },
  data() {
    return {
      carregando: true,
      fields: [
        // { key: "status", label: "Status" },
        { key: "sli_titulo", label: "Nome do Sistema" },
        { key: "label_sist_banco", label: "Sistema" },
        { key: "sli_token", label: "sli_token" },
        { key: "sli_db_dsn", label: "Endereço" },
        { key: "sli_db_database", label: "Banco" },
        // A virtual column made up from two fields
        { key: "opcoes", label: "Opções", class: "text-center" }
      ],
      itens: [],
      bancos: [],
      sistema_banco: [],
      itemSelecionado: {},
      paginacao: {
        paginaAtual: 1,
        totalPaginas: 0,
        porPagina: 5,
        totalItens: 0
      }
    };
  },
  methods: {
    detalhes(item) {
      this.itemSelecionado = item;
      this.$modal.show("modal-detalhes-licenciada");
    },
    exibirConfiguracoesExtras(sistema) {
      this.itemSelecionado = sistema;
      this.$modal.show("configuracoes-extra");
    },
    editar(item) {
      this.itemSelecionado = item;
      this.$emit(
        "editarLicenciada",
        this.itemSelecionado.sistema_licenciada_id
      );
    },
    copiar(texto) {
      this.$refs[texto].$emit("open");
      navigator.clipboard.writeText(texto);
      setTimeout(() => {
        this.$refs[texto].$emit("close");
      }, 1000);
    },
    apagar(item) {
      this.itemSelecionado = item;
      Swal.fire({
        title:
          'Você deseja remover o sistema "' +
          this.itemSelecionado.sli_titulo +
          '"?',
        text: "Não será possivel reverter essa ação! Você pode desativar o sistema sem precisar removê-lo.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, quero remover!",
        cancelButtonText: "Cancelar"
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire(
            "Removido",
            "Seu agente foi removido com sucesso!",
            "success"
          );
          ApiService.delete(
            API_LINKS.sistemaLicenciada +
              "/" +
              this.itemSelecionado.sistema_licenciada_id
          ).then(() => {
            this.$emit("atualizarDadosSistema");
            // this.pegarDados();
          });
        }
      });
    },
    async testarConexao(item) {
      this.exibirToast({
        severity: "info",
        summary: "Testando a conexão com o sistema",
        detail:
          "Isso pode demorar uma pouco! Mas logo iremos informar o resultado.",
        life: 5000
      });

      let response = await ApiService.get(
        API_LINKS.sistemaLicenciadaTestarConexao,
        item.sistema_licenciada_id
      );
      let requisicao = response.data.requisicao;
      if (requisicao.sucesso) {
        ApiService.get(
          API_LINKS.sistemaLicenciadaRespostaTesteConexao,
          item.sistema_licenciada_id + "/" + requisicao.uid
        )
          .then((response) => {
            requisicao = response.data.requisicao;
            const payload = requisicao.requisicao.resultado;
            if (requisicao.requisicao.sucesso_requisicao) {
              this.exibirToast({
                severity: "success",
                summary: "Teste de conexão bem sucedido!",
                detail: `Verificamos suas credenciais e tudo está funcionando corretamente. Resultado: ${JSON.stringify(
                  payload
                )}`,
                life: 7000
              });
            } else {
              this.exibirToast({
                severity: "error",
                summary: "Erro no teste de conexão",
                detail: `Verificamos suas credenciais e não foi possível se conectar!. Resultado: ${JSON.stringify(
                  payload
                )}`,
                life: 7000
              });
            }
          })
          .catch((error) => {
            this.exibirToast({
              severity: "error",
              summary: "Erro no teste de conexão",
              detail: "Não foi possível se conectar ao sistema.",
              life: 7000
            });
          });
      } else {
        this.exibirToast({
          severity: "error",
          summary: "Erro no teste de conexão",
          detail: "Não foi possível solicitar o teste de conexão.",
          life: 7000
        });
      }
    },
    pegarDados() {
      const init = async () => {
        this.carregando = true;
        let response = await ApiService.get(API_LINKS.banco, "", false);
        this.bancos = response.data.bancos;

        let response1 = await ApiService.get(API_LINKS.sistema, "", false);
        this.sistema_banco = response1.data.sistemas;

        let response2 = await ApiService.get(
          API_LINKS.sistemaLicenciada,
          "",
          false
        );
        this.itens = response2.data.sistemas;

        this.paginacao.totalItens = response2.data.sistemas.length;
        this.paginacao.totalPaginas = Math.ceil(
          response2.data.sistemas.length / this.paginacao.porPagina
        );

        this.concatenarSistemaBanco();
        this.carregando = false;
      };
      init();
    },
    concatenarSistemaBanco() {
      this.itens.forEach((sis_lic) => {
        sis_lic.sistema_obj = this.sistema_banco.find(
          (sis) => sis.sistema_id == sis_lic.sistema_id
        );
        sis_lic.banco_obj = this.bancos.find(
          (banco) => banco.banco_id.trim() == sis_lic.banco_id.trim()
        );
        sis_lic.label_sist_banco =
          sis_lic.sistema_obj.sis_nome + " - " + sis_lic.banco_obj.bat_nome;
      });
    },
    obterDataFormatada(data) {
      return data
        ? moment(data, "YYYY-MM-DD hh:mm:ss")
            .locale("pt-br")
            .format("DD [de] MMMM[,] YYYY")
        : "-";
    },
    exibirToast(toast) {
      this.$toast.removeAllGroups();
      this.$toast.add(toast);
    }
  },
  mounted() {
    this.pegarDados();
  }
};
</script>
<style>
.camp-center {
  display: flex;
  /* background-color: crimson; */
  width: 50%;
  height: 100%;
}

.botao-opcoes {
  border: none;
  outline: none;
}
</style>
