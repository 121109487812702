<template>
  <div
    class="sidebar-container"
    v-if="isVisible"
    :style="{ overflow: estagioDeOverflowVisible ? 'visible' : 'scroll' }"
  >
    <div class="sidebar-header">
      <h4 v-text="activeNode.label" class="sidebar-title"></h4>
      <button class="tooltip-button" @click="abrirModalInformacoesEstagio">
        <i class="fas fa-info"></i>
      </button>
    </div>
    <div class="fluxo-inputs-container">
      <p class="text-title" style="margin: 2px">
        Quantidade <b>máxima</b> de filhos:
        <strong>{{ activeNode.ces_metadata.qtd_max_filho }}</strong>
      </p>
      <p class="text-title" style="margin: 2px">
        Quantidade <b>mínima</b> de filhos:
        <strong>{{ activeNode.ces_metadata.qtd_min_filho }}</strong>
      </p>
      <hr />
      <div
        v-if="activeNode.chatbot_estagio_id == 10"
        class="tomar-decisao-container"
      >
        <div
          v-for="(child, childIndex) in activeNode.ces_metadata.campos"
          :key="'child-' + childIndex"
          class="tomar-decisao-item"
        >
          <template v-if="childrens[childIndex]">
            <h6
              v-if="childrens[childIndex]"
              v-text="`${childrens[childIndex].label}`"
            ></h6>
            <div
              v-for="(campo, key) in activeNode.ces_metadata.campos[childIndex]"
              :key="'campo-' + childIndex + '-' + key"
              class="mt-2"
            >
              <template
                v-if="
                  !modoAvancadoTomarDecisao(
                    activeNode.ces_metadata.campos[childIndex]
                  )
                "
              >
                <p
                  class="text-title"
                  v-text="campo.label"
                  v-if="
                    ![
                      'cec_ordem',
                      'cec_condicao',
                      'para_chatbot_fluxo_estagio_id'
                    ].includes(campo.bindText) && campo.type !== 'bool'
                  "
                ></p>

                <b-form-input
                  v-if="
                    campo.type === 'text' &&
                    ![
                      'cec_ordem',
                      'cec_condicao',
                      'para_chatbot_fluxo_estagio_id'
                    ].includes(campo.bindText) &&
                    !modoAvancadoTomarDecisao(
                      activeNode.ces_metadata.campos[childIndex]
                    )
                  "
                  type="text"
                  v-model="campo.value"
                ></b-form-input>

                <b-form-select
                  v-if="
                    campo.type === 'select' &&
                    ![
                      'cec_ordem',
                      'cec_condicao',
                      'para_chatbot_fluxo_estagio_id'
                    ].includes(campo.bindText)
                  "
                  class="w-100"
                  :name="campo.bindText"
                  :options="optionsDoCampo(campo.bindText)"
                  v-model="campo.value"
                ></b-form-select>
              </template>
              <b-form-textarea
                v-if="
                  campo.bindText == 'cec_condicao' &&
                  modoAvancadoTomarDecisao(
                    activeNode.ces_metadata.campos[childIndex]
                  )
                "
                :rows="3"
                type="text"
                v-model="campo.value"
              ></b-form-textarea>

              <ToggleButton
                v-if="
                  campo.type === 'bool' &&
                  ![
                    'cec_ordem',
                    'cec_condicao',
                    'para_chatbot_fluxo_estagio_id'
                  ].includes(campo.bindText)
                "
                :onLabel="campo.onLabel || 'Modo Avançado'"
                :offLabel="campo.offLabel"
                v-model="campo.value"
                class="w-100 text-uppercase"
              />
            </div>
            <hr
          /></template>
        </div>
      </div>

      <div v-else>
        <div
          v-for="(campo, key) in computedCampos"
          :key="'campo' + key"
          class="mt-3"
        >
          <template
            v-if="
              !(campo.bindText == 'cfe_time' && encaminharParaAgendaWellonChat)
            "
          >
            <div class="title-container">
              <p
                class="text-title"
                style="margin: 2px"
                v-if="![25].includes(activeNode.chatbot_estagio_id)"
              >
                {{ campo.label }}
              </p>
              <div v-if="campoDeVariavel(campo)" class="tooltip-container">
                <i
                  v-if="campoDeVariavel(campo)"
                  class="fa fa-question-circle"
                ></i>
                <div class="tooltip-mensagem">
                  <p class="text-title" style="margin: 2px">
                    O parâmetro será responsável por armazenar a mensagem ou
                    arquivo que será enviado pelo usuário.
                  </p>
                </div>
              </div>
            </div>
            <b-form-input
              v-if="campo.type === 'text'"
              type="text"
              v-model="campo.value"
            ></b-form-input>

            <b-form-textarea
              v-else-if="campo.type === 'textArea'"
              v-model="campo.value"
              :rows="5"
            ></b-form-textarea>

            <div class="d-flex align-items-center">
              <b-form-select
                v-if="campo.type === 'select'"
                class="w-100"
                :name="campo.bindText"
                :options="optionsDoCampo(campo.bindText)"
                @change="onChangeMainChatbotSelect(campo, $event)"
                v-model="campo.value"
              ></b-form-select>
              <i
                v-if="
                  activeNode.ces_estagio == 'ESTAGIO_REDIRECIONAR_FLUXO' &&
                  campo.value &&
                  campo.bindText == 'cfe_atributo'
                "
                class="fas fa-external-link-alt ml-2 d-flex align-items-center justify-content-center"
                style="
                  font-size: 18px;
                  cursor: pointer;
                  border: 1px solid #e4e6ef;
                  padding: 4px;
                  border-radius: 4px;
                  width: 38px;
                  height: 38px;
                "
                v-b-tooltip.hover
                title="Mostrar fluxo em outra janela"
                @click="abrirFluxoEmNovaAba(campo.value)"
              ></i>
            </div>

            <MultiSelect
              v-if="
                campo.type === 'multiSelect' &&
                activeNode.chatbot_estagio_id == 17
              "
              class="col w-100"
              v-model="campo.value"
              :options="agendasMultiSelect"
              optionLabel="text"
              placeholder="Parametros"
              :filter="true"
              size="lg"
            />

            <MultiSelect
              v-if="
                campo.type === 'multiSelect' &&
                activeNode.chatbot_estagio_id != 17
              "
              class="col w-100"
              v-model="campo.value"
              :options="variaveisDeclaradas"
              optionLabel="text"
              placeholder="Parametros"
              :filter="true"
              size="lg"
            />

            <!-- arquivo -->
            <b-form-file
              v-if="campo.type === 'file'"
              :state="Boolean(campo.value)"
              :placeholder="campo.value ? campo.value : 'Selecione um arquivo'"
              accept="*"
              @change="onChangeFile(campo, $event)"
              browse-text="Selecionar"
            >
              ></b-form-file
            >
            <b-button
              v-if="campo.type === 'file'"
              variant="outline-primary"
              class="mt-2 w-100"
              @click="showFilePreview"
            >
              {{
                arquivoASerEnviado
                  ? "Visualizar arquivo"
                  : "Nenhum arquivo selecionado"
              }}
            </b-button>

            <ToggleButton
              v-if="campo.type === 'bool'"
              :onLabel="campo.onLabel"
              :offLabel="campo.offLabel"
              v-model="campo.value"
              class="w-100 text-uppercase"
            />
          </template>
        </div>
        <template v-if="activeNode.chatbot_estagio_id == 25">
          <ul>
            <li
              v-for="(atributo, idx) in activeNode.ces_metadata.campos.find(
                (campo) => campo.bindText == 'atributos_customizaveis'
              ).value"
              :key="'atributo-' + idx"
              style="
                border: 1px solid #ccc;
                margin-top: 8px;
                padding: 8px;
                border-radius: 8px;
                list-style: none;
              "
            >
              <div class="title-container">
                <p class="text-title" style="margin: 2px">Atributo</p>
              </div>
              <b-form-select
                class="w-100"
                :name="'selecionado-' + idx + atributo.atributo_customizavel"
                :options="atributoCustomizavelOptions"
                @change="onChangeAtributoSelecionado(atributo, $event)"
                v-model="atributo.atributo_customizavel"
              ></b-form-select>

              <div class="title-container mt-3">
                <p class="text-title" style="margin: 1px">Valor</p>
              </div>

              <b-form-input
                v-if="atributo.tipo === 'text'"
                type="text"
                v-model="atributo.valor"
              ></b-form-input>

              <b-form-select
                v-if="atributo.tipo === 'select'"
                class="w-100"
                :name="'valor-' + idx + atributo.atributo_customizavel"
                :options="optionsAtributoCustomizavelSelecionado(atributo)"
                v-model="atributo.valor"
              ></b-form-select>

              <b-button
                variant="outline-danger"
                class="mt-2 w-100"
                @click="
                  activeNode.ces_metadata.campos
                    .find(
                      (campo) => campo.bindText == 'atributos_customizaveis'
                    )
                    .value.splice(idx, 1)
                "
              >
                <i class="fas fa-trash"></i>
                Remover
              </b-button>
            </li>
            <b-button
              variant="success"
              class="mt-4 w-100"
              @click="
                activeNode.ces_metadata.campos
                  .find((campo) => campo.bindText == 'atributos_customizaveis')
                  .value.push({
                    atributo_customizavel: '',
                    valor: '',
                    tipo: ''
                  })
              "
              style="box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px"
            >
              <i class="fas fa-plus"></i>
              Novo Atributo
            </b-button>
          </ul>
        </template>

        <template v-if="activeNode.chatbot_estagio_id == 21">
          <draggable
            class="list-group"
            tag="ul"
            v-model="
              activeNode.ces_metadata.campos.find(
                (campo) => campo.bindText == 'menu_conteudo'
              ).value
            "
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            v-if="
              activeNode.ces_metadata.campos.find(
                (campo) => campo.bindText == 'menu_tp'
              ).value == 'E'
            "
          >
            <transition-group type="transition" name="flip-list">
              <li
                class="list-group-item"
                v-for="(submenu, idx) in activeNode.ces_metadata.campos.find(
                  (campo) => campo.bindText == 'menu_conteudo'
                ).value"
                :key="'submenu-' + idx"
                style="
                  border: 1px solid #ccc;
                  border-radius: 8px;
                  margin-top: 8px;
                  padding: 8px;
                "
              >
                <div class="title-container">
                  <p class="text-title" style="margin: 2px">Texto:</p>
                </div>
                <b-form-textarea
                  v-model="submenu.texto"
                  :rows="3"
                ></b-form-textarea>
                <div class="title-container">
                  <p class="text-title" style="margin: 2px">Valor:</p>
                </div>
                <b-form-input
                  type="text"
                  v-model="submenu.valor"
                ></b-form-input>
                <div class="title-container">
                  <p class="text-title" style="margin: 2px">Palavras Chave:</p>
                </div>
                <Chips
                  v-model="submenu.palavras_chave"
                  separator=","
                  class="w-100"
                >
                  <template #chip="slotProps">
                    <div>
                      <span>{{ slotProps.value }}</span>
                    </div>
                  </template></Chips
                >
                <b-button
                  variant="outline-danger"
                  class="mt-2 w-100"
                  @click="
                    activeNode.ces_metadata.campos
                      .find((campo) => campo.bindText == 'menu_conteudo')
                      .value.splice(idx, 1)
                  "
                >
                  <i class="fas fa-trash"></i>
                  Remover
                </b-button>
              </li>
            </transition-group>
          </draggable>
          <template
            v-if="
              activeNode.chatbot_estagio_id == 21 &&
              activeNode.ces_metadata.campos.find(
                (campo) => campo.bindText == 'menu_tp'
              ).value == 'D'
            "
          >
            <transition-group type="transition" name="flip-list">
              <li
                class="list-group-item"
                v-for="(submenu, idx) in activeNode.ces_metadata.campos.find(
                  (campo) => campo.bindText == 'menu_conteudo'
                ).value"
                :key="'submenu-' + idx"
                style="
                  border: 1px solid #ccc;
                  border-radius: 8px;
                  margin-top: 8px;
                  padding: 8px;
                "
              >
                <div class="title-container">
                  <p class="text-title" style="margin: 2px">Texto:</p>
                </div>
                <b-form-textarea
                  v-model="submenu.texto"
                  :rows="3"
                ></b-form-textarea>
                <div class="title-container">
                  <p class="text-title" style="margin: 2px">Variável:</p>
                </div>
                <b-form-input
                  type="text"
                  v-model="submenu.variavel"
                ></b-form-input>
                <div class="title-container">
                  <p class="text-title" style="margin: 2px">Coluna:</p>
                </div>
                <b-form-input
                  type="text"
                  v-model="submenu.coluna"
                ></b-form-input>
                <div class="title-container">
                  <p class="text-title" style="margin: 2px">
                    Utilizar Item Extra:
                  </p>
                </div>

                <ToggleButton
                  onLabel="Utilizar"
                  offLabel="Não Utilizar"
                  v-model="submenu.utilizar_item_extra"
                  class="w-100 text-uppercase"
                />

                <div
                  style="
                    border: 1px solid #ccc;
                    border-radius: 8px;
                    margin-top: 8px;
                    padding: 8px;
                  "
                  v-if="submenu.utilizar_item_extra"
                >
                  <div class="title-container">
                    <p class="text-title" style="margin: 2px">Posição:</p>
                  </div>
                  <b-form-select
                    class="w-100"
                    v-model="submenu.item_extra['0'].posicao"
                  >
                    <b-form-select-option value="inicio">
                      Início
                    </b-form-select-option>
                    <b-form-select-option value="fim">Fim</b-form-select-option>
                  </b-form-select>

                  <div class="title-container">
                    <p class="text-title" style="margin: 2px">Texto:</p>
                  </div>
                  <b-form-textarea
                    v-model="submenu.item_extra['0'].texto"
                    :rows="3"
                  ></b-form-textarea>
                  <div class="title-container">
                    <p class="text-title" style="margin: 2px">Valor:</p>
                  </div>
                  <b-form-input
                    type="text"
                    v-model="submenu.item_extra['0'].valor"
                  ></b-form-input>
                  <div class="title-container">
                    <p class="text-title" style="margin: 2px">
                      Palavras Chave:
                    </p>
                  </div>
                  <Chips
                    v-model="submenu.item_extra['0'].palavras_chave"
                    separator=","
                    class="w-100"
                  >
                    <template #chip="slotProps">
                      <div>
                        <span>{{ slotProps.value }}</span>
                      </div>
                    </template></Chips
                  >
                </div>
              </li>
            </transition-group>
          </template>

          <b-button
            variant="success"
            class="mt-4 w-100"
            @click="adicionarNovoItemDeSubmenu"
            style="box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px"
            v-if="
              activeNode.ces_metadata.campos.find(
                (campo) => campo.bindText == 'menu_tp'
              ).value == 'E'
            "
          >
            <i class="fas fa-plus"></i>
            Novo Menu
          </b-button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import API_LINKS from "../../api.links";
import MultiSelect from "primevue/multiselect";
import ToggleButton from "primevue/togglebutton";
import Chips from "primevue/chips";
import draggable from "vuedraggable";

export default {
  components: {
    MultiSelect,
    ToggleButton,
    Chips,
    draggable
  },
  props: {
    isVisible: {
      default: false
    },
    activeNode: {
      required: true
    },
    sistemas: {
      required: true
    },
    agendas: {
      required: true
    },
    modelos: {
      required: true
    },
    childrens: {
      required: true
    },
    opcoesFormatacaoEntrada: {
      required: true
    },
    times: {
      required: true
    },
    atributos_customizaveis: {
      required: false,
      default: () => []
    },
    estagiosPai: {
      required: true
    },
    variaveisDeclaradas: {
      required: true
    },
    modeloLinks: {
      required: true
    },
    chatbotCallbacks: {
      required: true
    },
    fluxosDeChatbot: {
      required: true
    },
    prioridades: {
      required: true
    },
    tipo_reagendamento: {
      required: true
    },
    tipo_req_http: {
      required: true
    },
    tipo_arquivo: {
      required: true
    },
    select_orientacao: {
      required: true
    }
  },
  watch: {
    childrens: {
      handler: function (newVal, oldVal) {},
      deep: true
    },
    activeNode: {
      handler: function (newVal, oldVal) {
        // setTimeout(() => {
        // Esse if verifica se é o estágio de tomar decisão para atribuir ao campo "Ordem de verificação dessa condição" o valor do child que já está com o título anexado
        if (newVal.chatbot_estagio_id == 10) {
          this.childrens.forEach((child, i) => {
            if (this.activeNode.ces_metadata.campos[i]) {
              const campoDeCecOrdem = this.activeNode.ces_metadata.campos[
                i
              ].find((cmp) => cmp.bindText === "cec_ordem");

              const campoDeDirecionamentoParaChatbotFluxoEstagioId =
                this.activeNode.ces_metadata.campos[i].find(
                  (cmp) => cmp.bindText === "para_chatbot_fluxo_estagio_id"
                );

              if (child) {
                if (campoDeCecOrdem) {
                  campoDeCecOrdem.value = child.id;
                }

                if (campoDeDirecionamentoParaChatbotFluxoEstagioId) {
                  campoDeDirecionamentoParaChatbotFluxoEstagioId.value =
                    child.id;
                }
              }

              const condicaoCampos = this.activeNode.ces_metadata.campos[i];
              const cecCondiciao = condicaoCampos.find(
                (cmp) => cmp.bindText === "cec_condicao"
              );
              const cecParametro = condicaoCampos.find(
                (cmp) => cmp.bindText === "cec_parametro"
              );
              const cecOperador = condicaoCampos.find(
                (cmp) => cmp.bindText === "cec_operador"
              );
              const cecValor = condicaoCampos.find(
                (cmp) => cmp.bindText === "cec_valor"
              );
              const cecAvancado = condicaoCampos.find(
                (cmp) => cmp.bindText === "cec_avancado"
              );
              if (!cecAvancado.value) {
                let stringAvancada = `{${cecParametro.value}} ${cecOperador.value} ${cecValor.value}`;
                cecCondiciao.value = stringAvancada;
              }
              // console.log(condicao);
            }
          });
        }
        // }, 100);

        // arquivoASerEnviado
        // verifica se no campos[i] do ces_metadata tem o arquivo para enviar no value
        // se tiver, ele seta o arquivoASerEnviado com o value do campo
        if (newVal.chatbot_estagio_id == 1) {
          this.activeNode.ces_metadata.campos.forEach((campo, i) => {
            if (campo.type == "file" && campo.value != null) {
              this.arquivoASerEnviado = campo.value;
            } else if (campo.type == "file") {
              this.arquivoASerEnviado = null;
              campo.value = null;
            }
          });
        }

        //se for o estágio consultar agenda
        if (newVal.chatbot_estagio_id == 17) {
          this.activeNode.ces_metadata.campos
            .filter(
              (campo) => campo.type == "multiSelect" && campo.value != null
            )
            .map((campo) => {
              campo.value.map((value, key) => {
                this.agendas.map((agenda) => {
                  if (agenda.id == value.value) {
                    campo.value[key] = {
                      text: agenda.nome,
                      value: agenda.id
                    };
                  }
                });
              });
            });
        }
      }
    }
  },
  data() {
    return {
      drag: false,
      camposAgendaHardCodedNathanSolutions: [
        { id: 0, text: "Encaminhar para agendamentos", value: 0 },
        { id: 1, text: "Encaminhar para o Atende", value: 1 }
      ],
      mostrarTooltip: false,
      arquivoASerEnviado: null,
      varianvelSelecionada: null,
      operadores: [
        {
          text: "Igual",
          value: "==="
        },
        {
          text: "Diferente",
          value: "!=="
        },
        {
          text: "Maior",
          value: ">"
        },
        {
          text: "Maior ou igual",
          value: ">="
        },
        {
          text: "Menor",
          value: "<"
        },
        {
          text: "Menor ou igual",
          value: "<="
        }
      ],
      opcoes_status_conversa_atende: [
        {
          text: "Aberta",
          value: "open"
        },
        {
          text: "Resolvida",
          value: "resolved"
        },
        {
          text: "Pendente",
          value: "pending"
        }
      ]
    };
  },
  methods: {
    onChangeAtributoSelecionado(atributo) {
      const atributo_selecionado = this.atributoCustomizavelOptions.find(
        (c_att) => c_att.value == atributo.atributo_customizavel
      );

      atributo.valor = "";
      if (atributo_selecionado.attribute_display_type == "list") {
        atributo.tipo = "select";
      } else {
        atributo.tipo = "text";
      }
      this.$forceUpdate();
    },
    optionsAtributoCustomizavelSelecionado(atributo) {
      const atributo_selecionado = this.atributoCustomizavelOptions.find(
        (c_atributo) => c_atributo.value == atributo.atributo_customizavel
      );
      if (atributo_selecionado.attribute_display_type == "list") {
        return atributo_selecionado.attribute_values.map((valor) => {
          return {
            value: valor,
            text: valor
          };
        });
      } else {
        return [];
      }
    },
    onChangeMainChatbotSelect(campo) {
      if (campo.bindText == "cfe_atributo_customizavel") {
        const selected_custom_attribute = this.atributos_customizaveis.find(
          (atributo) => atributo.attribute_key == campo.value
        );
        if (selected_custom_attribute) {
          const cfe_atributo_customizavel_valor =
            this.activeNode.ces_metadata.campos.find(
              (campo) => campo.bindText == "cfe_atributo_customizavel_valor"
            );
          if (selected_custom_attribute.attribute_display_type != "list") {
            cfe_atributo_customizavel_valor.type = "text";
          } else {
            cfe_atributo_customizavel_valor.type = "select";
          }
        }
      }
      if (
        this.activeNode.chatbot_estagio_id == 21 &&
        campo.bindText == "menu_tp"
      ) {
        this.activeNode.ces_metadata.campos.find(
          (campo) => campo.bindText == "menu_conteudo"
        ).value = [];
        this.adicionarNovoItemDeSubmenu();
      }
    },
    adicionarNovoItemDeSubmenu() {
      const novo_item = {
        texto: "",
        valor: "",
        palavras_chave: [],
        variavel: "",
        coluna: "",
        utilizar_item_extra: false,
        item_extra: [
          {
            posicao: "inicio",
            texto: "",
            variavel: "",
            coluna: "",
            palavras_chave: []
          }
        ]
      };
      const campos_de_itens_de_menu = this.activeNode.ces_metadata.campos.find(
        (campo) => campo.bindText == "menu_conteudo"
      ).value;
      campos_de_itens_de_menu.push(novo_item);
    },
    modoAvancadoTomarDecisao(campoTomarDecisao) {
      const boolAvancado = campoTomarDecisao.find(
        (campo) => campo.bindText === "cec_avancado"
      );
      return boolAvancado.value;
    },
    campoDeVariavel(campo) {
      if (["cfe_variavel"].includes(campo.bindText)) {
        return true;
      }
      return false;
    },
    abrirModalInformacoesEstagio() {
      // em construção
      this.$emit("abrirModalInformacoesEstagio");
    },
    showFilePreview() {
      if (this.arquivoASerEnviado) {
        window.open(this.arquivoASerEnviado);
      }
    },
    onChangeFile(campo, e) {
      const file = e.target.files;

      let reader = new FileReader();
      reader.onload = (e2) => {
        this.arquivoASerEnviado = e2.target.result;
      };
      reader.readAsDataURL(file[0]);
      setTimeout(() => {
        ApiService.post(`${API_LINKS.contasAtivas}/upload-arquivo`, {
          arquivo: this.arquivoASerEnviado,
          caminho: "modelo-campanha/"
        }).then((res) => {
          campo.value = res.data.url;
          this.arquivoASerEnviado = res.data.url;
        });
      }, 200);
    },
    optionsDoCampo(campoBind) {
      if (this.activeNode.chatbot_estagio_id == 17 && campoBind == "cfe_time") {
        return this.times;
      } else if (this.activeNode.chatbot_estagio_id == 20) {
        if (campoBind == "cfe_time") {
          return this.times;
        } else if (campoBind == "cfe_atributo") {
          return this.variaveisDeclaradas.map((variavel) => {
            return {
              text: variavel.text,
              value: variavel.text
            };
          });
        } else if (campoBind == "tipo_agenda") {
          return this.camposAgendaHardCodedNathanSolutions;
        }
      } else if (this.activeNode.chatbot_estagio_id == 10) {
        if (campoBind == "cec_parametro") {
          return this.variaveisDeclaradas.map((variavel) => {
            return {
              text: variavel.text,
              value: variavel.text
            };
          });
        } else if (campoBind == "cec_operador") {
          return this.operadores;
        }
      } else if (
        this.activeNode.chatbot_estagio_id == 3 &&
        campoBind == "chatbot_campanha_id"
      ) {
        const modelosFiltrados = this.modelos
          .filter((modeloFiltradoPeloSistema) => {
            const sistemaLicenciadaIdSelecionada =
              this.activeNode.ces_metadata.campos[0].find(
                (v) => v.bindText == "sistema_licenciada_id"
              ).value;

            if (sistemaLicenciadaIdSelecionada) {
              const sistemaFiltradosPelaLicenciada = this.sistemas.find(
                (sistema) =>
                  sistema.sistema_licenciada_id ==
                  sistemaLicenciadaIdSelecionada
              );
              if (sistemaFiltradosPelaLicenciada) {
                return (
                  modeloFiltradoPeloSistema.sistema_banco_id ==
                  sistemaFiltradosPelaLicenciada.sistema_banco_id
                );
              }
              return modeloFiltradoPeloSistema;
            }
          })
          .map((modelo) => {
            return {
              value: modelo.chatbot_campanha_id,
              text: modelo.cca_nome
            };
          });

        if (modelosFiltrados.length > 0) {
          return modelosFiltrados;
        } else {
          return [
            {
              value: null,
              text: "Nenhum modelo encontrado"
            }
          ];
        }
      } else if (campoBind == "para_chatbot_fluxo_estagio_id") {
        if (this.activeNode.chatbot_estagio_id == 11) {
          // listar estagiosPai
          return this.estagiosPai.map((estagio) => {
            return {
              value: estagio.id,
              text: estagio.label
            };
          });
        } else if (this.childrens.length > 0) {
          return this.childrens.map((child) => {
            return {
              value: child.id,
              text: child.label
            };
          });
        }
      } else if (campoBind == "tipo_arquivo") {
        return this.tipo_arquivo;
      } else if (campoBind == "select_orientacao") {
        return this.select_orientacao;
      } else if (campoBind == "cfe_req_http") {
        return this.tipo_req_http;
      } else if (campoBind == "cfe_time") {
        return this.times;
      } else if (campoBind == "sistema_licenciada_id") {
        return this.sistemas.map((sistema) => {
          return {
            value: sistema.sistema_licenciada_id,
            text: sistema.sli_titulo
          };
        });
      } else if (campoBind == "chatbot_campanha_id") {
        return this.modelos.map((modelo) => {
          return {
            value: modelo.modelo_id,
            text: modelo.md_descricao
          };
        });
      } else if (campoBind == "chatbot_formatacao_entrada_id") {
        return this.opcoesFormatacaoEntrada.map((opcao) => {
          return {
            value: opcao.chatbot_formatacao_entrada_id,
            text: opcao.cfe_descricao
          };
        });
      } else if (this.activeNode.chatbot_estagio_id == 24) {
        if (campoBind == "tipo_reagendamento") {
          return this.tipo_reagendamento;
        } else if (campoBind == "cfe_time") {
          return this.times;
        }
      } else if (this.activeNode.chatbot_estagio_id == 5) {
        if (campoBind == "cfe_time") {
          return this.times;
        } else if (campoBind == "cfe_prioridade") {
          return this.prioridades;
        } else if (campoBind == "cfe_atributo_customizavel") {
          return this.atributoCustomizavelOptions;
        } else if (campoBind == "cfe_atributo_customizavel_valor") {
          const selected_custom_attribute_attribute_key =
            this.activeNode.ces_metadata.campos.find(
              (campo) => campo.bindText == "cfe_atributo_customizavel"
            )?.value;
          const selected_custom_attribute = this.atributos_customizaveis.find(
            (atributo) =>
              atributo.attribute_key == selected_custom_attribute_attribute_key
          );
          if (selected_custom_attribute?.attribute_display_type == "list") {
            return selected_custom_attribute.attribute_values.map((valor) => {
              return {
                value: valor,
                text: valor
              };
            });
          } else {
            const custom_att_value = this.activeNode.ces_metadata.campos.find(
              (campo) => campo.bindText == "cfe_atributo_customizavel_valor"
            );
            custom_att_value.type = "text";
          }
          return [];
        }
      } else if (campoBind == "chatbot_agenda_id") {
        return this.agendas.map((agenda) => {
          return {
            value: agenda.id,
            text: agenda.nome
          };
        });
      } else if (campoBind == "modelo_link_id") {
        return this.modeloLinks.map((link) => {
          return {
            value: link.modelo_link_id,
            text: link.sic_descricao
          };
        });
      } else if (campoBind == "callback_comando_id") {
        const valorSistemaLicenciadaId =
          this.activeNode.ces_metadata.campos[0].find(
            (v) => v.bindText == "sistema_licenciada_id"
          )?.value;

        const sistemaBancoIdBaseadoNaLicenciada = this.sistemas.find(
          (sistema) => sistema.sistema_licenciada_id == valorSistemaLicenciadaId
        )?.sistema_banco_id;

        return this.chatbotCallbacks
          .filter((callback) => {
            return (
              callback.sistema_banco_id == sistemaBancoIdBaseadoNaLicenciada
            );
          })
          .map((callback) => {
            return {
              value: callback.callback_comando_id,
              text: callback.cal_nome
            };
          });
      } else if (campoBind == "menu_tp") {
        return [
          {
            value: "E",
            text: "Estático"
          },
          {
            value: "D",
            text: "Dinâmico"
          }
        ];
      } else if (campoBind == "qtd_iteracao") {
        return [
          {
            value: 0,
            text: "Sem Tratamento"
          },
          {
            value: 1,
            text: "1"
          },
          {
            value: 2,
            text: "2"
          },
          {
            value: 3,
            text: "3"
          },
          {
            value: 4,
            text: "4"
          },
          {
            value: 5,
            text: "5"
          },
          {
            value: 6,
            text: "6"
          },
          {
            value: 7,
            text: "7"
          },
          {
            value: 8,
            text: "8"
          },
          {
            value: 9,
            text: "9"
          },
          {
            value: 10,
            text: "10"
          }
        ];
      } else if (
        (this.activeNode.chatbot_estagio_id == 22 &&
          campoBind == "cfe_atributo") ||
        campoBind == "chatbot_fluxo_id"
      ) {
        return this.fluxosDeChatbot.map((fluxo) => {
          return {
            value: fluxo.chatbot_fluxo_id,
            text: fluxo.cfl_titulo
          };
        });
      } else if (
        this.activeNode.chatbot_estagio_id == 9 &&
        campoBind == "cfe_redirect"
      ) {
        return this.fluxosDeChatbot.map((fluxo) => {
          return {
            value: fluxo.chatbot_fluxo_id,
            text: fluxo.cfl_titulo
          };
        });
      } else if (this.activeNode.chatbot_estagio_id == 23) {
        if (campoBind == "cfe_inteligencia_artificial") {
          return [
            {
              value: 0,
              text: "Não"
            },
            {
              value: 1,
              text: "Sim"
            }
          ];
        }
      } else if (this.activeNode.chatbot_estagio_id == 4) {
        if (campoBind == "cfe_atributo") {
          return this.opcoes_status_conversa_atende;
        }
      }
    },
    abrirFluxoEmNovaAba(fluxoId) {
      const routeData = this.$router.resolve({
        name: "automacao.fluxo.editar",
        query: {
          fluxo_id: fluxoId
        }
      });
      window.open(routeData.href, "_blank");
    }
  },
  computed: {
    atributoCustomizavelOptions() {
      return this.atributos_customizaveis?.filter(
        (conversation) =>
          conversation.attribute_model == "conversation_attribute"
      );
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    estagioDeOverflowVisible() {
      if (!this.activeNode) return false;
      return [17].includes(this.activeNode.chatbot_estagio_id);
    },
    agendasMultiSelect() {
      return this.agendas.map((agenda) => {
        return {
          value: agenda.id,
          text: agenda.nome
        };
      });
    },
    mostrarTooltipAtivo() {
      return this.mostrarTooltip;
    },
    computedCampos() {
      // Verify if it's an array of arrays
      if (this.activeNode.ces_metadata.campos[0] instanceof Array) {
        return this.activeNode.ces_metadata.campos[0];
      }
      return this.activeNode.ces_metadata.campos;
    },
    encaminharParaAgendaWellonChat() {
      if (this.activeNode.chatbot_estagio_id != 20) return false;
      const wellonChatSelecionado = this.activeNode.ces_metadata.campos.find(
        (v) => v.bindText == "tipo_agenda"
      );
      return wellonChatSelecionado?.value == 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.sidebar-container {
  width: 333px;
  padding: 16px;
  height: 75vh;
  position: fixed;
  right: 0;
  bottom: 0;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-top-left-radius: 24px;
  z-index: 99;
  overflow: scroll;
  .title-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
    margin-bottom: 4px;
  }
  .tooltip-container {
    position: relative;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    i {
      color: #ff8c00;
    }
    .tooltip-mensagem {
      position: absolute;
      top: -50%;
      /* left: 0; */
      transform: translateX(-105%);
      width: 250px;

      background: rgba(0, 0, 0, 0.8);
      color: #fff;
      padding: 8px;
      border-radius: 4px;
      font-size: 12px;
      font-weight: 500;
      z-index: 999;
      visibility: hidden;
      opacity: 0;
      transition: all 0.5s ease-in-out;

      /* transition not working */
    }
    &:hover .tooltip-mensagem {
      transition: all 0.5s ease-in-out;
      visibility: visible;
      opacity: 1;
    }
  }

  .sidebar-header {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    align-items: center;
    margin-bottom: 12px;

    .sidebar-title {
      font-size: 20px;
      font-weight: 500;
      color: #ff8c00;
      margin: 0;
    }
    .tooltip-button {
      min-width: 20px;
      min-height: 20px;
      border-radius: 50%;
      /* background: #ff8c00;
       */
      /* only the border orange and background transparent */
      border: 1px solid #ff8c00;
      background: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      i {
        color: #ff8c00 !important;
        font-size: 12px;
      }
      /* animation to make button perceivable */
      animation: pulse 2s infinite;
      font-weight: 500;
      /* At first this button should shine */
    }
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 0.7);
    }
    70% {
      transform: scale(1.2);
      box-shadow: 0 0 0 10px rgba(255, 140, 0, 0);
    }
    100% {
      transform: scale(1);
      box-shadow: 0 0 0 0 rgba(255, 140, 0, 0);
    }
  }
  .tomar-decisao-container {
    /* make the last hr of the last child invisible */
    .tomar-decisao-item {
      &:last-child {
        hr {
          display: none;
        }
      }
    }
  }
}
.p-togglebutton.p-button {
  background: #e4e6ef;
  border: 1px solid #ced4da;
  color: #495057;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
  text-transform: uppercase;
}

.p-togglebutton.p-button.p-highlight {
  background: #1bc5bd;
  border-color: #1bc5bd;
  color: #ffffff;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s,
    box-shadow 0.2s;
}
::v-deep .p-chips .p-chips-multiple-container {
  width: 100% !important;
}
::v-deep .p-chips-multiple-container {
  flex-wrap: wrap !important;
  gap: 0.5rem !important;
}
.flip-list-move {
  transition: transform 0.75s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #e1fcb6;
}
.list-group-item {
  cursor: grab;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
</style>
