<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-forgot-on"
    >
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
              id="kt_validar_2fa_form"
              ref="kt_validar_2fa_form"
            >
              <div class="pb-3 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                  style="color: #482182 !important; font-weight: 500 !important"
                >
                  Autenticação de dois fatores
                </h3>
                <p
                  class="text-muted font-weight-bold"
                  style="
                    font-size: 1.1rem;
                    color: #a7a7a7 !important;
                    font-weight: 500 !important;
                  "
                >
                  Digite abaixo o código que enviamos para seu telefone e/ou
                  email.
                </p>
              </div>

              <FAInput @codigo:atualizado="atualizarCodigo2FA" @enter="enter" />

              <div class="d-flex flex-column">
                <div class="d-flex w-100">
                  <div class="w-100 mr-2">
                    <button
                      type="button"
                      id="kt_login_validar2fa_reenviar"
                      ref="kt_login_validar2fa_reenviar"
                      class="btn btn-cancel btn-outline-primary font-size-h6 px-8 py-3 w-100 my-2 mr-4 btn-block font-weight-bold"
                      style="border: 1px solid #a7a7a7; color: #482182"
                      @click.prevent="reenviar2FA"
                    >
                      Reenviar
                    </button>
                  </div>

                  <div class="w-100">
                    <button
                      type="submit"
                      id="kt_login_validar2fa_submit"
                      ref="kt_login_validar2fa_submit"
                      class="btn btn-primary font-weight-bold font-size-h6 px-8 py-3 w-100 my-2 btn-block"
                      style="
                        background-color: #482182;
                        border: 1px solid #482182;
                      "
                    >
                      Confirmar
                    </button>
                  </div>
                </div>
                <p class="text-muted">
                  Este site é protegido pelo reCAPTCHA e a
                  <a
                    href="https://policies.google.com/privacy"
                    class="font-weight-bolder"
                    style="color: #482182"
                    >Política de Privacidade</a
                  >
                  e os
                  <a
                    href="https://policies.google.com/terms"
                    class="font-weight-bolder"
                    style="color: #482182"
                    >Termos de Serviço</a
                  >
                  do Google se aplicam.
                </p>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
      </div>
      <!--end::Content-->
      <!--begin::Aside-->
      <div
        class="login-aside diagonal-aside d-flex flex-column flex-row-auto justify-content-center"
        style="background-color: #ff7900"
      >
        <a href="#" class="text-center">
          <img
            src="media/comunicante/logo-wellon-login.png"
            class="img-login1"
            style="height: 85vh"
            alt=""
          />
        </a>
      </div>
      <!--begin::Aside-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters } from "vuex";
import { VALIDAR_2FA, REENVIAR_2FA } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

import FAInput from "./2FAInput";

export default {
  name: "autenticaca-dois-fatores",

  components: {
    FAInput
  },

  data() {
    return {
      form: {
        codigo: "",
        token: ""
      }
    };
  },
  computed: {
    ...mapGetters(["validatorErrors"]),

    backgroundImage() {
      return process.env.BASE_URL + "media/comunicante/bg-login.svg";
    }
  },
  mounted() {
    const validar2fa_form = KTUtil.getById("kt_validar_2fa_form");

    this.fv1 = formValidation(validar2fa_form, {
      fields: {
        codigo: {
          validators: {
            notEmpty: {
              message: "Campo obrigatório."
            },
            stringLength: {
              min: 6,
              max: 6,
              message: "Informe o código com 6 dígitos."
            },
            blank: {}
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv1.on("core.form.valid", async () => {
      const submitButton = this.$refs["kt_login_validar2fa_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      await this.$recaptchaLoaded();
      this.form.token = await this.$recaptcha("login");

      this.$store
        .dispatch(VALIDAR_2FA, this.form)
        .then(() => {
          this.$router.push("/");
        })
        .catch(() => {
          for (const error in this.validatorErrors) {
            this.fv
              .updateValidatorOption(
                error,
                "blank",
                "message",
                this.validatorErrors[error]
              )
              .updateFieldStatus(error, "Invalid", "blank");
          }
        })
        .finally(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    });
  },

  methods: {
    async reenviar2FA() {
      const reenviarButton = this.$refs["kt_login_validar2fa_reenviar"];
      reenviarButton.classList.add("spinner", "spinner-light", "spinner-right");

      await this.$recaptchaLoaded();
      this.form.token = await this.$recaptcha("login");

      this.$store
        .dispatch(REENVIAR_2FA, this.form)
        .then(() => {
          this.form.codigo = "";
          this.form.token = "";

          Swal.fire({
            title: "MENSAGEM: ",
            text: "Uma mensagem com um novo código foi enviado para você, verifique seu telefone ou email!",
            icon: "success",
            confirmButtonClass: "btn btn-success",
            heightAuto: false
          });
        })
        .catch(() => {
          for (const error in this.validatorErrors) {
            this.fv
              .updateValidatorOption(
                error,
                "blank",
                "message",
                this.validatorErrors[error]
              )
              .updateFieldStatus(error, "Invalid", "blank");
          }
        })
        .finally(() => {
          reenviarButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
        });
    },

    enter(numberInput) {
      const submitButton = this.$refs.kt_login_validar2fa_submit;
      if (submitButton) {
        submitButton.click(); // Simula o clique no botão
      }
    },

    atualizarCodigo2FA(codigo) {
      this.form.codigo = codigo;
    }
  }
};
</script>

<style scoped>
.diagonal-aside {
  margin-top: -25em;
  margin-left: -5em;
  background: #ffffff;
  overflow: hidden;
  -webkit-transform: skew(16deg) translateX(100px);
  -ms-transform: skew(16deg) translateX(100px);
  transform: skew(16deg) translateX(100px);
  left: 0;
}

.diagonal-aside img {
  width: 75%;
  margin-left: -20em;
  margin-top: 25em;
  -webkit-transform: skew(-16deg) translateX(40px);
  -ms-transform: skew(-16deg) translateX(40px);
  transform: skew(-16deg) translateX(40px);
  left: 0;
}

@media screen and (max-width: 768px) {
  .diagonal-aside {
    display: none;
  }
}

.btn-cancel:hover {
  background-color: #f8f9fa !important;
  color: #482182;
}
</style>
